import { useLazyQuery, useMutation } from "@apollo/client";
import { GETCLIENTSNAMEANDIDS } from "../../../Graphql/Queries";
import React, { useCallback, useEffect, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { returnClientOptions } from "../../../Libs/Utilities";
import { toast } from "react-toastify";
import Select from "react-select";
import { BigQueryDataSetsComponent } from "../../Shared/BigQueryDataSetsComponent";
import { FilterPrecalculatedValues } from "../../NewComponents/FilterPrecalculatedValues";
import { format, subDays } from "date-fns";
import MyLoader from "../../MiroComponents/PreLoader";
import { GETPRECALCULATEDVALUES } from "../../../Graphql/Mutations";
import { PreCalculatedValues } from "../../Shared/PreCalculatedValues";
import { CrossIcon } from "../../Shared/Icons";

export const PreCalculations = () => {
  const [myProfile, is_client, is_admin] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams({});
  const client = searchParams.get("client");
  const bqclientId = searchParams.get("bgclientId");
  const viewId = searchParams.get("viewId");
  const _params = Object.fromEntries([...searchParams]);
  const [precalculations, setPrecalculations] = useState(null);
  const [getprecalculations] = useMutation(GETPRECALCULATEDVALUES);
  const [clientSelectorOptions, setClientSelectorOptions] = useState(null);
  const [selectedDataSet, setSelectedDataSet] = useState(null);
  const [filters, setFilters] = useState([]);
  const [filtersWithMatchType, setFiltersWithMatchType] = useState([]);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);

  const callBackWhenClose = () => {
    setShowCalendar(false);
  };

  const [getActiveClients] = useLazyQuery(GETCLIENTSNAMEANDIDS);

  const setAllClientsOptions = async () => {
    try {
      const { data, loading } = await getActiveClients({
        variables: {
          clientFilters: [{ active: true }],
        },
      });
      if (!loading && data) {
        setClientSelectorOptions(returnClientOptions(data?.clients?.clients));
      }
    } catch (error) {
      toast.error("Error fetching clients");
    }
  };

  const setLimitedClients = (clients) => {
    setClientSelectorOptions(returnClientOptions(clients));
  };

  useEffect(() => {
    if (is_client) {
      setLimitedClients(myProfile?.client);
    } else {
      setAllClientsOptions();
    }
  }, [is_client, myProfile]);

  const createFilters = (val, fieldName, matchType, filters, setValues) => {
    const filterObject = {
      fieldName,
      matchType,
      value: val?.value,
    };
    const newArr = [...filters];
    const findField = newArr?.findIndex(
      (f) => f?.fieldName === fieldName && f?.matchType === matchType
    );
    if (findField > -1) {
      newArr[findField] = filterObject;
    } else {
      newArr.push(filterObject);
    }
    setValues(newArr);
  };

  // const createFiltersWithMatchType = (val, fieldName) => {
  //   const filterObject = {
  //     fieldName,
  //     value: val?.value,
  //   };
  //   const newArr = [...filtersWithMatchType];
  //   const findField = newArr?.findIndex((f) => f?.fieldName === fieldName);
  //   if (findField > -1) {
  //     newArr[findField] = filterObject;
  //   } else {
  //     newArr.push(filterObject);
  //   }
  //   setFiltersWithMatchType(newArr);
  // };

  const removeSegment = useCallback((segment, filters, setValues) => {
    const newArr = [...filters];
    const fieldNameIndex = newArr?.findIndex(
      (f) =>
        f?.fieldName === segment?.fieldName &&
        f?.value === segment?.value &&
        f?.matchType === segment?.matchType
    );
    if (fieldNameIndex > -1) {
      newArr.splice(fieldNameIndex, 1);
    }
    setValues(newArr);
  });

  const ClientSelector = ({ options }) => {
    return (
      <div className="fields-wrapper">
        <Select
          onChange={(client) => {
            const _params = Object.fromEntries([...searchParams]);
            _params.client = JSON.parse(client?.value)?._id;
            _params.bgclientId = JSON.parse(client?.value)?.bqClientId;
            delete _params.viewId;
            setSearchParams(_params);
          }}
          value={options?.find((opt) => JSON.parse(opt?.value)?._id === client)}
          // isMulti={true}
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder="Name"
          options={options}
        />
      </div>
    );
  };

  const getPreCalculations = async (conditions) => {
    try {
      const {
        data: { getPrecalculatedData },
        loading,
      } = await getprecalculations({
        variables: {
          client,
          dateRange: {
            startDate: format(selectionRange[0]?.startDate, "yyyyMMdd"),
            endDate: format(selectionRange[0]?.endDate, "yyyyMMdd"),
          },
          dataSetId: viewId,
          filters: conditions,
        },
      });
      setPrecalculations({
        loading,
        getPrecalculatedData,
      });
    } catch (error) {
      setPrecalculations({
        error,
        loading: false,
      });
      toast.error("Error fetching precalculations");
    }
  };

  if (!clientSelectorOptions) return <MyLoader />;
  return (
    <React.Fragment>
      <section className="live-reports">
        <div className="live-reports-inner">
          <div className="report-head-main">
            <h1 className="section-heading-title">Pre-Calculations</h1>
          </div>
          <div className="client-data-wrapper">
            <div className="client-details">
              <div className="client-dropdown">
                <h1 className="section-heading">Store:</h1>
                <ClientSelector options={clientSelectorOptions} />
              </div>
              {client && (
                <BigQueryDataSetsComponent
                  projectId={bqclientId}
                  client={
                    client
                      ? JSON.parse(
                          clientSelectorOptions?.find((opt) => {
                            return JSON.parse(opt?.value)?._id === client;
                          })?.value
                        )
                      : null
                  }
                  viewId={viewId}
                  _params={_params}
                  setSearchParams={setSearchParams}
                />
              )}

              {/* {client && (
                <BigQueryDataSetsComponent
                  projectId={bqclientId}
                  client={
                    client
                      ? JSON.parse(
                          clientSelectorOptions?.find((opt) => {
                            return JSON.parse(opt?.value)?._id === client;
                          })?.value
                        )
                      : null
                  }
                  setSelectedDataSet={setSelectedDataSet}
                  selectedDataSet={selectedDataSet}
                />
              )} */}
            </div>
          </div>
        </div>
      </section>
      {bqclientId && viewId && (
        <FilterPrecalculatedValues
          showCalendar={showCalendar}
          setShowCalendar={setShowCalendar}
          selectionRange={selectionRange}
          setSelectionRange={setSelectionRange}
          callBackWhenClose={callBackWhenClose}
          getPreCalculations={getPreCalculations}
        />
      )}

      <div>
        <div className="selected-segment">
          <div className="selected-data-wrapper">
            {filters?.map((segment, i) => (
              <div className="selected-data" key={i}>
                <span
                  className="remove-data-icon"
                  onClick={(e) => removeSegment(segment, filters, setFilters)}
                >
                  <CrossIcon />
                </span>
                <span className="selected-data-name">
                  {segment?.fieldName}{" "}
                  <span
                    style={{
                      textTransform: "uppercase",
                      color: "#fff",
                    }}
                  >
                    {segment?.matchType}{" "}
                  </span>{" "}
                  {segment?.value}
                </span>
              </div>
            ))}
            {filtersWithMatchType?.some(
              (f) => f?.fieldName === "match_type"
            ) && (
              <>
                {filtersWithMatchType?.map((segment, ind) => (
                  <div className="selected-data" key={ind}>
                    <span
                      className="remove-data-icon"
                      onClick={(e) =>
                        removeSegment(
                          segment?.fieldName,
                          filtersWithMatchType,
                          setFiltersWithMatchType
                        )
                      }
                    >
                      <CrossIcon />
                    </span>
                    <span className="selected-data-name">
                      {segment?.fieldName} : {segment?.value}
                    </span>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>

      <PreCalculatedValues precalculations={precalculations} />
    </React.Fragment>
  );
};
