import { Filters } from "../../Libs/FiltersHelpers";
import Select from "react-select";
import { useCallback, useMemo } from "react";
import { useQuery } from "@apollo/client";
import {
  FORMPREFILLDATAV2,
  GETCLIENTSNAMEANDIDS,
  GETPAGEOBJECTS,
} from "../../Graphql/Queries";
import MyLoader from "../MiroComponents/PreLoader";

export const FiltersComponent = ({
  serverFilters,
  setServerFilters,
  filterTypes,
  setPage,
}) => {
  const { data, loading, error } = useQuery(FORMPREFILLDATAV2);
  // const {
  //   data: dataClients,
  //   loading: clientLoading,
  //   error: clientError,
  // } = useQuery(GETCLIENTSNAMEANDIDS, {
  //   variables: {
  //     clientFilters: [{ active: true }],
  //   },
  // });

  // console.log(dataClients, "DATA CLIENTS");

  const filters = useMemo(
    () =>
      Filters(
        filterTypes,
        data?.formPrefillData?.clients,
        data?.formPrefillData?.pageelements
      ),
    [filterTypes, data]
  );
  let filterPayload = [...serverFilters];
  const createFilters = useCallback(
    (key, value) => {
      if (key && value) {
        let item = { [key]: value };
        if (value !== "" || value?.length > 0) {
          const index = filterPayload.findIndex(
            (it) => Object.keys(it)[0] === key
          );
          if (index === -1) {
            filterPayload.push(item);
          } else {
            filterPayload[index] = item;
          }
        }
        if (value === "" || value?.length === 0) {
          filterPayload.filter((it) => Object.keys(it)[0] !== key);
        }
      }
      return filterPayload;
    },
    [filterPayload]
  );

  const SetSelectValue = useCallback(
    (key) => {
      const value = filterPayload.find((it) => Object.keys(it)[0] === key);
      return value ? value[key] : "";
    },
    [serverFilters]
  );
  const SetSelectCheckbox = useCallback(
    (key) => {
      const value = filterPayload.find((it) => Object.keys(it)[0] === key);
      return value ? true : false;
    },
    [serverFilters]
  );

  const RenderFilters = useCallback(
    ({ type, values }) => {
      if (type === "select") {
        const options = values.options;
        const dValueId = SetSelectValue(values?.field);
        const dValue = options.filter((opt) =>
          typeof dValueId === "string"
            ? dValueId === opt?.value
            : dValueId.indexOf(opt?.value) > -1
        );

        return (
          <div className="fields-wrapper">
            {/* <label>{values.name}</label> */}
            <Select
              options={options}
              defaultValue={dValue}
              className="acc-newselectwrapper"
              isMulti={true}
              classNamePrefix="acc-newselect"
              onChange={(e) => {
                createFilters(
                  values.field,
                  e?.map((it) => it?.value)
                );
              }}
              placeholder={values.placeholder}
              name={values.field}
            />
          </div>
        );
      }
      if (type === "input") {
        return (
          <div className="fields-wrapper theme-black">
            {/* <label>{values.name}</label> */}
            <input
              type="text"
              defaultValue={SetSelectValue(values.field)}
              onChange={(e) => {
                if (e.currentTarget.value !== "") {
                  createFilters(values.field, e.currentTarget.value);
                } else {
                  setServerFilters(
                    filterPayload.filter(
                      (it) => Object.keys(it)[0] !== values.field
                    )
                  );
                }
              }}
              name={values.field}
              className="field-control"
              placeholder={values.placeholder}
            />
          </div>
        );
      }
      if (type === "checkbox") {
        return (
          <div className="fields-wrapper filters-checkbox">
            <p
              onClick={(e) => {
                let clicked = false;
                const input = e.currentTarget.querySelector("input");
                if (!clicked) {
                  input.click();
                  clicked = true;
                }
                setTimeout(function () {
                  if (input.checked) {
                    createFilters(values.field, input.checked);
                  } else {
                    setServerFilters(
                      filterPayload.filter(
                        (it) => Object.keys(it)[0] !== values.field
                      )
                    );
                  }
                  clicked = false;
                }, 500);
              }}
            >
              <input
                type="checkbox"
                defaultChecked={SetSelectCheckbox(values.field)}
                name={values.field}
                className=""
                placeholder={values.placeholder}
              />{" "}
              {values.name}
            </p>
          </div>
        );
      }
      // if (type === "date") {
      //   return (
      //     <div>
      //       <Calendar
      //         date={new Date()}
      //         onChange={(_) => {
      //           createFilters(values.field, _);
      //           console.log(filterPayload);
      //           var _vls = [...filterPayload];
      //           var index = _vls.findIndex(
      //             (it) => Object.keys(it)[0] === values.field
      //           );
      //           if (index === -1) {
      //             _vls.push({
      //               [values.field]: _,
      //             });
      //           } else {
      //             _vls[index] = {
      //               [values.field]: _,
      //             };
      //           }
      //           setFinalFilters(_vls);
      //         }}
      //       />
      //     </div>
      //   );
      // }
      return "";
    },
    [filterPayload]
  );
  if (loading) return <MyLoader />;
  if (error) return <div>{error?.message}</div>;
  return (
    <div className="filters-fields shadow p-3 mb-3">
      {filters?.map((values, i) => (
        <RenderFilters key={i} type={values?.type} values={values} />
      ))}

      <div className="d-flex">
        <button
          className="btn btn-sm btn-warning"
          onClick={async (e) => {
            setPage(1);
            setServerFilters(createFilters());
          }}
        >
          Filter Results
        </button>
        <button
          type="button"
          onClick={(e) => {
            setPage(1);
            setServerFilters([]);
          }}
          className="btn btn-sm btn-danger ms-auto me-0"
        >
          Reset Filters
        </button>
      </div>
    </div>
  );
};
