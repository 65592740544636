import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import Select from "react-select";
import {
  GETCLIENTSNAMEANDIDS,
  GETCLIENTEXPERIMENTS,
  GETCLIENTSCRIPTS,
} from "../../../Graphql/Queries";
import MyLoader from "../../MiroComponents/PreLoader";
import { LiveReportsIcon } from "../../Shared/Icons";
import { CREATECLIENTSCRIPT } from "../../../Graphql/Mutations";
import ExperimentsList from "../../NewComponents/ExperimentsList/ExperimentsList";
import TargetingModuleList from "../../NewComponents/TargetingModuleList/TargetingModuleList";

export const ABTestingDashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const client = searchParams.get("client");
  const viewId = searchParams.get("viewId");
  const [myProfile] = useOutletContext();
  const [clientSelectorOptions, setClientSelectorOptions] = useState(null);
  const [getActiveClients] = useLazyQuery(GETCLIENTSNAMEANDIDS);
  const [createClientScript] = useMutation(CREATECLIENTSCRIPT);
  const [clientScripts] = useLazyQuery(GETCLIENTSCRIPTS);
  const [ClientExperiments] = useLazyQuery(GETCLIENTEXPERIMENTS);
  const [experimentsPagination, setExperimentsPagination] = useState({
    page: 1,
    size: 5,
    total: 0,
  });
  const [codeValue, setCodeValue] = useState({});

  const returnClientOptions = (clients) => {
    return clients?.map((c) => {
      return {
        label: c?.name,
        value: JSON.stringify({
          _id: c?._id,
          gaproperties: c?.gaproperties,
          currency: c?.currency,
          logo: c?.logo,
        }),
      };
    });
  };

  const setAllClientsOptions = async () => {
    const { data, loading } = await getActiveClients({
      variables: {
        clientFilters: [{ active: true }],
      },
    });
    if (!loading && data) {
      setClientSelectorOptions(returnClientOptions(data?.clients?.clients));
    }
  };

  useEffect(() => {
    setAllClientsOptions();
  }, [myProfile]);

  const ClientSelector = ({ options }) => {
    return (
      <div className="fields-wrapper">
        <Select
          onChange={(client) => {
            const _params = Object.fromEntries([...searchParams]);
            _params.client = JSON.parse(client?.value)?._id;
            delete _params.viewId;
            setSearchParams(_params);
            setExperimentsPagination({ ...experimentsPagination, total: 0 });
          }}
          value={
            client
              ? options?.find((opt) => JSON.parse(opt?.value)?._id === client)
              : null
          }
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder="Select Store"
          options={options}
        />
      </div>
    );
  };

  const PropertySelector = ({ client, options }) => {
    const propertyOptions = client
      ? JSON.parse(
          options?.find((opt) => {
            return JSON.parse(opt?.value)?._id === client;
          })?.value
        )?.gaproperties?.map((c) => {
          return {
            label: c?.propertyname,
            value: c?.viewId,
          };
        })
      : [];
    return (
      <div className="fields-wrapper">
        <Select
          onChange={(propertyvalue) => {
            const _params = Object.fromEntries([...searchParams]);
            _params.viewId = propertyvalue?.value;
            setSearchParams(_params);
            if (propertyvalue?.value !== _params.viewId) {
              setExperimentsPagination({ ...experimentsPagination, total: 0 });
            }
          }}
          isDisabled={!client ? true : false}
          value={
            viewId ? propertyOptions.find((opt) => opt?.value === viewId) : null
          }
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder="Choose One"
          options={client && propertyOptions?.length > 0 ? propertyOptions : []}
        />
      </div>
    );
  };

  if (!clientSelectorOptions) return <MyLoader />;
  return (
    <>
      {/* <button
        onClick={(e) => {
          e.preventDefault();
          loadScript(client, viewId, createClientScript);
        }}
      >
        Check Script
      </button> */}
      <section className="live-reports">
        <div className="live-reports-inner">
          <div className="report-head-main">
            <span className="live-icon">
              <LiveReportsIcon />
            </span>
            <h1 className="section-heading-title">Dashboard</h1>
          </div>
          <div className="client-data-wrapper">
            <div className="client-details">
              <div className="client-dropdown">
                <h1 className="section-heading">Store:</h1>
                <ClientSelector options={clientSelectorOptions} />
              </div>
              <div className="properties-dropdown">
                <h1 className="section-heading">Properties:</h1>
                <PropertySelector
                  options={clientSelectorOptions}
                  client={client}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {client && viewId && (
        <>
          <ExperimentsList
            client={client}
            viewId={viewId}
            setSearchParams={setSearchParams}
          />
          <hr />
          <TargetingModuleList client={client} />
        </>
      )}
    </>
  );
};
