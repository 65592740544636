import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { ListHeader } from "./ListHeader";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  CREATEENVIRONMENT,
  UPDATEENVIRONMENT,
} from "../../../Graphql/Mutations";
import { GETENVIRONMENTS } from "../../../Graphql/Queries";
import { LoaderLogo } from "../../Shared/LoaderLogo";

const ENVIRONMENT_HEADER = ["Name", "Rules Js", "Edit/Delete"];
export const EnvironmentTargeting = ({
  type,
  module,
  formType,
  currentData,
  client,
  setModule,
  setCurrentData,
  setFormType,
}) => {
  const [createEnvironment] = useMutation(CREATEENVIRONMENT);
  const [updateEnvironment] = useMutation(UPDATEENVIRONMENT);

  const { data, loading, error, refetch } = useQuery(GETENVIRONMENTS, {
    variables: {
      filters: [
        {
          client: client,
        },
      ],
    },
  });
  if (loading) return <LoaderLogo />;
  if (error) return <div>Error: {error.message}</div>;
  return (
    <>
      {type === "form" ? (
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const { name, rules_js } = e.target;
            const environmentPayload = {
              client: client,
              name: name.value,
              rules_js: rules_js.value,
            };
            if (formType === "add") {
              await createEnvironment({
                variables: {
                  environmentPayload,
                },
              });
              toast.success("Environment Added Successfully");
            } else {
              await updateEnvironment({
                variables: {
                  id: currentData?._id,
                  environmentPayload,
                },
              });
              toast.success("Environment Edited Successfully");
            }
            refetch();
            setModule(null);
          }}
        >
          <div className="fields-wrapper fields-wrapper__updated">
            <div>
              <label>Name</label>
              <input
                required
                name="name"
                className="field-control  field-control__updated"
                placeholder=""
                defaultValue={currentData ? currentData.name : ""}
              />
            </div>
          </div>
          <div className="fields-wrapper fields-wrapper__updated">
            <label>Rules js</label>
            <textarea
              name="rules_js"
              className="field-control  field-control__updated"
              placeholder=""
              defaultValue={currentData ? currentData.rules_js : "return true;"}
            />
          </div>
          <div className="add__new__test__form-footer">
            <button className="btn next-btn" type="submit">
              {formType === "add" ? `Add ${module}` : `Edit ${module}`}
            </button>
          </div>
        </form>
      ) : (
        <>
          <ListHeader header={ENVIRONMENT_HEADER} />
          <div>
            {data?.getEnvironments?.environments?.map((row, idx) => (
              <div
                key={`${row.name}-${idx}`}
                className="test-details-table-data"
              >
                <div className="test-name text-break">
                  <h3>{row?.name}</h3>
                </div>
                <div className="test-name text-break">
                  <h3>{row?.rules_js}</h3>
                </div>
                <div className="test-name text-break">
                  <FaEdit
                    onClick={() => {
                      setModule("Environment");
                      setCurrentData(row);
                      setFormType("edit");
                    }}
                    style={{ cursor: "pointer", color: "gray" }}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};
