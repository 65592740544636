export const Filters = (filterValues, clients, pageelements) => {
  const list = [
    {
      name: "Test",
      placeholder: "Search by name..",
      describe: "findtestbytestname",
      field: "name",
      type: "input",
      options: null,
    },
    {
      name: "Client",
      placeholder: `Select Client`,
      describe: "findtestbyclientid",
      field: "client",
      type: "select",
      options: clients?.map((clnt) => {
        return {
          label: clnt?.name,
          value: clnt?._id,
        };
      }),
    },
    {
      name: "Client",
      placeholder: `Select Client`,
      describe: "findclientbyclientid",
      field: "_id",
      type: "select",
      options: clients?.map((clnt) => {
        return {
          label: clnt?.name,
          value: clnt?._id,
        };
      }),
    },
    {
      name: "Object/Page",
      placeholder: !pageelements
        ? `Loading Page Elements...`
        : `Select Page Element`,
      describe: "findtestbypageobjecttype",
      field: "pageelement",
      type: "select",
      options: pageelements?.map((pe) => {
        return {
          label: pe?.name,
          value: pe?._id,
        };
      }),
    },
    {
      name: "Status",
      placeholder: "Select Status",
      describe: "findtestbyteststatus",
      field: "status",
      type: "select",
      options: [
        {
          label: "Live",
          value: "live",
        },
        {
          label: "Draft",
          value: "draft",
        },
        {
          label: "Ended",
          value: "ended",
        },
        {
          label: "Preview",
          value: "preview",
        },
        {
          label: "Paused",
          value: "paused",
        },
        {
          label: "Discard",
          value: "discard",
        },
      ],
    },
    {
      name: "Tool",
      placeholder: "Select Tool",
      describe: "findtestbytesttool",
      field: "testtool",
      type: "select",
      options: [
        {
          label: "Codebase",
          value: "Codebase",
        },
        {
          label: "Kameleoon",
          value: "Kameleoon",
        },
        {
          label: "Varify",
          value: "Varify",
        },
        {
          label: "VWO",
          value: "Vwo",
        },
        {
          label: "Optimizely",
          value: "Optimizely",
        },
        {
          label: "A/B Tasty",
          value: "A/B Tasty",
        },
      ],
    },
    {
      name: "Tests that can be evaluated now",
      placeholder: "Get tests that can be evaluated now",
      describe: "testscanbeevaluatednow",
      field: "testscanbeavaluated",
      type: "checkbox",
    },
    {
      name: "Hypothesis",
      placeholder: "Select Hypothesis",
      describe: "findtestbyhypothesisid",
      field: "hypothesis",
      type: "select",
      options: [],
    },
    {
      name: "Hypothesis",
      placeholder: "Select Hypothesis",
      describe: "findhypothesisbyhypothesisid",
      field: "_id",
      type: "select",
      options: [],
    },
    {
      name: "Live Date",
      placeholder: "Select Test Live Date",
      describe: "findtestbylivedate",
      field: "livedate",
      type: "date",
      options: null,
    },
  ];
  return list.filter((list) => filterValues.includes(list.describe));
};
