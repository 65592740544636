import { useEffect, useState } from "react";
import { VscInfo } from "react-icons/vsc";
import ReactTooltip from "react-tooltip";

export const ClientCalculatedKpis = ({ data, currency }) => {
  const [showAovRpu, setShowAovRpu] = useState(true);
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [showAovRpu]);
  const valueColor = (value, decimal) => {
    if (value <= 0) {
      return <span className="number-danger"> {decimal ? value : 0} </span>;
    }
    return (
      <span className="numbers-success">
        {" "}
        {Number(value).toLocaleString()}{" "}
      </span>
    );
  };
  return (
    <>
      <div className="calculated-kpis-variants">
        <div className="experiment-heading-divider">
          <div className="variant-heading section-heading-data">
            <h3 className="variant-name">{data?.controlvariant} Performance</h3>
            <span className="section-heading-subtitle">(to-date)</span>
          </div>
          {/* <div>
            <span
              className="showaovrpucta"
              onClick={(_) => {
                setShowAovRpu(!showAovRpu);
              }}
            >
              {" "}
              Show AOV/RPU Details
            </span>
          </div> */}
        </div>

        <div className="time-line-variants">
          <div className="time-line-variant-heading">
            <div className="variant-performance-cards">
              <div className="single-performance-card">
                <h2 className="performance-card-heading">
                  Change in CR{" "}
                  <span
                    className="toottip-span"
                    data-tip={`= Variant CR (users) ÷ (Control CR (users)) - 1`}
                  >
                    <VscInfo />
                  </span>
                </h2>
                <div className="performance-card-numbers">{data?.uplift}%</div>
              </div>
              <div className="single-performance-card">
                <h2 className="performance-card-heading">
                  Additional Transactions{" "}
                  <span
                    className="toottip-span"
                    data-tip={`= Variant CR (users) * Monthly Traffic -
                  Control CR (users) * Monthly Traffic`}
                  >
                    <VscInfo />
                  </span>{" "}
                  <br />
                  <span className="performance-time-period">(Per Month)</span>
                </h2>
                <div className="performance-card-numbers">
                  {valueColor(data?.additionaltransactionspermonth)}{" "}
                </div>
              </div>
              <div className="single-performance-card">
                <h2 className="performance-card-heading">
                  Additional Revenue{" "}
                  <span
                    className="toottip-span"
                    data-tip={`= Total revenue - (Total users x Control RPU)`}
                  >
                    <VscInfo />
                  </span>
                  <br />
                  <span className="performance-time-period">(during now)</span>
                </h2>
                <div className="performance-card-numbers">
                  {valueColor(data?.additionalrevenueduringtime)}
                  <small>{data?.currency}</small>
                </div>
              </div>
              <div className="single-performance-card">
                <h2 className="performance-card-heading">
                  Additional Revenue{" "}
                  <span
                    className="toottip-span"
                    data-tip={`= Additional Transactions (per month) * Average Order Value`}
                  >
                    <VscInfo />
                  </span>
                  <br />
                  <span className="performance-time-period">(Per Month)</span>
                </h2>

                <div className="performance-card-numbers">
                  {valueColor(data?.additionalrevenuepermonth)}
                  <small>{data?.currency}</small>
                </div>
              </div>
              <div className="single-performance-card">
                <h2 className="performance-card-heading">
                  Additional Revenue{" "}
                  <span
                    className="toottip-span"
                    data-tip={`= (Additional Transactions (per month)) * 12`}
                  >
                    <VscInfo />
                  </span>
                  <br />
                  <span className="performance-time-period">(Per year)</span>
                </h2>
                <div className="performance-card-numbers">
                  {valueColor(data?.additionalrevenueperyear)}
                  <small>{data?.currency}</small>
                </div>
              </div>
              <div className="single-performance-card">
                <h2 className="performance-card-heading">
                  Confidence of {data?.uplift < 0 ? `Control` : `Variant`}
                  <span
                    className="toottip-span"
                    data-tip={`= 1 - NormalDist(Control Transactions (per session) - Variant Transactions (per session)) ÷
                SQRT(Math.pow(Control Std. Error, 2) + Math.pow(Variant Std Error, 2))`}
                  >
                    <VscInfo />
                  </span>
                </h2>
                <div className="performance-card-numbers">
                  {data?.confidence}%
                </div>
              </div>
              {showAovRpu && (
                <>
                  <div className="single-performance-card">
                    <h2 className="performance-card-heading">
                      Change in AOV{" "}
                      <span
                        className="toottip-span"
                        data-tip={`= Variant AOV - Control AOV`}
                      >
                        <VscInfo />
                      </span>
                    </h2>
                    <div className="performance-card-numbers">
                      {valueColor(data?.changeinaov, true)}
                    </div>
                  </div>
                  <div className="single-performance-card">
                    <h2 className="performance-card-heading">
                      Change in RPU{" "}
                      <span
                        className="toottip-span"
                        data-tip={`= Variant RPU - Control RPU`}
                      >
                        <VscInfo />
                      </span>
                    </h2>
                    <div className="performance-card-numbers">
                      {valueColor(data?.changeinrpu, true)}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
