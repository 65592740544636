import MyRoutes from "../Routes";
import {
  AuditIcon,
  ClientLink,
  Clients,
  CodeBase,
  Dashboard,
  DataLibrary,
  Experiments,
  Reporting,
  ABCompareIcon,
} from "../Components/Shared/Icons";
import { VscReport } from "react-icons/vsc";

export const routes = [
  {
    name: "Dashboard",
    route: MyRoutes.dashboard,
    subItem: null,
    icon: <Dashboard />,
    rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
  },
  {
    name: "Dashboard",
    route: MyRoutes.editorDashboard,
    subItem: null,
    icon: <Dashboard />,
    rolesAllowed: ["EDITOR"],
  },
  {
    name: "Reporting",
    route: MyRoutes.gaanalytics,
    icon: <Reporting />,
    rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
    subItem: [
      {
        name: "Live Reports",
        route: MyRoutes.gaanalytics,
        rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
      },
      {
        name: "Test Pre-calculations",
        route: MyRoutes.gaprecalculations,
        rolesAllowed: ["ADMIN", "EDITOR", "OPTIMIZER", "USER"],
      },
      {
        name: "Client Dashboards",
        route: MyRoutes.clientreports,
        rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
      },
      {
        name: "Client Reports Learnings",
        route: MyRoutes.clientreportslearnings,
        rolesAllowed: ["ADMIN", "OPTIMIZER"],
      },
      {
        name: "Portfolio Comparison",
        route: MyRoutes.portfoliocomparison,
        rolesAllowed: ["ADMIN", "OPTIMIZER"],
      },
    ],
  },
  {
    name: "Clients",
    route: MyRoutes.clients,
    icon: <Clients />,
    subItem: [
      {
        name: "Clients",
        route: MyRoutes.clients,
        rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
      },
      {
        name: "Client Sheets/Links",
        route: MyRoutes.clientlinks,
        icon: <ClientLink />,
        rolesAllowed: ["ADMIN", "OPTIMIZER"],
      },
      {
        name: "Audit",
        route: MyRoutes.clientaudit,
        icon: <AuditIcon />,
        rolesAllowed: ["ADMIN", "OPTIMIZER"],
      },
      {
        name: "CVR-Report",
        route: MyRoutes.cvrReports,
        rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
      },
      {
        name: "Refetch Additional Revenue",
        route: MyRoutes.refetchAddtionalRevenueData,
        rolesAllowed: ["ADMIN", "OPTIMIZER"],
      },
      {
        name: "Tests Overview",
        route: MyRoutes.testsoverview,
        rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
      },
      {
        name: "Client Business Projection",
        route: MyRoutes.clientbusinesscaseprojections,
        rolesAllowed: ["ADMIN", "OPTIMIZER"],
      },
    ],
    rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
  },
  {
    name: "Experiments",
    route: MyRoutes.tests,
    icon: <Experiments />,
    rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
    subItem: [
      {
        name: "Experiments",
        icon: <VscReport />,
        route: MyRoutes.tests,
        rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
      },
      {
        name: "Test ideas",
        icon: <VscReport />,
        route: MyRoutes.testideas,
        rolesAllowed: ["ADMIN", "OPTIMIZER"],
      },
      {
        name: "Landingpages",
        icon: <VscReport />,
        route: MyRoutes.landingpages,
        rolesAllowed: ["ADMIN", "OPTIMIZER"],
      },
    ],
  },
  {
    name: "Data Library",
    route: MyRoutes.page,
    icon: <DataLibrary />,
    rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
    subItem: [
      {
        name: "Object / Page",
        route: MyRoutes.page,
        rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
      },
      {
        name: "Hypothesis",
        route: MyRoutes.hypothesis,
        rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
      },
      {
        name: "Psychological Trigger",
        route: MyRoutes.triggers,
        rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
      },
      {
        name: "Goals",
        route: MyRoutes.goals,
        rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
      },
      {
        name: "Test Types",
        route: MyRoutes.testtypes,
        rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
      },
      {
        name: "Tags",
        route: MyRoutes.tags,
        rolesAllowed: ["ADMIN", "OPTIMIZER", "USER"],
      },
      {
        name: "Segments",
        route: MyRoutes.createsegments,
        rolesAllowed: ["ADMIN"],
      },
      {
        name: "FAQS",
        route: MyRoutes.faqs,
        rolesAllowed: ["ADMIN"],
      },
      {
        name: "Import / Export CSV",
        route: MyRoutes.importcsv,
        rolesAllowed: ["ADMIN"],
      },
    ],
  },
  {
    name: "Codebase",
    route: MyRoutes.snippets,
    icon: <CodeBase />,
    rolesAllowed: ["ADMIN", "EDITOR", "OPTIMIZER", "USER"],
    subItem: null,
  },
  // {
  //   name: "Business Projection",
  //   route: MyRoutes.businesscaseprojections,
  //   icon: <CodeBase />,
  //   rolesAllowed: ["ADMIN", "OPTIMIZER"],
  //   subItem: null,
  // },
  {
    name: "AB TESTING (BETA)",
    route: MyRoutes.abtestingdashboard,
    icon: <ABCompareIcon />,
    rolesAllowed: ["ADMIN", "OPTIMIZER"],
    subItem: null,
  },
  {
    name: "User",
    route: MyRoutes.users,
    settingsBar: true,
    rolesAllowed: ["ADMIN"],
  },
  {
    name: "Client Survery Reports",
    route: MyRoutes.clientsurveyreport,
    settingsBar: true,
    rolesAllowed: ["ADMIN", "OPTIMIZER"],
  },
  {
    name: "Announcements",
    route: MyRoutes.announcements,
    settingsBar: true,
    rolesAllowed: ["ADMIN", "OPTIMIZER"],
  },
  {
    name: "Client Accesses",
    route: MyRoutes.clientsaccesses,
    settingsBar: true,
    rolesAllowed: ["ADMIN", "OPTIMIZER"],
  },
  {
    name: "Manage Faqs",
    route: MyRoutes.faqs,
    settingsBar: true,
    rolesAllowed: ["ADMIN", "OPTIMIZER"],
  },
  // {
  //   name: "Client Feedbacks",
  //   route: MyRoutes.clientfeedbacks,
  //   icon: <Feedbacks />,
  //   rolesAllowed: ["ADMIN", "USER"],
  //   subItem: null,
  // },

  // {
  //   name: "Client Reports",
  //   route: MyRoutes.clientreports,
  //   rolesAllowed: ["ADMIN", "EDITOR", "OPTIMIZER"],
  //   subItem: null,
  // },
];
