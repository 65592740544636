import React, { useState } from "react";
import { ABCompareIcon, CheckBoxMarkIcon, FilterIcon } from "../Shared/Icons";
import { Scrubber } from "../Shared/Scrubber";
import { DateRangeSelectorComponent } from "../Shared/DateRangeSelectorComponent";
import { ReportFilterValues } from "../../Libs/ReportFiltersValues";
import Select from "react-select";
import { OperatorValues } from "../../Libs/Utilities";
export function AnalyticsDimensionsAndMetrics({
  client,
  viewId,
  selectedClient,
  platform,
  selectedTest,
  showCalendar,
  setShowCalendar,
  selectionRange,
  setSelectionRange,
  getreport,
  callBackWhenClose,
  createDimensionFilters,
  includeProducts = false,
  setIncludeProducts = () => void 0,
  interaDayTable = false,
  setInteraDayTable = () => void 0,
  file = null,
  setFile = () => void 0,
}) {
  const [scrubber, showScrubber] = useState(null);
  const [valueFilters, setValueFilters] = useState({
    value: null,
    key: null,
    filterType: "string",
    matchType: null,
  });
  if (!client || !viewId || !selectedTest) return ``;
  if (platform === "BQ" && !selectedClient?.bqClientId)
    return (
      <div>
        <h3
          style={{
            color: "#fff",
          }}
        >
          No Big Query ClientID found for this property.
        </h3>
      </div>
    );
  return (
    <>
      <Scrubber showScrubber={showScrubber} scrubber={scrubber} />
      <section className="selected-test-content live-reports-content">
        <div className="experiment-data-inner">
          <div className="experiment-heading-divider">
            <div className="section-heading-data">
              <h1 className="section-heading">Experiment Data</h1>
              <span className="section-heading-subtitle">(to-date)</span>
            </div>
            {/* filter icon for mobile screens as general filters will be removed in mobile view */}
            <div className="reporting-mobile-filters">
              <FilterIcon />
              <span className="reporting-mobile-filters__text">Filter</span>
            </div>
            <div
              className="ab-compare"
              onClick={(e) => {
                showScrubber({
                  control: selectedTest?.controlimage,
                  variation: selectedTest?.variationimage,
                });
              }}
            >
              <ABCompareIcon />
              <span>Compare</span>
            </div>
          </div>
          <div className="experiment-data-navbar">
            <div className="experiment-data-dropdown-pane properties-dropdown">
              {ReportFilterValues?.map((filter, i) => {
                return (
                  <div className="experiment-dropdown-item" key={i}>
                    <span className="experiment-dropdown-text">
                      <Select
                        placeholder={filter?.label}
                        // value={getSelectValue(segment?.key, selectedSegments)}
                        onChange={(val) => {
                          createDimensionFilters(
                            val,
                            filter?.fieldName,
                            filter?.filterType,
                            filter?.matchType
                          );
                          // selectSegments(
                          //   segment?.key,
                          //   val,
                          //   selectedSegments,
                          //   "=="
                          // );
                        }}
                        // menuIsOpen={true}
                        // isDisabled={platform === "BQ"}
                        className="acc-newselectwrapper"
                        classNamePrefix="acc-newselect"
                        options={filter?.values?.map((val) => {
                          return {
                            label: val,
                            value: val?.toLowerCase(),
                          };
                        })}
                      />
                    </span>
                  </div>
                );
              })}
              <div className="experiment-dropdown-item">
                <span className="experiment-dropdown-text">
                  <div className="checkbox-wrapper-4">
                    <input
                      className="inp-cbx"
                      id="interaDayTable"
                      type="checkbox"
                      onChange={(e) => {
                        setInteraDayTable(e.target.checked);
                      }}
                      checked={interaDayTable}
                    />
                    <label className="cbx" htmlFor="interaDayTable">
                      <span>
                        <CheckBoxMarkIcon />
                      </span>
                      <span>Use Intera Day Table</span>
                    </label>
                    {/* <svg className="inline-svg">
                      <symbol id="check-4" viewBox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                      </symbol>
                    </svg> */}
                  </div>
                </span>
              </div>
              <div className="experiment-dropdown-item">
                <span className="experiment-dropdown-text">
                  <div className="checkbox-wrapper-4">
                    <input
                      className="inp-cbx"
                      id="includeProducts"
                      type="checkbox"
                      checked={includeProducts}
                      onChange={(e) => {
                        setIncludeProducts(e.target.checked);
                      }}
                    />
                    <label className="cbx" htmlFor="includeProducts">
                      <span>
                        <CheckBoxMarkIcon />
                      </span>
                      <span>Fetch Products to calculate profit (BETA)</span>
                    </label>
                    {/* <svg className="inline-svg">
                      <symbol id="check-4" viewBox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                      </symbol>
                    </svg> */}
                  </div>
                </span>
              </div>

              {includeProducts && (
                <div className="xls-sheet-wrapper">
                  <div class="upload-btn-wrapper">
                    <button class="btn">
                      {file?.name ? file?.name : "Upload a file"}
                    </button>
                    <input
                      type="file"
                      onChange={(e) => setFile(e.target.files[0])}
                      name="myfile"
                    />
                  </div>
                  <a
                    href="https://docs.google.com/spreadsheets/d/1b_Za99BXhgvEUN9k4PmVGbXDNbcgxTOne1gcY3QbUpc/edit?usp=sharing"
                    target="_blank"
                  >
                    <span>Download Sample File</span>
                  </a>
                </div>
              )}

              {/* {segmentOptions?.map((segment, i) => (
                  <div className="experiment-dropdown-item" key={i}>
                    <span className="experiment-dropdown-text">
                      <Select
                        placeholder={segment?.name}
                        className="acc-newselectwrapper"
                        value={getSelectValue(segment?.key, selectedSegments)}
                        onChange={(val) => {
                          selectSegments(
                            segment?.key,
                            val,
                            selectedSegments,
                            "=="
                          );
                        }}
                        classNamePrefix="acc-newselect"
                        options={segment?.values?.map((val) => {
                          return {
                            label: val,
                            value: val,
                          };
                        })}
                      />
                    </span>
                  </div>
                ))} */}
            </div>
            <div className="date-selector">
              <DateRangeSelectorComponent
                showCalendar={showCalendar}
                setShowCalendar={setShowCalendar}
                selectionRange={selectionRange}
                setSelectionRange={setSelectionRange}
                className="dark-calendar"
                classNameWrapper="dark-calendar-wrapper"
                callBackWhenClose={callBackWhenClose}
                showDays={true}
                color="#fff"
              />
            </div>
          </div>
          <div className="experiment-segment">
            <div className="segment-data">
              <div className="segment-dropdown-value">
                {platform === "BQ" && (
                  <>
                    <div className="experiment-dropdown-text">
                      <Select
                        className="acc-newselectwrapper"
                        placeholder="Segment"
                        value={[
                          {
                            label: "Transactions",
                            value: "transactions",
                          },
                        ].find((val) => val?.value === valueFilters?.key)}
                        onChange={(val) => {
                          setValueFilters((oldValue) => ({
                            ...oldValue,
                            key: val?.value,
                          }));
                        }}
                        classNamePrefix="acc-newselect"
                        options={[
                          {
                            label: "Transactions",
                            value: "transactions",
                          },
                        ]}
                      />
                    </div>
                    <div className="experiment-dropdown-text">
                      <Select
                        className="acc-newselectwrapper"
                        placeholder="Equals to"
                        value={OperatorValues().find(
                          (val) => val?.value === valueFilters?.matchType
                        )}
                        onChange={(val) => {
                          setValueFilters((oldValue) => ({
                            ...oldValue,
                            matchType: val?.value,
                          }));
                        }}
                        classNamePrefix="acc-newselect"
                        options={OperatorValues()}
                      />
                    </div>
                    <div className="segment-input">
                      <input
                        type="number"
                        id="segmentValue"
                        defaultValue={""}
                        placeholder="Value $ ..."
                        className="segment-search"
                      />
                    </div>
                    <div className="segment-button">
                      <button
                        onClick={(e) => {
                          const values = document
                            .querySelector("#segmentValue")
                            .value.trim();
                          if (
                            valueFilters?.key &&
                            valueFilters?.matchType &&
                            values !== ""
                          ) {
                            createDimensionFilters(
                              {
                                label: values,
                                value: values,
                              },
                              valueFilters?.key,
                              valueFilters?.filterType,
                              valueFilters?.matchType
                            );
                            document.querySelector("#segmentValue").value = "";
                          }
                        }}
                      >
                        {" "}
                        Add
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="segment-btn">
                <button
                  className="save-segment-btn"
                  onClick={async (event) => {
                    event.preventDefault();
                    getreport();
                    // getReportsDataFromServer(true);
                  }}
                >
                  Live Reports
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export const FilterAnalyticsDimensionsAndMetrics = React.memo(
  AnalyticsDimensionsAndMetrics
);
