import { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GETALLCVRREPORT,
  GETCLIENTSNAMEANDIDS,
} from "../../../Graphql/Queries";
import useStore from "../../../Store";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  ADDCVRREPORT,
  DELETECVRREPORT,
  UPDATECVRREPORT,
} from "../../../Graphql/Mutations";
import MyLoader from "../../MiroComponents/PreLoader";
import DataTable from "react-data-table-component";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { ClientCVRReport } from "./ClientCVRReport";
import { customStyles } from "../../../Libs/TableCustomStyles";

export const CVRReports = () => {
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams({});
  const client = searchParams.get("client");
  const { toggleForm } = useStore();
  const [selectedClient, setSelectedClient] = useState(null);
  const [editObj, setEditObj] = useState(null);
  const [clientSelectorOptions, setClientSelectorOptions] = useState(null);
  const showForm = useStore((state) => state.showForm);
  const [getActiveClients] = useLazyQuery(GETCLIENTSNAMEANDIDS);
  const getCVRReports = useQuery(GETALLCVRREPORT);
  const [addCVRReport] = useMutation(ADDCVRREPORT);
  const [updateCVRReport] = useMutation(UPDATECVRREPORT);
  const [deleteCVRReport] = useMutation(DELETECVRREPORT);

  const returnClientOptions = (clients) => {
    return clients?.map((c) => {
      return {
        label: c?.name,
        value: c?._id,
      };
    });
  };

  const setAllClientsOptions = async () => {
    const { data, loading } = await getActiveClients({
      variables: {
        clientFilters: [{ active: true }],
      },
    });
    if (!loading && data) {
      setClientSelectorOptions(returnClientOptions(data?.clients?.clients));
    }
  };

  useEffect(() => {
    setAllClientsOptions();
  }, [myProfile]);

  const columns = [
    {
      name: "Client",
      selector: (row) => <div> {row?.client?.name} </div>,
      width: "20%",
      wrap: true,
    },
    {
      name: "CVR Link",
      selector: (row) => <div className="pt-1 pb-1"> {row?.reportLink} </div>,
      width: "50%",
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <button
            className="btn btn-sm btn-success"
            style={{ marginRight: "5px" }}
            onClick={(_) => {
              const _params = Object.fromEntries([...searchParams]);
              _params.client = row?.client?._id;
              setSearchParams(_params);
            }}
          >
            Report
          </button>
          <button
            className="btn btn-sm btn-primary"
            style={{ marginRight: "5px" }}
            onClick={(_) => {
              const client = clientSelectorOptions.find(
                (c) => c?.value === row?.client?._id
              );
              toggleForm(true);
              setEditObj(row);
              setSelectedClient(client);
            }}
          >
            Edit
          </button>
          <button
            className="btn btn-sm btn-danger"
            onClick={async (e) => {
              await deleteCVRReport({
                variables: {
                  id: row?._id,
                },
              });
              toast.success("Deleted");
              getCVRReports?.refetch();
            }}
          >
            Delete
          </button>
        </div>
      ),
      width: "30%",
      right: true,
      wrap: true,
    },
  ];

  if (getCVRReports?.loading) return <MyLoader />;
  return (
    <>
      {showForm && (
        <div className="form-wrapper" id="add-new-test-form">
          <div
            className="form-wrapper-inner addtestform"
            style={{ overflow: "inherit" }}
          >
            <div className="card add-new-test-card" id="formcard">
              <button
                className="close-modal"
                onClick={(_) => {
                  toggleForm(!showForm);
                  setEditObj(null);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#000"
                >
                  <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                </svg>{" "}
              </button>
              <div className="card-body">
                <h3> {editObj ? "Update" : "Create"} CVR Reports</h3>

                <form
                  onSubmit={async (_) => {
                    _.preventDefault();
                    const reportLink = _.target.reportLink.value;
                    if (reportLink === "") {
                      toast.error("Please add required data.");
                      return;
                    }

                    if (editObj) {
                      const { data } = await updateCVRReport({
                        variables: {
                          id: editObj?._id,
                          client: selectedClient?.value,
                          reportLink,
                        },
                      });
                      if (data) {
                        toggleForm(!showForm);
                        setEditObj(null);
                        setSelectedClient(null);
                        getCVRReports?.refetch();
                      }
                    } else {
                      const { data } = await addCVRReport({
                        variables: {
                          client: selectedClient?.value,
                          reportLink,
                        },
                      });
                      if (data) {
                        toggleForm(!showForm);
                        setSelectedClient(null);
                        getCVRReports?.refetch();
                      }
                    }
                  }}
                >
                  <div className="fields-wrapper">
                    <label> CVR Report Link (required)</label>
                    <input
                      className="field-control"
                      name="reportLink"
                      defaultValue={editObj ? editObj?.reportLink : ""}
                    />
                  </div>
                  <div className="fields-wrapper">
                    <label> Select Client</label>

                    <Select
                      onChange={(client) => {
                        setSelectedClient(client);
                      }}
                      value={selectedClient}
                      className="acc-newselectwrapper"
                      classNamePrefix="acc-newselect"
                      placeholder="Select Store"
                      options={clientSelectorOptions}
                    />
                  </div>

                  <div className="fields-wrapper">
                    <button> Submit CVR Details</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {client && (
        <ClientCVRReport
          client={client}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          haveMultipleClients={true}
        />
      )}
      <div
        style={
          client
            ? {
                display: "none",
              }
            : {}
        }
      >
        <div className="header-bar">
          <div className="header-bar-inner">
            <h2> CVR Reports</h2>
            <div>
              {!is_user && (
                <button className="add" onClick={(_) => toggleForm(!showForm)}>
                  Add CVR Report
                </button>
              )}
            </div>
          </div>
        </div>

        <div>
          <DataTable
            columns={columns}
            data={getCVRReports?.data?.getAllCVRReport}
            progressPending={getCVRReports?.loading}
            customStyles={customStyles}
            progressComponent={<MyLoader />}
          />
        </div>
      </div>
    </>
  );
};
