import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useEffect, useLayoutEffect, useRef } from "react";

export const ClientPropertiesList = ({
  properties,
  setSearchParams,
  selectedViewId,
  _params,
  listCount,
}) => {
  const splideRef = useRef(null);

  useLayoutEffect(() => {
    const selectedView = document.querySelector(".experiment-active");
    if (selectedView) {
      splideRef.current.go(Number(selectedView.getAttribute("data-index")));
    }
  }, [selectedViewId]);
  return (
    <div className="experiment-list">
      <Splide
        aria-label="My Favorite Images"
        ref={splideRef}
        options={{
          type: "slide",
          perPage: listCount ?? 3,
          pagination: false,
          arrows: true,
          gap: "10px",
          breakpoints: {
            1279: {
              perPage: 2,
              pagination: true,
              arrows: false,
            },
            // 767: {
            //   pagination: true,
            //   // arrows: false,
            // },
          },
        }}
      >
        {properties?.map(({ platform, propertyname, viewId }, i) => (
          <SplideSlide key={i}>
            <div
              key={i}
              data-index={i}
              className={`experiment-name ${
                viewId === selectedViewId ? `experiment-active` : ``
              } `}
              onClick={(e) => {
                _params.viewId = viewId;
                setSearchParams(_params);
              }}
            >
              <p>
                {platform} - {propertyname}
              </p>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};
