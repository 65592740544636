import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useStore from "../../Store";
import AuthTopBar from "../MiroComponents/AuthTopBar";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
// import "../Pages/Admin/admin-style.css";
import "../Layouts/NewStyle.css";
import "../Layouts/StoreAudit.css";
import ClientSideBar from "../MiroComponents/ClientSideBar";
import { useMyProfile } from "../../Hooks/useMyProfile";
import { LoaderLogo } from "../Shared/LoaderLogo";
import { NotAuthorized } from "../Shared/NotAuthorized";
import { CustomNavigator } from "../Shared/CustomNavigator";
import { ANNOUNCEMENTS } from "../../Graphql/Queries";
import { useLazyQuery } from "@apollo/client";
import { AnnouncementComponent } from "../Shared/Announcement";
function ClientLayout(props) {
  const navigate = useNavigate();

  // const [showFullAnnouncement, setShowFullAnnouncement] = useState(false);
  // const sideBar = useStore((state) => state.sideBar);
  // const { setSideBar } = useStore();
  const { myProfile, loading, error } = useMyProfile();

  const setSideBar = () => {
    const wrapper = document.querySelector(".authlayout-wrapper");
    if (wrapper.classList.contains("sidebar-is-open")) {
      wrapper.classList.remove("sidebar-is-open");
    } else {
      wrapper.classList.add("sidebar-is-open");
    }
  };

  // const [showTour, setShowTour] = useState(false);
  // useEffect(() => {
  //   const _checktour = localStorage.getItem("tourclosed");
  //   if (!_checktour && window.location.pathname === entryRoute) {
  //     setShowTour(true);
  //   }
  // }, [loading]);

  // const closeTour = () => {
  //   localStorage.setItem("tourclosed", true);
  //   setShowTour(false);
  // };

  useEffect(() => {
    if (error) {
      navigate("/auth/login", {
        state: {
          gobackto: window.location.href,
        },
      });
    }
  }, [error]);

  if (loading) return <LoaderLogo />;

  // const steps = [
  //   {
  //     selector: ".client-dashboard",
  //     content: (
  //       <div>
  //         <h4>Dashboard</h4>
  //         <p>
  //           This is your Accelerated home. Here you find an overview of the
  //           figures as well as details of the ended and upcoming tests.
  //         </p>
  //       </div>
  //     ),
  //   },
  //   {
  //     selector: ".property-selector",
  //     content: (
  //       <div>
  //         <h4>Property Selector</h4>
  //         <p>
  //           Select your shop’s Google Analytics property. Switch between the
  //           properties if you own more than 1 property
  //         </p>
  //       </div>
  //     ),
  //   },
  //   {
  //     selector: ".tests-ended",
  //     content: (
  //       <div>
  //         <h4>Ended Tests</h4>
  //         <p>Check your ended A/B tests and their reports.</p>
  //       </div>
  //     ),
  //   },
  //   {
  //     selector: ".ended-test-detail",
  //     content: (
  //       <div>
  //         <h4>Details</h4>
  //         <p>Click on Details to see the report for the selected A/B test.</p>
  //       </div>
  //     ),
  //   },
  //   {
  //     selector: ".tests-draft",
  //     content: (
  //       <div>
  //         <h4>Draft/Upcoming Tests</h4>
  //         <p>Check your upcoming A/B tests.</p>
  //       </div>
  //     ),
  //   },
  //   {
  //     selector: ".client-active-tests",
  //     content: (
  //       <div>
  //         <h4>Your Active Tests</h4>
  //         <p>
  //           In this tab, you find all your live A/B tests with real time
  //           reporting from Google Analytics.
  //         </p>
  //         <ul>
  //           <li>Select GA property to see the tests that are live.</li>
  //           <li>Select a test</li>
  //           <li>Select a Segment (ex. All Users)</li>
  //           <li>
  //             Select a secondary dimension if you want to see the performance
  //             for a more specific segment
  //           </li>
  //           <li>Click Get Reports to see the live report from GA.</li>
  //         </ul>
  //       </div>
  //     ),
  //   },
  // ];

  const is_client = myProfile?.role?.some((rl) => rl?.name === "CLIENT");
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  const { client } = !error && myProfile;
  if (!is_client) return <NotAuthorized />;

  return (
    <div>
      {/* <Tour
        disableFocusLock={true}
        // maskClassName="d-none"
        closeWithMask={false}
        className="sw_custom_tour"
        steps={steps}
        isOpen={showTour}
        lastStepNextButton={
          <span className="btn btn-sm btn-dark text-white">Let's Go</span>
        }
        onRequestClose={(_) => closeTour()}
      /> */}
      <div className="authlayout-wrapper sidebar-is-open">
        <AuthTopBar
          // sideBar={sideBar}
          setSideBar={setSideBar}
          logo={client?.logo}
          is_client={is_client}
          myProfile={myProfile}
          userId={myProfile?._id}
        />
        {/* <header className="header header-wrapper">
          <div className="header-inner">
            <div className="menu-icon">
              <span onClick={(e) => setSideBar(!sideBar)}>
                <MenuIcon />
              </span>
            </div>
            <div className="header-logo">
              <img
                src={`${window.location.origin}/Images/acceleratedfulllogo.png`}
                alt="Accelerated Logo"
              />
            </div>
            <div className="account-details">
              <div className="account-details-inner">
                <div className="notification">
                  <Bell />
                  <span className="notification-text"> notifications </span>
                </div>
                <div className="account">
                  <div className="user-info">
                    <div className="user-img"></div>
                    <div className="user-name">
                      <span className="name"> Daria </span>
                      <div className="dropdown">
                        <ArrowDown />
                      </div>
                    </div>
                  </div>
                  <span className="account-text" onClick={(_) => logOut()}>
                    Logout
                  </span>
                </div>
              </div>
            </div>
          </div>
        </header> */}

        <div className="authlayout-inner">
          <div className={`sidebar`}>
            <ClientSideBar
              myProfile={myProfile}
              setSideBar={setSideBar}
              vw={vw}
            />
          </div>
          <div className="client-layout-content dark-bg">
            <AnnouncementComponent myProfile={myProfile} loading={loading} />
            {/* {announcement && announcement?.length > 0 && (
              <div className="announcements-wrapper">
                <div className="announcements-inner">
                  <div className="announcement-text">
                    <p>
                      <strong> Announcement: </strong> {announcement[0]?.title}{" "}
                    </p>
                  </div>
                  <button
                    className="badge bg-dark text-white border-0"
                    onClick={(_) => {
                      setShowFullAnnouncement(!showFullAnnouncement);
                    }}
                  >
                    {showFullAnnouncement ? "Read Less" : "Read More"}
                  </button>
                </div>
                <div
                  className="announcement-full-text"
                  style={
                    showFullAnnouncement
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  {announcement[0]?.description}
                </div>
              </div>
            )} */}
            {/* <CustomNavigator
              disableBackNavigate={location.state?.disableBackNavigate}
            /> */}
            <Outlet context={[myProfile, is_client]} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientLayout;
