import { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  CREATECLIENT,
  CREATEGOAL,
  CREATEHYPOTHESIS,
  CREATEPAGE,
  CREATETAG,
  CREATETESTTYPE,
  CREATETRIGGER,
  CREATEUSER,
} from "../../Graphql/Mutations";
import { toast } from "react-toastify";
import useStore from "../../Store";
import Select from "react-select";
import { Currencies } from "../../Libs/Utilities";
import { UploadClientLogo } from "../../Libs/Cloudinary";
import { VscChromeClose, VscCloudUpload } from "react-icons/vsc";
import { BackIcon } from "../Shared/Icons";

function FormInstance({
  title,
  type,
  getpageobjectsrefetch,
  clientrefetch,
  gethyposrefetch,
  testtyperefetch,
  addtriggersrefetch,
  goalsrefetch,
  tagsrefetch,
  userrefetch,
  allroles,
  clients,
  tiers,
  closeForm,
  client,
  viewId,
  tagged,
}) {
  const { toggleForm } = useStore();
  const showForm = useStore((state) => state.showForm);
  const [createclient, cc] = useMutation(CREATECLIENT);
  const [createpage, cp] = useMutation(CREATEPAGE);
  const [createtesttype, ctt] = useMutation(CREATETESTTYPE);
  const [creategoal, cg] = useMutation(CREATEGOAL);
  const [createhypothesis, ch] = useMutation(CREATEHYPOTHESIS);
  const [createtrigger, ct] = useMutation(CREATETRIGGER);
  const [createtag, ctg] = useMutation(CREATETAG);
  const [createuser] = useMutation(CREATEUSER);
  const [role, setRole] = useState(null);
  const [selectedClients, setSelectedClients] = useState(null);
  const [gaProperties, setGaProperties] = useState([]);
  const [clientLogo, setClientLogo] = useState(null);
  const [clientCurrency, selectClientCurrency] = useState([]);

  const [tier, setTier] = useState(null);

  const currencies = Currencies();
  return (
    <div className="form-wrapper" id="add-new-test-form">
      <div className="form-wrapper-inner addtestform">
        <div className="card add-new-test-card" id="formcard">
          <button
            className="close-modal"
            onClick={(_) => {
              if (closeForm) {
                closeForm();
              } else {
                toggleForm(!showForm);
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="#000"
            >
              <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
            </svg>{" "}
          </button>
          <h3>{title}</h3>
          <div className="card-body p-0">
            <form
              onSubmit={async (_) => {
                _.preventDefault();
                const fieldvalue = _.target.fieldvalue.value;
                if (fieldvalue === "") {
                  toast.error("Please insert all fields");
                  return;
                }
                if (type === "adduser") {
                  const email = _.target.email.value;
                  const password = _.target.password.value;
                  const roleid = role?._id;
                  const client =
                    role?.name === "CLIENT"
                      ? selectedClients?.map((sc) => sc?.value)
                      : null;
                  if (email.trim() === "" || password.trim() === "" || !role) {
                    toast.error("Please fill all required fields");
                    return;
                  }
                  if (role && role?.name === "CLIENT" && !client) {
                    toast.error(`Please select client name for role "CLIENT"`);
                    return;
                  }
                  try {
                    const _createuser = await createuser({
                      variables: {
                        name: fieldvalue,
                        email,
                        password,
                        roleid,
                        client,
                      },
                    });
                    if (_createuser?.data) {
                      toast.success("New user added!");
                      userrefetch();
                      toggleForm(false);
                    }
                  } catch (error) {
                    toast.error(error?.message);
                  }
                }

                if (type === "addtag") {
                  try {
                    await createtag({
                      variables: {
                        name: fieldvalue,
                      },
                    });
                    toast("New tag created!");
                    toggleForm(false);
                    tagsrefetch();
                    ctg.reset();
                  } catch (error) {
                    toast.error(`${error?.message}`);
                  }
                }
                if (type === "addclient") {
                  const onboardDate = _.target.onboardDate.value;
                  if (
                    fieldvalue === "" ||
                    gaProperties?.length <= 0 ||
                    !clientCurrency ||
                    onboardDate === ""
                  ) {
                    toast.error("Please insert all fields");
                    return;
                  }
                  try {
                    await createclient({
                      variables: {
                        clientPayload: {
                          name: fieldvalue,
                          currency: clientCurrency?.value,
                          logo: clientLogo,
                          gaproperties: gaProperties,
                          tier: tier?.value,
                          onboardDate,
                        },
                      },
                    });
                    toast("New client created!");
                    toggleForm(false);
                    clientrefetch();
                    cc.reset();
                  } catch (error) {
                    toast.error(`${error?.message}`);
                  }
                }
                if (type === "addpage") {
                  const payload = {
                    name: fieldvalue,
                  };
                  try {
                    await createpage({
                      variables: {
                        payload,
                      },
                    });
                    toast("New Page/Object created!");
                    toggleForm(false);
                    getpageobjectsrefetch();
                    cp.reset();
                  } catch (error) {
                    toast.error(`${error?.message}`);
                  }
                }
                if (type === "addtesttype") {
                  const details = _.target.testtypedetails.value;
                  try {
                    await createtesttype({
                      variables: {
                        testTypePayload: {
                          name: fieldvalue,
                          details: details,
                        },
                      },
                    });
                    toast("New test type created!");
                    toggleForm(false);
                    testtyperefetch();
                    ctt.reset();
                  } catch (error) {
                    toast.error(`${error?.message}`);
                  }
                }
                if (type === "addgoals") {
                  const details = _.target.testtypedetails.value;
                  try {
                    await creategoal({
                      variables: {
                        goalPayload: {
                          name: fieldvalue,
                          details: details,
                        },
                      },
                    });
                    toast("New Goal created!");
                    toggleForm(false);
                    goalsrefetch();
                    cg.reset();
                  } catch (error) {
                    toast.error(`${error?.message}`);
                  }
                }

                if (type === "addhypothesis") {
                  const hypoNumber = _.target.hypoNumber.value;
                  const hypoAction = _.target.hypoAction.value;
                  const hypoResult = _.target.hypoResult.value;
                  const hypoExpectation = _.target.hypoExpectation.value;
                  const hypoTagged = _.target.hypoTagged.value;
                  const hypothesisPayload = {
                    hypothesisNumber: hypoNumber,
                    name: fieldvalue,
                    action: hypoAction,
                    result: hypoResult,
                    expectation: hypoExpectation,
                    tagged: hypoTagged,
                  };
                  if (client && viewId) {
                    hypothesisPayload.client = client;
                    hypothesisPayload.viewId = viewId;
                  }

                  try {
                    await createhypothesis({
                      variables: {
                        hypothesisPayload,
                      },
                    });
                    toast("New Hypothesis created!");
                    if (closeForm) {
                      closeForm();
                    } else {
                      toggleForm(false);
                      ch.reset();
                    }
                    gethyposrefetch();
                  } catch (error) {
                    toast.error(`${error?.message}`);
                  }
                }
                if (type === "addtriggers") {
                  try {
                    await createtrigger({
                      variables: {
                        triggerPayload: {
                          name: fieldvalue,
                        },
                      },
                    });
                    toast("New Trigger created!");
                    toggleForm(false);
                    addtriggersrefetch();
                    ct.reset();
                  } catch (error) {
                    toast.error(`${error?.message}`);
                  }
                }
              }}
            >
              <div className="fields-wrapper">
                <label>Name</label>
                <input
                  name="fieldvalue"
                  className="field-control"
                  placeholder={`${title}`}
                />
              </div>

              {(type === "addtesttype" || type === "addgoals") && (
                <div className="fields-wrapper">
                  <label>Details</label>

                  <input
                    name="testtypedetails"
                    className="field-control"
                    placeholder="Add few details"
                  />
                </div>
              )}

              {type === "addclient" && (
                <>
                  <div className="fields-wrapper">
                    <label> Client OnBoard Date</label>
                    <input
                      type="date"
                      className="field-control"
                      name="onboardDate"
                    />
                  </div>

                  <div className="fields-wrapper">
                    <label> Select Base Currency</label>
                    <Select
                      classNamePrefix="acc-select"
                      placeholder="Select Currency"
                      onChange={(_) => selectClientCurrency(_)}
                      options={currencies.map((cr) => {
                        return {
                          label: cr,
                          value: cr,
                        };
                      })}
                    />
                  </div>

                  <div className="fields-wrapper">
                    <label>Tier</label>
                    <Select
                      classNamePrefix="acc-select"
                      placeholder="Select Tier"
                      defaultValue={tier}
                      onChange={(_) => setTier(_)}
                      options={tiers.map((tr) => {
                        return {
                          label: tr?.name,
                          value: tr?._id,
                        };
                      })}
                    />
                  </div>

                  <div className="control-variant-image-container">
                    <div
                      className="client-logo"
                      style={
                        clientLogo
                          ? { backgroundImage: `url(${clientLogo})` }
                          : {}
                      }
                    >
                      <div className="upload-icon">
                        <VscCloudUpload
                          onClick={(_) => {
                            _.currentTarget.nextSibling.click();
                          }}
                        />
                        <input
                          className="field-control"
                          onChange={async (_) => {
                            if (_.currentTarget.files?.length > 0) {
                              const _fileupload = await UploadClientLogo(
                                _.currentTarget.files[0]
                              );
                              if (_fileupload?.error) {
                                alert(_fileupload?.error?.message);
                              } else {
                                setClientLogo(_fileupload?.secure_url);
                              }
                            }
                          }}
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          style={{ display: "none" }}
                        />
                        {clientLogo && (
                          <VscChromeClose
                            onClick={(_) => {
                              setClientLogo(null);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center flex-wrap">
                    <div className="fields-wrapper w-50">
                      <label> Property Name</label>
                      <input
                        id="propertyName"
                        className="field-control"
                        placeholder="Property Name"
                      />
                    </div>
                    <div className="fields-wrapper w-50">
                      <label> View ID</label>
                      <input
                        id="propertyViewId"
                        className="field-control"
                        placeholder="View ID"
                      />
                    </div>
                    <div className="fields-wrapper w-100">
                      <button
                        type="button"
                        className="btn btn-sm btn-dark ms-auto d-block"
                        onClick={(_) => {
                          const _n =
                            document.querySelector("#propertyName").value;
                          const _v =
                            document.querySelector("#propertyViewId").value;
                          if (_n.trim() === "" || _v.trim() === "") {
                            return;
                          }
                          setGaProperties((_old) => [
                            ..._old,
                            {
                              propertyname: _n,
                              viewId: _v,
                            },
                          ]);
                          document.querySelector("#propertyName").value = "";
                          document.querySelector("#propertyViewId").value = "";
                        }}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="list-group mb-4">
                    {gaProperties.map((prps, i) => {
                      return (
                        <a
                          key={i}
                          className="list-group-item list-group-item-action flex-column align-items-start"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <span
                              className="rmve"
                              onClick={(_) => {
                                const _prps = [...gaProperties];
                                const newItems = _prps.filter(
                                  (it) => it?.viewId !== prps?.viewId
                                );
                                setGaProperties(newItems);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="#000"
                              >
                                <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                              </svg>
                            </span>
                          </div>
                          <p className="mb-1">
                            {prps?.propertyname} - {prps?.viewId}
                          </p>
                        </a>
                      );
                    })}
                  </div>
                </>
              )}

              {type === "addhypothesis" && (
                <>
                  <div className="fields-wrapper">
                    <label> Hypothesis Number</label>
                    <input
                      className="field-control"
                      name="hypoNumber"
                      placeholder="Hypothesis Number"
                    />{" "}
                  </div>
                  <div className="fields-wrapper">
                    <label>If we do x</label>
                    <input
                      className="field-control"
                      name="hypoAction"
                      placeholder="If we do x"
                    />{" "}
                  </div>
                  <div className="fields-wrapper">
                    <label> then y happens</label>
                    <input
                      className="field-control"
                      name="hypoResult"
                      placeholder="Then y happens"
                    />{" "}
                  </div>
                  <div className="fields-wrapper">
                    <label> Because we expect</label>
                    <input
                      className="field-control"
                      name="hypoExpectation"
                      placeholder="Because we expect"
                    />{" "}
                  </div>
                  <div
                    className="fields-wrapper"
                    style={tagged ? { display: "none" } : {}}
                  >
                    <label> Tag</label>
                    <select
                      className="field-control"
                      name="hypoTagged"
                      defaultValue={tagged}
                    >
                      <option value="General Purpose"> General Purpose</option>
                      <option value="Evergreen"> Evergreen</option>
                      <option value="Client Specific"> Client Specific</option>
                    </select>
                    {/* <input
                      className="field-control"
                      name="hypoTagged"
                      placeholder="T"
                    />{" "} */}
                  </div>
                </>
              )}
              {type === "adduser" && (
                <>
                  <div className="fields-wrapper">
                    <label> Email</label>
                    <input
                      className="field-control"
                      name="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="fields-wrapper">
                    <label> Create Password</label>
                    <input
                      className="field-control"
                      name="password"
                      placeholder="Password"
                    />
                  </div>
                  <div className="fields-wrapper">
                    <label>Assign a role</label>
                    <select
                      className="field-control"
                      onChange={(_) => {
                        if (_?.currentTarget.value !== "") {
                          setRole(JSON.parse(_?.currentTarget.value));
                          if (
                            JSON.parse(_?.currentTarget.value)?.name ===
                            "CLIENT"
                          ) {
                            document.querySelector("#formcard").style.height =
                              "650px";
                          } else {
                            document.querySelector("#formcard").style.height =
                              "auto";
                          }
                        } else {
                          setRole(null);
                        }
                      }}
                    >
                      <option value="">Select role</option>
                      {allroles?.map((role) => (
                        <option key={role?._id} value={JSON.stringify(role)}>
                          {role?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {role && role?.name === "CLIENT" && (
                    <div className="fields-wrapper">
                      <label>Select Client</label>
                      <Select
                        classNamePrefix="acc-select"
                        isMulti={true}
                        placeholder="Select Client(s)"
                        defaultValue={selectedClients}
                        onChange={(_) => setSelectedClients(_)}
                        options={clients}
                      />

                      {/* <select name="clientId" className="field-control">
                        <option value="">Select Client</option>
                        {allclients?.map((client) => (
                          <option key={client?._id} value={client?._id}>
                            {client?.name}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  )}
                </>
              )}
              <div className="add__new__test__form-footer">
                <div
                  className="btn-prev"
                  onClick={(_) => toggleForm(!showForm)}
                >
                  <BackIcon />
                  Cancel
                </div>

                <button type="submit" className="btn next-btn">
                  Add Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FormInstance;
