import React, { useEffect, useMemo, useState } from "react";
import "./ClientSettings.scss";
import { CLIENTSCRIPT } from "../../../Graphql/Queries";
import { LoaderLogo } from "../../Shared/LoaderLogo";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATECLIENTSCRIPT } from "../../../Graphql/Mutations";
import { toast } from "react-toastify";
export default function ClientSettings({ client, viewId }) {
  const { data, loading, error, refetch } = useQuery(CLIENTSCRIPT, {
    variables: { client, viewId },
  });
  const [updateclientscript] = useMutation(UPDATECLIENTSCRIPT);

  if (loading) return <LoaderLogo />;
  if (error) return <div>{error.message}</div>;
  const { getClientScript } = data;
  return (
    <section className="ab-test-overview">
      <div className="experiment-lists">
        {getClientScript?.clientScriptURL && (
          <div className="experiment-box">
            <div className="experiment-box-head">
              <h3>Script URL</h3>
            </div>
            <div className="experiment-overview active">
              <div className="fields-wrapper">
                <label className="nofloat">Script URL</label>
                <input
                  type="text"
                  name="jscode"
                  readOnly
                  onClick={(e) => {
                    navigator.clipboard.writeText(
                      `https://res.cloudinary.com/acceleratedagency/raw/upload/CLIENTSCRIPTS/${getClientScript?.clientScriptURL
                        ?.split("/")
                        .pop()}`
                    );
                    toast.success("Copied to Clipboard");
                  }}
                  defaultValue={
                    getClientScript?.clientScriptURL
                      ? `https://res.cloudinary.com/acceleratedagency/raw/upload/CLIENTSCRIPTS/${getClientScript?.clientScriptURL
                          ?.split("/")
                          .pop()}`
                      : ""
                  }
                  className="field-control  field-control__updated darkinput"
                />
              </div>
            </div>
          </div>
        )}

        <form
          className="experiment-box"
          onSubmit={async (e) => {
            e.preventDefault();
            const form = e.target;
            const debug = form.debug.checked;
            const restrict_debug = form.restrict_debug.checked;
            const is_spa = form.is_spa.checked;
            const run_only_on_reinit = form.run_only_on_reinit.checked;
            const jscode = form.jscode.value;
            const csscode = form.csscode.value;
            const clientScriptData = {
              debug,
              restrict_debug,
              is_spa,
              run_only_on_reinit,
              jscode,
              csscode,
            };
            await updateclientscript({
              variables: {
                client,
                viewId,
                clientScriptData,
              },
            });
            toast.success("Client Script Updated");
            refetch();
          }}
        >
          <div className="experiment-box-head">
            <h3>Project Settings {getClientScript?.client?.name}</h3>
            {/* <p>{moment(singletest?.createdAt).format("LL")}</p> */}
          </div>
          <div className="experiment-overview active" id="add-new-test-form">
            <h2> General Settings</h2>
            {/* <p>This experiment is in mode. It will only be visible to you.</p> */}

            <div className="fields-wrapper inline-checkbox">
              <label className="nofloat">Debug Mode</label>
              <div className="checkbox-field-wrapper">
                <input
                  type="checkbox"
                  name="debug"
                  defaultChecked={getClientScript?.debug}
                  className="field-control  field-control__updated darkinput"
                />
                <span>
                  If checked, Info is provided in the Browser's Console
                </span>
              </div>
            </div>
            <div className="fields-wrapper inline-checkbox">
              <label className="nofloat">Restrict Debug Mode</label>
              <div className="checkbox-field-wrapper">
                <input
                  type="checkbox"
                  name="restrict_debug"
                  defaultChecked={getClientScript?.restrict_debug}
                  className="field-control  field-control__updated darkinput"
                />
                <span>
                  If checked, you have to set an additional Parameter to see the
                  Debug Output. <br />
                  ?codebase_show_debug=true
                </span>
              </div>
            </div>
            <div className="fields-wrapper inline-checkbox">
              <label className="nofloat">Single Page Application Feature</label>
              <div className="checkbox-field-wrapper">
                <input
                  type="checkbox"
                  defaultChecked={getClientScript?.is_spa}
                  name="is_spa"
                  className="field-control  field-control__updated darkinput"
                />
                <span>
                  Use Features for dynamic Pages (i.e. SPAs) like React,
                  Angular, Vue, etc.
                </span>
              </div>
            </div>
            <div className="fields-wrapper inline-checkbox">
              <label className="nofloat">Only Init Script on API Call</label>
              <div className="checkbox-field-wrapper">
                <input
                  type="checkbox"
                  defaultChecked={getClientScript?.run_only_on_reinit}
                  name="run_only_on_reinit"
                  className="field-control  field-control__updated darkinput"
                />
                <span>
                  Only run on codebase.reInit(). When checked, the sequence only
                  starts on the call of codebase.reInit(). Otherwise the
                  sequence also starts on each page load by default.
                </span>
              </div>
            </div>
            <div className="fields-wrapper inline-checkbox">
              <label className="nofloat">Anti Flicker Management</label>
              <div className="checkbox-field-wrapper">
                <input
                  type="checkbox"
                  defaultChecked={getClientScript?.use_antiflicker}
                  name="use_antiflicker"
                  className="field-control  field-control__updated darkinput"
                />
                <span>
                  If enabled, the Page either shows after your Scripts have run
                  or after 2 Seconds as Fallback.
                </span>
              </div>
            </div>

            <div className="fields-wrapper">
              <label className="nofloat">JS Code (optional)</label>
              <textarea
                type="text"
                name="jscode"
                defaultValue={getClientScript?.jscode}
                className="field-control  field-control__updated darkinput"
              />
            </div>

            <div className="fields-wrapper">
              <label className="nofloat">CSS Code (optional)</label>
              <textarea
                type="text"
                defaultValue={getClientScript?.csscode}
                className="field-control  field-control__updated darkinput"
                name="csscode"
              />
            </div>
          </div>

          <div className="experiment-box-footer">
            <div>
              Snippet Revisions:{" "}
              <strong> {getClientScript?.snippet_revision ?? 1} </strong>
            </div>
            <div>
              <button type="submit" className="btn btn-primary text-white">
                Update Details
              </button>
            </div>
          </div>
        </form>
        <form
          className="experiment-box"
          onSubmit={async (e) => {
            e.preventDefault();
            const form = e.target;
            const rules = form.rules.value;
            const rules_tracking = form.rules_tracking.value;
            const clientScriptData = {
              rules,
              rules_tracking,
            };
            await updateclientscript({
              variables: {
                client,
                viewId,
                clientScriptData,
              },
            });
            toast.success("Client Script Updated");
            refetch();
          }}
        >
          <div className="experiment-box-head">
            <h3> Prerequisites</h3>
            {/* <p>{moment(singletest?.createdAt).format("LL")}</p> */}
          </div>
          <div className="experiment-overview active" id="add-new-test-form">
            <h2> Script will initiate only after these settings</h2>
            {/* <p>This experiment is in mode. It will only be visible to you.</p> */}

            <div className="fields-wrapper">
              <label className="nofloat">
                Rules - General Startup (Run Variations) <br />
                <small className="text-primary">
                  {" "}
                  If you return true, the project will be processed further and
                  potentially show variations. This Function is executed only
                  once and immediately.
                </small>
              </label>
              <textarea
                type="text"
                name="rules"
                defaultValue={getClientScript?.rules}
                className="field-control  field-control__updated darkinput"
              />
            </div>

            <div className="fields-wrapper">
              <label className="nofloat">
                Rules - Tracking and Browser Storage <br />
                <small className="text-primary">
                  {" "}
                  If you return true, the tracking and storing data in the
                  browser is enabled. This Function is executed only once and
                  immediately.
                </small>
              </label>
              <textarea
                type="text"
                defaultValue={getClientScript?.rules_tracking}
                className="field-control  field-control__updated darkinput"
                name="rules_tracking"
              />
            </div>
          </div>

          <div className="experiment-box-footer">
            <div>
              Snippet Revisions:{" "}
              <strong> {getClientScript?.snippet_revision ?? 1} </strong>
            </div>
            <div>
              <button type="submit" className="btn btn-primary text-white">
                Update Setting
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
