import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { VscInfo } from "react-icons/vsc";

export const DetailsTab = ({
  index,
  title,
  vlusers,
  vlsessions,
  vltransactions,
  vlCRsession,
  vlCRuser,
  vlAVGRPU,
  vlAOV,
  vlrevenue,
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <div className="visual-box-outer">
      <h2> {title.replaceAll("-", " ")}</h2>
      <div
        className="visual-box"
        style={title === "Total" ? { backgroundColor: "#f1f1f1" } : {}}
      >
        <div className="users">
          <label>
            Users
            {title === "Total" && (
              <span data-tip={`Users`} className="toottip-span">
                <VscInfo />
              </span>
            )}
          </label>
          <div className="value">{vlusers}</div>
        </div>
        <div className="conversions">
          <label>
            Sessions
            {title === "Total" && (
              <span data-tip={`Sessions`} className="toottip-span">
                <VscInfo />
              </span>
            )}
          </label>
          <div className="value">{vlsessions}</div>
        </div>
        <div className="conversion-rate">
          <label>
            Transactions
            {title === "Total" && (
              <span data-tip={`Transactions`} className="toottip-span">
                <VscInfo />
              </span>
            )}
          </label>
          <div className="value">{vltransactions} </div>
        </div>
        <div className="conversion-rate">
          <label>
            CR (Sessions)
            {title === "Total" && (
              <span
                data-tip={`Transactions / Sessions`}
                className="toottip-span"
              >
                <VscInfo />
              </span>
            )}
          </label>
          <div className="value">{(vlCRsession * 100).toFixed(2)} % </div>
        </div>
        <div className="conversion-rate">
          <label>
            CR (Users)
            {title === "Total" && (
              <span data-tip={`Transactions / Users`} className="toottip-span">
                <VscInfo />
              </span>
            )}
          </label>
          <div className="value">{(vlCRuser * 100).toFixed(2)} % </div>
        </div>
        <div className="conversion-rate">
          <label>
            Revenue
            {title === "Total" && (
              <span data-tip={`Revenue`} className="toottip-span">
                <VscInfo />
              </span>
            )}
          </label>
          <div className="value">{vlrevenue.toLocaleString()}</div>
        </div>
        <div className="conversion-rate">
          <label>
            Avg. RPU
            {title === "Total" && (
              <span data-tip={`Revenue / Users`} className="toottip-span">
                <VscInfo />
              </span>
            )}
          </label>
          <div className="value">{vlAVGRPU.toFixed(2)} </div>
        </div>
        <div className="conversion-rate">
          <label>
            AOV
            {title === "Total" && (
              <span
                data-tip={`Revenue / Transactions`}
                className="toottip-span"
              >
                <VscInfo />
              </span>
            )}
          </label>
          <div className="value">{!isNaN(vlAOV) ? vlAOV.toFixed(2) : 0} </div>
        </div>
      </div>
    </div>
  );
};
