import React, { useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { differenceInCalendarDays, format } from "date-fns";
import { useState } from "react";
import { ALLTESTS } from "../../../Graphql/Queries";
import useStore from "../../../Store";
import CalendarComponent from "../../MiroComponents/CalenderComponent";
import MyLoader from "../../MiroComponents/PreLoader";
import { RiCalendarEventFill } from "react-icons/ri";
import { Pagination } from "../../Shared/Paginations";
import { FiltersComponent } from "../../Shared/FiltersComponent";
import { Scrubber } from "../../Shared/Scrubber";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { ImplementedStatusOptions } from "../../../Libs/ReportFiltersValues";
import {
  DELETEREPORTANDPUSHTESTDRAFT,
  UPDATEREPORTDETAILS,
} from "../../../Graphql/Mutations";
import { toast } from "react-toastify";
import {
  ImagePlaceholder,
  PencilEditIcon,
  ReportShareIcon,
  ShowReportIcon,
  CrossIcon,
} from "../../Shared/Icons";
import Select from "react-select";
import { CreateTestFormNew } from "../../MiroComponents/CreateTestFormNew";
import { ClientReportDetailsandInsights } from "../../NewComponents/ClientReportDetailsandInsights";

function Tests() {
  const [is_admin, is_user] = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(30);
  const [selectedReport, setSelectedReport] = useState(null);
  const [scrubber, showScrubber] = useState(null);
  const [test, setTest] = useState(null);
  const [serverFilters, setServerFilters] = useState([]);
  const [filter, showFilter] = useState(false);
  const [testObj, setTestObj] = useState(null);
  const [module, setModule] = useState(null);
  const initialImplementedStatus = {
    label: "",
    value: "",
  };
  const [implementedStatuses, setImplementedStatuses] = useState(
    initialImplementedStatus
  );
  const { data, loading, refetch } = useQuery(ALLTESTS, {
    variables: {
      id: null,
      propertyId: null,
      active: null,
      page: page,
      size: size,
      testFilters: serverFilters?.length > 0 ? serverFilters : null,
    },
  });
  const defaultImplementedStatus = { label: "", value: "" };

  const [updatereportdetails] = useMutation(UPDATEREPORTDETAILS);
  const [deleteTestPushTestDraft] = useMutation(DELETEREPORTANDPUSHTESTDRAFT);
  const { toggleForm } = useStore();
  const showForm = useStore((state) => state.showForm);

  const setTestNull = () => {
    setTest(null);
  };

  useEffect(() => {
    if (location?.state?.page) {
      setPage(location?.state?.page);
    }
  }, [location]);

  const testCanBeEvaluated = (liveDate, testId, clientId, propertyId) => {
    const days = Number(
      differenceInCalendarDays(new Date(), new Date(liveDate))
    );
    if (days < 30) {
      return (
        <span>
          - Results can probably evaluated in
          <strong className="d-inline-flex ms-1 me-1">
            {30 - Number(days)}
          </strong>
          days
        </span>
      );
    } else {
      return (
        <span>
          <span className="d-inline-flex align-items-center">
            - Can be evaluated now
            {/* <button
              className="badge ms-1 text-dark fw-normal"
              onClick={(_) => {
                navigate("/googleanalyticsdata", {
                  state: {
                    clientId,
                    propertyId,
                    testId,
                  },
                });
              }}
            >
              View live Report <VscGraph />
            </button> */}
          </span>
        </span>
      );
    }
  };

  const TestCurrentStatus = ({ test }) => {
    const status = test?.status;
    if (status === "ended") {
      return (
        <span className="ended">
          <span>{status}</span>
          {is_admin && (
            <button
              className="remove-report"
              onClick={async (_) => {
                if (
                  window.confirm(
                    "Are you sure you want to delete report and push this test to draft again ?"
                  )
                ) {
                  await deleteTestPushTestDraft({
                    variables: {
                      testId: test?._id,
                    },
                  });
                  toast.success(
                    "Report deleted. Test is pushed to draft state again."
                  );
                  refetch();
                }
              }}
            >
              Delete Report
            </button>
          )}
        </span>
      );
    }
    if (status === "live") {
      return (
        <span className="live">
          {status}
          <span>
            <div>
              <RiCalendarEventFill className="me-1" />
              {format(new Date(test?.livedate), "dd MMMM yyyy")}{" "}
            </div>
            {testCanBeEvaluated(
              test?.livedate,
              test?._id,
              test?.client?._id,
              test?.property?.value
            )}
          </span>{" "}
        </span>
      );
    }
    if (status === "discard") {
      return <span className="discard"> {status} </span>;
    }
    return (
      <span
        className="draft"
        onClick={(_) => {
          if (test.testid === "" || !test?.recommendedsamplesize) {
            toggleForm(!showForm);
            setTestObj(test);
            setModule("edit");
          } else {
            setTest(test);
          }
        }}
      >
        {status}
      </span>
    );
  };

  const ImplementedStatus = ({ test }) => {
    const { status } = test;
    if (status === "ended") {
      const selectedImplementedStatus = ImplementedStatusOptions.find(
        (item) => {
          return item.value === test?.report?.reportDetails?.implementedStatus;
        }
      );
      return (
        <div className="fields-wrapper">
          <div>
            <Select
              className="acc-newselectwrapper"
              classNamePrefix="acc-newselect"
              defaultValue={
                selectedImplementedStatus ?? defaultImplementedStatus
              }
              placeholder="Implemented Status"
              onChange={async (status) => {
                const newObj = { ...implementedStatuses, [test?._id]: status };
                setImplementedStatuses(newObj);
                const updatedDetails = {
                  ...test?.report?.reportDetails,
                  implementedStatus: status?.value,
                };
                await updatereportdetails({
                  variables: {
                    id: test?.report?._id,
                    reportDetails: updatedDetails,
                  },
                });
              }}
              options={ImplementedStatusOptions}
              value={
                (implementedStatuses && implementedStatuses[test?._id]) ||
                selectedImplementedStatus ||
                defaultImplementedStatus
              }
            />
          </div>
        </div>
      );
    }

    return null;
  };

  const TestRowComponent = ({ test }) => {
    const controlimage = test?.controlimage;
    const variationimage = test?.variationimage;
    return (
      <div className="test-details-table-data">
        <div className="test-name text-break">
          <h3
            onClick={(e) => {
              if (test?.testtool === "Codebase") {
                navigate(`/ab-testing-dashboard/${test?._id}`);
              }
            }}
          >
            {test?.name}
          </h3>
        </div>
        <div className="hypothesis-data">
          <h3>{test?.hypothesis?.name}</h3>
        </div>
        <div
          className="test-compare-img"
          style={
            !controlimage || !variationimage
              ? {
                  pointerEvents: "none",
                  opacity: 0.5,
                }
              : {}
          }
        >
          <span
            onClick={(_) => {
              if (!scrubber) {
                showScrubber({
                  control: controlimage,
                  variation: variationimage,
                });
              }
            }}
          >
            <ImagePlaceholder />
          </span>
        </div>
        <div className="test-status">
          <ImplementedStatus test={test} />
        </div>
        <div className="test-status">
          <TestCurrentStatus test={test} />
        </div>
        <div className="test-action-wrapper">
          <div className="test-action">
            <div
              style={
                test?.status !== "ended"
                  ? { pointerEvents: "none", opacity: 0.5 }
                  : {}
              }
              className="action-group"
              onClick={(_) => {
                setSelectedReport(test?.report?._id);
              }}
            >
              <ShowReportIcon />
              <span>Report</span>
            </div>
            <div
              style={
                test?.status !== "ended"
                  ? { pointerEvents: "none", opacity: 0.5 }
                  : {}
              }
              className="action-group"
              onClick={(_) => {
                navigator.clipboard.writeText(
                  `${window.location.host}/sharereport/${test?._id}`
                );
                toast.success("Link Copied.");
              }}
            >
              <ReportShareIcon />
              <span>Share</span>
            </div>

            <div
              className="action-group"
              onClick={(_) => {
                toggleForm(!showForm);
                setTestObj(test);
                setModule("edit");
              }}
            >
              <PencilEditIcon />
              <span>Edit</span>
            </div>
          </div>
        </div>
      </div>
    );
  };
  if (loading) return <MyLoader />;
  return (
    <div>
      {filter && (
        <FiltersComponent
          serverFilters={serverFilters}
          setServerFilters={setServerFilters}
          setPage={setPage}
          filterTypes={[
            "findtestbyclientid",
            "findtestbyteststatus",
            "findtestbytestname",
            "testscanbeevaluatednow",
            "findtestbytesttool",
            "findtestbypageobjecttype",
          ]}
        />
      )}

      <div className="header-bar">
        <div className="header-bar-inner">
          <h2> Tests</h2>
          <div>
            <button
              className="btn-as-link d-inline-flex align-items-center text-white"
              onClick={(_) => {
                if (filter) {
                  setPage(1);
                  setServerFilters([]);
                }
                showFilter(!filter);
              }}
            >
              {filter ? "Close Filters" : "Filters"}
            </button>
            {!is_user && (
              <>
                <button
                  className="add"
                  onClick={(_) => {
                    toggleForm(!showForm);
                    setModule("add");
                    setTestObj(null);
                  }}
                >
                  Add Test
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {scrubber && <Scrubber showScrubber={showScrubber} scrubber={scrubber} />}
      {test && (
        <CalendarComponent
          test={test}
          setTestNull={setTestNull}
          refetch={refetch}
        />
      )}
      {showForm && (
        <CreateTestFormNew
          showForm={showForm}
          refetch={refetch}
          testObj={testObj}
          module={module}
          setModule={setModule}
        />
      )}

      <div className="table-body">
        <div className="test-details-table-data heading-row">
          <div className="test-name">
            <h3>Name</h3>
          </div>
          <div className="hypothesis-data">
            <h3>Hypothesis</h3>
          </div>
          <div className="test-compare-img">
            <span>Compare</span>
          </div>
          <div className="test-status">
            <span>Implemented Status</span>
          </div>
          <div className="test-status">
            <span> Status</span>
          </div>
          <div className="test-action-wrapper">
            <div className="test-action">
              <span> Action</span>
            </div>
          </div>
        </div>
        {data?.alltests?.tests?.map((test, i) => (
          <TestRowComponent test={test} key={i} />
        ))}
      </div>
      <Pagination
        page={page}
        size={size}
        setPage={setPage}
        setSize={setSize}
        totalcount={data?.alltests?.total}
      />

      {selectedReport && (
        <div className="report-details-modal">
          <div className="report-details-modal-inner">
            <div className="close-modal">
              <button onClick={(e) => setSelectedReport(null)}>
                {" "}
                <CrossIcon />
              </button>
            </div>
            <div className="report-details-modal-content-wrapper">
              <ClientReportDetailsandInsights selectedReport={selectedReport} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Tests;
