export const clientSurveyQuestions = [
  {
    question: "Do you use codebase to check your test data?",
    type: "radio",
    options: ["Yes", "No"],
  },
  {
    question: "How frequently are you using codebase per month/week?",
    type: "text",
  },
];
