import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { NOTIFICATIONSREADCOUNT } from "../../Graphql/Queries";
import { logOut } from "../../Libs/Utilities";
import { NotificationSidebar } from "../Shared/NotificationSidebar";
import { SettingsSidebar } from "../Shared/SettingsSidebar";
import useStore from "../../Store";
import { ArrowDown, Bell, MenuIcon, TimesCross } from "../Shared/Icons";
import { Faq } from "../Shared/Faq";
import ChangePasswordModal from "../Pages/ChangePasswordModal";
import { FaCogs, FaLock } from "react-icons/fa";

function AuthTopBar({
  sideBar,
  is_admin,
  is_client,
  userId,
  setSideBar,
  myProfile,
}) {
  const [notificationBar, setNotificationBar] = useState(false);

  const [isModal, setIsModal] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const showFaq = useStore((state) => state.showFaq);
  const [notificationReadCount] = useLazyQuery(NOTIFICATIONSREADCOUNT);
  const _notificationCount = useCallback(async () => {
    const { data } = await notificationReadCount({
      variables: {
        userId,
      },
    });
    setNotificationCount(
      data?.notificationReadCount?.total - data?.notificationReadCount?.read
    );
  }, [userId]);

  useEffect(() => {
    if (is_client && userId) {
      _notificationCount();
    }
    // setInterval(() => {
    //   _notificationCount();
    // }, 600000);
  }, []);

  // useEffect(() => {
  //   if (is_client && userId) {
  //     _notificationCount();
  //   }
  //   setInterval(() => {
  //     _notificationCount();
  //   }, 600000);
  // }, [_notificationCount]);

  const nameInitial = useMemo(() => {
    return myProfile?.name?.charAt(0);
  }, [myProfile]);

  return (
    <>
      <header className="header header-wrapper">
        <div className="header-inner">
          <div className="menu-icon">
            <div className="notification" onClick={(e) => setSideBar()}>
              <MenuIcon />
              <span className="notification-text">MENU</span>
            </div>
          </div>
          <div className="header-logo">
            <img
              src={`${window.location.origin}/Images/acceleratedfulllogo.png`}
              alt="Accelerated Logo"
            />
          </div>
          <div className="account-details">
            <div className={`account-details-inner`}>
              <div
                className="notification"
                onClick={(e) => setNotificationBar(!notificationBar)}
              >
                <Bell />
                <span className="notification-text"> notifications </span>
              </div>

              {is_admin && (
                <div className="dropdown">
                  <a
                    className="dropdown-toggle notification text-white"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <FaCogs />
                    <span className="notification-text"> Settings </span>
                  </a>
                  <SettingsSidebar is_admin={is_admin} />
                </div>
              )}

              <div className="account">
                <div className="user-info">
                  <div className="user-img"></div>
                  <div className="user-name" data-initials={nameInitial}>
                    <div className="dropdown">
                      <a
                        className="dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span className="name">
                          {myProfile?.name ?? `User`}{" "}
                        </span>
                        <ArrowDown />
                      </a>
                      <ul
                        className="dropdown-menu topbar-dropdown"
                        aria-labelledby="dropdownMenuButton2"
                      >
                        <li>
                          <a
                            onClick={() => {
                              setIsModal(true);
                            }}
                          >
                            <span className="icon">
                              <FaLock />
                            </span>
                            <span>Change Password </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <span className="account-text" onClick={(_) => logOut()}>
                  Logout
                </span>
              </div>
              <div className="closemenu" onClick={(e) => setSideBar(!sideBar)}>
                <TimesCross />
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className={`notification-bar ${notificationBar ? `open` : ``}`}>
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
            Notifications
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            onClick={(e) => setNotificationBar(!notificationBar)}
          ></button>
        </div>
        <NotificationSidebar
          is_client={is_client}
          is_admin={is_admin}
          userId={userId}
          notificationBar={notificationBar}
        />
      </div>
      {showFaq && <Faq />}
      {isModal && (
        <ChangePasswordModal setIsModal={setIsModal} myProfile={myProfile} />
      )}
    </>
  );
}

export default AuthTopBar;
