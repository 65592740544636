import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { ListHeader } from "./ListHeader";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import Select from "react-select";
import { CREATEGOALS, UPDATEGOALS } from "../../../Graphql/Mutations";
import { GOALSPAGINATION } from "../../../Graphql/Queries";
import { LoaderLogo } from "../../Shared/LoaderLogo";

const GOALS_HEADER = [
  "Name",
  "Details",
  //   "Type",
  //   "Counting Method",
  //   "CSS Selector",
  "Rules Js",
  "Edit/Delete",
];
export const GoalsTargeting = ({
  type,
  module,
  formType,
  currentData,
  client,
  setModule,
  setCurrentData,
  setFormType,
}) => {
  const [createGoals] = useMutation(CREATEGOALS);
  const [updateGoals] = useMutation(UPDATEGOALS);

  const { data, loading, error, refetch } = useQuery(GOALSPAGINATION, {
    variables: {
      filters: [
        {
          client: client,
        },
      ],
    },
  });

  if (loading) return <LoaderLogo />;
  if (error) return <div>Error: {error.message}</div>;
  return (
    <>
      {type === "form" ? (
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const {
              name,
              details,
              type,
              counting_method,
              css_selector,
              rules_js,
            } = e.target;
            const goalPayload = {
              client: client,
              name: name.value,
              details: details.value,
              type: type.value,
              counting_method: counting_method.value,
              css_selector: css_selector.value,
              rules_js: rules_js.value,
            };
            if (formType === "add") {
              await createGoals({
                variables: {
                  goalPayload,
                },
              });
              toast.success("Goals Added Successfully");
            } else {
              await updateGoals({
                variables: {
                  id: currentData?._id,
                  goalPayload,
                },
              });
              toast.success("Goals Edited Successfully");
            }
            refetch();
            setModule(null);
          }}
        >
          <div className="fields-wrapper fields-wrapper__updated">
            <div>
              <label>Name</label>
              <input
                required
                name="name"
                className="field-control  field-control__updated"
                placeholder=""
                defaultValue={currentData ? currentData.name : ""}
              />
            </div>
          </div>
          <div className="fields-wrapper fields-wrapper__updated">
            <label>Details</label>
            <input
              name="details"
              className="field-control  field-control__updated"
              placeholder=""
              defaultValue={currentData ? currentData.details : ""}
            />
          </div>
          <div className="fields-wrapper fields-wrapper__updated">
            <label>Type</label>
            <Select
              name="type"
              className="acc-newselectwrapper"
              classNamePrefix="acc-newselect"
              options={[
                { value: "custom", label: "Custom" },
                { value: "pageview", label: "Page view" },
                { value: "click", label: "Click" },
                { value: "revenue", label: "Revenue" },
              ]}
              defaultValue={
                currentData
                  ? { value: currentData.type, label: currentData.type }
                  : { value: "custom", label: "Custom" }
              }
            />
          </div>
          <div className="fields-wrapper fields-wrapper__updated">
            <label>Counting Method</label>
            <Select
              name="counting_method"
              className="acc-newselectwrapper"
              classNamePrefix="acc-newselect"
              options={[
                { value: "one", label: "One" },
                { value: "every", label: "Every" },
              ]}
              defaultValue={
                currentData
                  ? {
                      value: currentData.counting_method,
                      label: currentData.counting_method,
                    }
                  : { value: "one", label: "One" }
              }
            />
          </div>
          <div className="fields-wrapper fields-wrapper__updated">
            <label>CSS Selector</label>
            <input
              name="css_selector"
              className="field-control  field-control__updated"
              placeholder=""
              defaultValue={currentData ? currentData.css_selector : ""}
            />
          </div>
          <div className="fields-wrapper fields-wrapper__updated">
            <label>Rules js</label>
            <textarea
              name="rules_js"
              className="field-control  field-control__updated"
              placeholder=""
              defaultValue={currentData ? currentData.rules_js : "return true;"}
            />
          </div>
          <div className="add__new__test__form-footer">
            <button className="btn next-btn" type="submit">
              {formType === "add" ? `Add ${module}` : `Edit ${module}`}
            </button>
          </div>
        </form>
      ) : (
        <>
          <ListHeader header={GOALS_HEADER} />
          <div>
            {data?.goalsPagination?.goals?.map((row, idx) => (
              <div
                key={`${row.name}-${idx}`}
                className="test-details-table-data"
              >
                <div className="test-name text-break">
                  <h3>{row?.name}</h3>
                </div>
                <div className="test-name text-break">
                  <h3>{row?.details}</h3>
                </div>
                {/* <div className="test-name text-break">
              <h3>{row?.type}</h3>
            </div>
            <div className="test-name text-break">
              <h3>{row?.counting_method}</h3>
            </div>
            <div className="test-name text-break">
              <h3>{row?.css_selector}</h3>
            </div> */}
                <div className="test-name text-break">
                  <h3>{row?.rules_js}</h3>
                </div>

                <div className="test-name text-break">
                  <FaEdit
                    onClick={() => {
                      setModule("Goals");
                      setCurrentData(row);
                      setFormType("edit");
                    }}
                    style={{ cursor: "pointer", color: "gray" }}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};
