import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CREATETEST, UPDATETEST } from "../../Graphql/Mutations";
import useStore from "../../Store";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { useCallback, useEffect, useState } from "react";
import { Calendar } from "react-date-range";
import { VscChromeClose, VscInfo } from "react-icons/vsc";
import {
  FORMPREFILLDATA,
  FORMPREFILLDATAV2,
  GETCLIENTHYPOTHESISSHEET,
  GOALSPAGINATION,
  SEQUENTIALVALUESFORTEST,
} from "../../Graphql/Queries";
import { toast } from "react-toastify";
import MyLoader from "./PreLoader";
import { UploadFile } from "../../Libs/Cloudinary";
import _ from "lodash";
import { BackIcon, CrossIcon, CrossIconModal } from "../Shared/Icons";
import UploadIcon from "./NewForm/UploadIcon";
import FormInstance from "./FormInstance";
import "./newForm.css";

export const CreateTestFormNew = ({ refetch, module, testObj, setModule }) => {
  const { toggleForm } = useStore();
  const [steps, setSteps] = useState(1);
  const showForm = useStore((state) => state.showForm);
  const [fullView, setFullView] = useState(false);
  const [createtest] = useMutation(CREATETEST);
  const [createHypothesis, setCreateHypothesis] = useState(false);
  const [updatetest] = useMutation(UPDATETEST);
  const [name, setName] = useState("");
  const [controlImage, setControlImage] = useState(null);
  const [variantImage, setVariantImage] = useState(null);
  const [client, setClient] = useState(null);
  const [stage, setStage] = useState(null);
  const [properties, setProperties] = useState(null);
  const [revenuetype, setRevenueType] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [hypothesisOptions, setHypothesisOptions] = useState([]);
  const [hypothesis, setHypothesis] = useState("");
  const [pageSelected, setPageSelected] = useState([]);
  const [goalSelected, setGoalSelected] = useState([]);
  const [triggerSelected, setTriggerSelected] = useState([]);
  const [tags, setTags] = useState([]);
  const [testTool, setTestTool] = useState(null);
  const [testType, setTestType] = useState(null);
  const [testStatus, setTestStatus] = useState("");
  const [liveDate, setLiveDate] = useState(new Date());
  const [triggerEvents, setTriggerEvents] = useState([]);
  const [kameleoonTestDetails, setKameleoonTestDetails] = useState([]);
  const [sequentialValues, setSequentialValues] = useState({});
  const [goals, setGoals] = useState([]);
  const [gethypothesisforclientquery] = useLazyQuery(GETCLIENTHYPOTHESISSHEET);
  const [getsequentialvaluesfortest] = useLazyQuery(SEQUENTIALVALUESFORTEST);
  const [goalspagination] = useLazyQuery(GOALSPAGINATION);
  const events = [
    { label: "Page View", value: "page_view" },
    { label: "User Interaction", value: "user_interaction" },
  ];

  const getSequentialValues = useCallback(async () => {
    if (module === "edit") {
      try {
        const {
          data: { getSequentialValuesForTest },
        } = await getsequentialvaluesfortest({
          variables: {
            test: testObj?._id,
          },
        });
        setSequentialValues(getSequentialValuesForTest);
      } catch (error) {
        toast.error(error.message);
      }
    }
  }, [testObj, module]);

  useEffect(() => {
    getSequentialValues();
  }, [getSequentialValues]);

  // STATES
  const { data, loading } = useQuery(FORMPREFILLDATAV2, {
    variables: {
      fullSet: false,
    },
  });
  // const KeyCodes = {
  //   comma: 188,
  //   enter: 13,
  // };
  // const delimiters = [KeyCodes?.comma, KeyCodes?.enter];
  const revenuestypes = ["Monthly", "Yearly"];
  const teststatuses = ["Live", "Draft", "Discard"];

  useEffect(() => {
    const platform = properties?.find(
      (p) => p?.viewId === selectedProperty?.value
    );
    setSelectedPlatform(platform?.platform ?? "GA4");
  }, [selectedProperty, properties]);

  const getClientHypothesisData = useCallback(async () => {
    if (client && selectedProperty) {
      const {
        data: { hypothesisSheetsByClient },
      } = await gethypothesisforclientquery({
        fetchPolicy: "cache-and-network",
        variables: {
          client: JSON.parse(client?.value)?._id,
          viewId: selectedProperty?.value,
        },
      });
      const { data: goalsData } = await goalspagination({
        fetchPolicy: "cache-and-network",
        variables: {
          filters: [
            {
              $or: [
                { client: JSON.parse(client?.value)?._id },
                {
                  client: {
                    $exists: false,
                  },
                },
              ],
            },
          ],
        },
      });
      setGoals(goalsData?.goalsPagination?.goals);
      setHypothesisOptions(
        hypothesisSheetsByClient?.filter((hs) => !hs?.tested)
      );
    }
  }, [client, selectedProperty]);

  useEffect(() => {
    if (showForm) {
      getClientHypothesisData();
    }
  }, [showForm, getClientHypothesisData]);

  useEffect(() => {
    if (module === "edit") {
      setFullView(true);
      const clientobj = JSON.stringify({
        _id: testObj?.client?._id,
        gaproperties: testObj?.client?.gaproperties,
      });
      setName(testObj?.name);
      setClient({
        label: testObj?.client?.name,
        value: clientobj,
      });
      const properties = data?.formPrefillData?.clients?.find(
        (c) => c?._id === testObj?.client?._id
      )?.gaproperties;
      setProperties(properties);
      setSelectedProperty(testObj?.property);
      setSelectedPlatform(testObj?.platform);
      const testtypeobj = testObj?.pagetesttype?.map((ptt) => {
        return {
          label: ptt?.name,
          value: ptt?._id,
        };
      });
      if (testObj?.stageincustomerjourney) {
        setStage({
          label: testObj?.stageincustomerjourney?.name,
          value: testObj?.stageincustomerjourney?._id,
        });
      }
      setTestType(testtypeobj ? testtypeobj[0] : null);
      setHypothesis({
        label: testObj?.hypothesis?.name,
        value: testObj?.hypothesis?._id,
      });
      const goalsobj = testObj?.goal?.map((goal) => {
        return {
          label: goal?.name,
          value: goal?._id,
        };
      });
      setGoalSelected(goalsobj);
      const pageelementobj = testObj?.pageelement?.map((page) => {
        return {
          label: page?.name,
          value: page?._id,
        };
      });
      setPageSelected(pageelementobj);
      setControlImage(testObj?.controlimage);
      setVariantImage(testObj?.variationimage);

      const tagsobj = testObj?.tags?.map((tag) => {
        return {
          label: tag?.name,
          value: tag?._id,
        };
      });
      setTags(tagsobj);
      const triggereventsobj = testObj?.triggerevents?.map((event) => {
        return {
          id: event,
          text: event,
        };
      });
      setTriggerEvents(triggereventsobj);
      const psychologicaltriggerobj = testObj?.trigger?.map((trigger) => {
        return {
          label: trigger?.name,
          value: trigger?._id,
        };
      });
      setSelectedEvent(events.find((e) => e?.value === testObj?.selectedevent));
      setTestTool(testObj?.testtool ?? null);
      setTriggerSelected(psychologicaltriggerobj);
      setRevenueType(testObj?.revenue[0]?.type);
      setTestStatus(testObj?.status);
      setLiveDate(testObj?.livedate ? new Date(testObj?.livedate) : new Date());
      setTestStatus(testObj?.status);
      setLiveDate(
        testObj?.status === "live" ? new Date(testObj?.livedate) : new Date()
      );
      if (testObj?.testtool === "Kameleoon") {
        const _kameleoondetails = testObj?.kameleoontestdetails;
        setKameleoonTestDetails(
          _kameleoondetails ? [..._kameleoondetails?.controlvariants] : []
        );
      }
      if (testObj?.testtool === "Vwo") {
        const _vwodetails = testObj?.vwotestdetails;
        setKameleoonTestDetails(
          _vwodetails ? [..._vwodetails?.controlvariants] : []
        );
      }
    }
  }, [data, testObj, module]);

  const resetHypothesisForm = () => {
    setCreateHypothesis(false);
  };

  const checkButtonStatus = (module, status, testTool) => {
    if (!testTool || testTool === "") {
      return true;
    }
    if (testTool === "codebase" && status === "live") {
      return true;
    }
    if ((module === "add" && status === "ended") || status === "discard") {
      return true;
    }
    return false;
  };

  const requiredformfields = [
    {
      field: "testid",
      required: false,
    },
    { field: "csscode", required: false },
    { field: "htmlcode", required: false },
    { field: "jscode", required: false },
    { field: "urltargeting", required: testTool === "Codebase" ? true : false },
    // { field: "pageaudience", required: false },
    {
      field: "recommendedsamplesize",
      required: testStatus === "live" ? true : false,
    },
    { field: "significance", required: false },
    // { field: "targetaudience", required: false },
    { field: "page", required: false },
    { field: "pageNotContains", required: false },
    { field: "eventparameter", required: false },
    { field: "defaultControl", required: false },
  ];
  let testPayload = {};

  const uploadTest = async (_) => {
    _.preventDefault();
    const form = _.currentTarget;
    const values = _.target;
    for await (let field of requiredformfields) {
      const value = form.querySelector(`[name=${field?.field}]`).value;
      if (field?.required && value.trim() === "") {
        setFullView(true);
        const fieldelement = document.querySelector(`[name=${field?.field}]`);
        fieldelement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
        fieldelement.style.border = "1px solid red";
        setTimeout(function () {
          fieldelement.setAttribute("style", "");
        }, 2000);
        toast.error(`${field?.field} is required`);
        return;
      } else {
        testPayload[field?.field] = value;
      }
    }
    if (
      !client ||
      !selectedProperty ||
      !testTool ||
      !testType ||
      !hypothesis ||
      !goalSelected ||
      goalSelected?.length === 0 ||
      !triggerSelected ||
      triggerSelected?.length === 0 ||
      !controlImage ||
      !variantImage
    ) {
      setFullView(true);
      toast.error(`Please add all required fields`);
      return;
    }
    const filterAddedVariants = kameleoonTestDetails?.filter(
      (item) => item?.name.trim() !== ""
    );
    if (
      testTool === "Kameleoon" &&
      selectedPlatform !== "GA4" &&
      filterAddedVariants?.length === 0
    ) {
      toast.error(`Please add correct Kameleeon test details`);
      return;
    }
    if (
      testTool === "Kameleoon" &&
      selectedPlatform !== "GA4" &&
      values.kameleoondimension.value.trim() === ""
    ) {
      toast.error(`Please add correct Kameleeon test details`);
      return;
    }
    if (
      testTool === "Vwo" &&
      selectedPlatform !== "GA4" &&
      values.vwoeventcategory.value.trim() === ""
      // ||
      //   filterAddedVariants?.length === 0
    ) {
      toast.error(`Please add all VWO test details`);
      return;
    }
    const clientvalue = JSON.parse(client?.value)?._id;
    testPayload["client"] = clientvalue;
    testPayload["name"] = name;
    testPayload["testtool"] = testTool;
    testPayload["controlimage"] = controlImage;
    testPayload["goal"] = goalSelected.map((_) => _?.value);
    testPayload["hypothesis"] = hypothesis?.value;
    if (testStatus === "live") {
      testPayload["livedate"] = liveDate.toString();
    }
    if (testTool === "Kameleoon") {
      testPayload["kameleoontestdetails"] = {
        dimension: values.kameleoondimension.value.trim(),
        controlvariants: filterAddedVariants,
      };
    }
    if (testTool === "Vwo") {
      testPayload["vwotestdetails"] = {
        vwoeventcategory: values.vwoeventcategory.value.trim(),
        controlvariants: filterAddedVariants,
      };
    }
    if (testTool?.toLowerCase() === "varify") {
      if (testPayload?.testid?.trim() === "") {
        toast.error("Test ID is required for Varify Tests");
        return;
      }
    }
    testPayload["pageelement"] = pageSelected.map((_) => _?.value);
    testPayload["pagetesttype"] = [testType?.value];
    testPayload["property"] = selectedProperty;
    testPayload["platform"] = selectedPlatform;
    testPayload["revenue"] = {
      type: revenuetype,
      value: form.querySelector(`[name='revenue']`).value,
    };
    testPayload["status"] = testStatus;
    testPayload["tags"] = tags.map((_) => _?.value);
    testPayload["trigger"] = triggerSelected.map((_) => _?.value);
    testPayload["stageincustomerjourney"] = stage ? stage.value : null;
    testPayload["selectedevent"] = selectedEvent ? selectedEvent.value : null;
    testPayload["triggerevents"] = triggerEvents.map((_) => _?.id);
    testPayload["urltargeting"] = testPayload?.urltargeting
      ? [testPayload?.urltargeting]
      : [];
    testPayload["variationimage"] = variantImage;
    if (module === "edit" && testTool === "Codebase") {
      delete testPayload["status"];
    }

    let sequentialValuesPayload = {
      alpha: Number(values?.alpha.value) ?? 0.05,
      beta: Number(values?.beta.value) ?? 0.2,
      deltaMin: Number(values?.deltaMin.value) ?? 0.01,
      nMax: Number(values?.nMax.value) ?? 10000,
    };
    if (module === "add") {
      try {
        const { data } = await createtest({
          variables: {
            testPayload: testPayload,
            sequentialValues: sequentialValuesPayload,
          },
        });
        toggleForm(!showForm);
        setModule(null);
        toast.success("Test Created Successfully");
        if (data?.createtest?.testtool === "Codebase") {
          window.location.href = `/ab-testing-dashboard/${data?.createtest?._id}`;
        }
      } catch (error) {
        toast.error(error?.message);
      }
    } else {
      try {
        if (!testObj?._id) {
          toast.error("Test ID not found, Please try again.");
          setModule(null);
          return;
        }
        await updatetest({
          variables: {
            id: testObj?._id,
            testPayload: testPayload,
            sequentialValues: sequentialValuesPayload,
          },
        });
        setModule(null);
        toast.success("Test Updated Successfully");
        toggleForm(!showForm);
      } catch (error) {
        toast.error(error?.message);
      }
    }
    refetch();
  };

  const setKameleoonDetails = (details, key, value) => {
    const singleObj = Object.assign(
      {},
      details?.find((itm) => itm?.index === key)
    );
    const filterDetails = details?.filter((itm) => itm.index !== key);
    singleObj.name = value;
    const newArray = [...filterDetails, singleObj].sort(
      (a, b) => a.index - b.index
    );
    setKameleoonTestDetails(newArray);
  };
  const addVariant = (obj) => {
    const length = obj.length;
    let index = 0;
    if (length > 0) {
      index = _.maxBy(obj, "index")?.index + 1;
    }
    const newArray = [
      ...obj,
      {
        index,
        name: "",
      },
    ];
    setKameleoonTestDetails(newArray);
  };

  const removeKameleoonVariant = (obj, index) => {
    const newArray = obj
      .filter((variants) => variants?.index !== index)
      .sort((a, b) => a.index - b.index);
    setKameleoonTestDetails(newArray);
  };
  const toolTypes = [
    "Codebase",
    "Kameleoon",
    "Vwo",
    "Optimizely",
    "A/B Tasty",
    "Varify",
  ];

  if (loading || (module === "edit" && !testObj))
    return (
      <div className="form-wrapper">
        <div className="form-wrapper-inner addtestform">
          <div className="card bg-dark">
            <MyLoader />
          </div>
        </div>
      </div>
    );
  return (
    <div className="form-wrapper" id="add-new-test-form">
      <div className="form-wrapper-inner addtestform">
        <div className="card add-new-test-card" style={{ minHeight: "455px" }}>
          <button
            className="close-modal"
            onClick={(_) => toggleForm(!showForm)}
          >
            <CrossIconModal />
          </button>
          <h3> Add Test</h3>
          <div className="card-body p-0">
            <div id="progress-bar-wrapper">
              <div className="progress-inner">
                <div className="progress-total"> </div>
                <div
                  className="progress-made"
                  style={
                    fullView
                      ? { maxWidth: "100%" }
                      : steps === 1
                      ? { maxWidth: "10%" }
                      : { maxWidth: `${steps * 25}%` }
                  }
                >
                  {fullView ? "100" : steps === 1 ? 0 : 25 * steps} %
                </div>
              </div>
            </div>
            <form onSubmit={(e) => uploadTest(e)}>
              <div
                className={`step step-${steps}`}
                style={
                  steps === 1 || fullView
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="fields-wrapper">
                  <label> Select Client *</label>
                  <Select
                    onChange={(client) => {
                      setClient(client);
                      setProperties(JSON.parse(client?.value)?.gaproperties);
                      setSelectedProperty(null);
                    }}
                    value={client}
                    className="acc-newselectwrapper"
                    classNamePrefix="acc-newselect"
                    placeholder={
                      module === "edit" ? `Change Client` : `Select Client`
                    }
                    // isDisabled={module === "edit" ? true : false}
                    options={data?.formPrefillData?.clients?.map((c, i) => {
                      const clientobj = JSON.stringify({
                        _id: c?._id,
                        gaproperties: c?.gaproperties,
                      });
                      return {
                        label: c?.name,
                        value: clientobj,
                      };
                    })}
                  />
                </div>
                <div className="fields-wrapper">
                  <label> Select Property *</label>
                  <Select
                    // isDisabled={!client || module === "edit" ? true : false}
                    onChange={(property) => {
                      const propertyObj = JSON.parse(property?.value);
                      setSelectedProperty({
                        label: property?.label,
                        value: propertyObj?.viewId,
                      });
                      // setSelectedPlatform(propertyObj?.platform);
                    }}
                    value={selectedProperty}
                    className="acc-newselectwrapper"
                    classNamePrefix="acc-newselect"
                    placeholder="Select Property"
                    options={properties?.map((ps) => {
                      return {
                        label: ps?.propertyname,
                        value: JSON.stringify({
                          viewId: ps?.viewId,
                          platform: ps?.platform ?? `GA4`,
                        }),
                      };
                    })}
                  />
                </div>
                <div className="fields-wrapper input-groups">
                  <div>
                    <label> Test Name *</label>
                    <input
                      placeholder="Test name"
                      className="field-control"
                      name="name"
                      onKeyUp={(e) => setName(e.currentTarget.value.trim())}
                      defaultValue={name ?? ``}
                    />
                  </div>

                  <div className="">
                    <label>
                      {" "}
                      Test ID <small> (required for Varify Tests) </small>
                    </label>
                    <input
                      placeholder="Test ID"
                      className="field-control"
                      name="testid"
                      defaultValue={testObj ? testObj?.testid : ``}
                    />
                  </div>
                </div>
                <div className="fields-wrapper">
                  <label> Target URL</label>
                  <input
                    placeholder="Target Url"
                    className="field-control"
                    name="urltargeting"
                    defaultValue={testObj ? testObj?.urltargeting?.[0] : ``}
                  />
                </div>

                <div className="fields-wrapper">
                  <label> Test Type *</label>
                  <div className="test-types">
                    {data?.formPrefillData?.pagetesttypes?.map((tt, i) => (
                      <div
                        className="form-check"
                        key={i}
                        onClick={(e) => {
                          setTestType({
                            label: tt?.name,
                            value: tt?._id,
                          });
                        }}
                      >
                        <input
                          type="radio"
                          // onClick={(e) => {
                          //   console.log(e.currentTarget.parentNode);
                          //   e.currentTarget.parentNode.click();
                          // }}
                          name="test-type"
                          readOnly
                          checked={tt?._id === testType?.value}
                          value={testType?.value}
                          className="form-check-input"
                        />
                        <label className="radio-labels">{tt?.name}</label>
                      </div>
                    ))}
                  </div>

                  {/* <Select
                      options={data?.pagetesttypes?.map((tt) => {
                        return {
                          label: tt?.name,
                          value: tt?._id,
                        };
                      })}
                      classNamePrefix="acc-select"
                      value={testType}
                      onChange={(testtype) => {
                        setTestType(testtype);
                      }}
                    /> */}
                </div>
                {!fullView && (
                  <div
                    className="add__new__test__form-footer"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div
                      className="btn next-btn"
                      onClick={(e) => {
                        if (
                          !client ||
                          !selectedProperty ||
                          !testType ||
                          name === ""
                        ) {
                          toast.error("Please add all required fields");
                          return;
                        }
                        setSteps((steps) => steps + 1);
                      }}
                    >
                      NEXT
                    </div>
                  </div>
                )}
              </div>

              <div
                className={`step step-${steps}`}
                style={
                  steps === 2 || fullView
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="fields-wrapper">
                  <label> Select Hypothesis *</label>
                  <Select
                    // options={data?.allHypothesis?.hypotheses?.map((hp) => {
                    //   return {
                    //     label: `${hp?.name} - ${hp?.action} - ${hp?.expectation} - ${hp?.result}`,
                    //     value: hp?._id,
                    //   };
                    // })}
                    options={hypothesisOptions?.map((hp) => ({
                      label: `${hp?.hypothesis?.name} - ${hp?.hypothesis?.action} - ${hp?.hypothesis?.result} - ${hp?.hypothesis?.expectation}`,
                      value: hp?.hypothesis?._id,
                    }))}
                    className="acc-newselectwrapper"
                    classNamePrefix="acc-newselect"
                    value={hypothesis}
                    onChange={(hypo) => {
                      setHypothesis(hypo);
                    }}
                  />
                </div>
                <div
                  style={{
                    textAlign: "right",
                    color: "#fff",
                  }}
                >
                  <span
                    onClick={(e) => setCreateHypothesis(true)}
                    style={{
                      textAlign: "right",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    Add Hypothesis
                  </span>
                </div>
                <div className="fields-wrapper">
                  <label> Select Goals *</label>
                  <Select
                    isMulti={true}
                    options={goals?.map((pe) => {
                      return {
                        label: pe?.name,
                        value: pe?._id,
                      };
                    })}
                    className="acc-newselectwrapper"
                    classNamePrefix="acc-newselect"
                    value={goalSelected}
                    onChange={(goal) => {
                      setGoalSelected(goal);
                    }}
                  />
                </div>
                <div className="fields-wrapper">
                  <label> Psychological Triggers *</label>
                  <Select
                    isMulti={true}
                    options={data?.formPrefillData?.triggers?.map((pe) => {
                      return {
                        label: pe?.name,
                        value: pe?._id,
                      };
                    })}
                    className="acc-newselectwrapper"
                    classNamePrefix="acc-newselect"
                    value={triggerSelected}
                    onChange={(trigger) => {
                      setTriggerSelected(trigger);
                    }}
                  />
                </div>
                <div className="control-variant-image-container">
                  <Dropzone
                    accept={{
                      "image/png": [".png", ".jpg", ".jpeg"],
                    }}
                    onDrop={async (acceptedFiles) => {
                      setControlImage(
                        "https://res.cloudinary.com/acceleratedagency/image/upload/f_auto/STATICIMAGES/Preloader_rlboab.gif"
                      );
                      const _fileupload = await UploadFile(acceptedFiles[0]);
                      if (_fileupload?.error) {
                        alert(_fileupload?.error?.message);
                        setControlImage(null);
                      } else {
                        setControlImage(_fileupload?.secure_url);
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className="control-image"
                        {...getRootProps()}
                        style={
                          controlImage
                            ? { backgroundImage: `url(${controlImage})` }
                            : {}
                        }
                      >
                        <input {...getInputProps()} />
                        <div className="upload-icon">
                          <UploadIcon />
                          {controlImage && (
                            <VscChromeClose
                              onClick={(_) => {
                                setControlImage(null);
                              }}
                            />
                          )}
                          <span>Add Control image...</span>
                        </div>
                      </div>
                    )}
                  </Dropzone>

                  <Dropzone
                    accept={{
                      "image/png": [".png", ".jpg", ".jpeg"],
                    }}
                    onDrop={async (acceptedFiles) => {
                      setVariantImage(
                        "https://res.cloudinary.com/acceleratedagency/image/upload/f_auto/STATICIMAGES/Preloader_rlboab.gif"
                      );
                      const _fileupload = await UploadFile(acceptedFiles[0]);
                      if (_fileupload?.error) {
                        alert(_fileupload?.error?.message);
                        setVariantImage(null);
                      } else {
                        setVariantImage(_fileupload?.secure_url);
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className="variant-image"
                        {...getRootProps()}
                        style={
                          controlImage
                            ? { backgroundImage: `url(${variantImage})` }
                            : {}
                        }
                      >
                        <input {...getInputProps()} />
                        <div className="upload-icon">
                          <UploadIcon />
                          {variantImage && (
                            <VscChromeClose
                              onClick={(_) => {
                                setVariantImage(null);
                              }}
                            />
                          )}
                          <span>Add Variant image...</span>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
                {!fullView && (
                  <div className="add__new__test__form-footer">
                    {steps > 0 && (
                      <div
                        className="btn-prev"
                        onClick={(e) => {
                          setSteps((steps) => steps - 1);
                        }}
                      >
                        <BackIcon />
                        Back
                      </div>
                    )}
                    <div
                      className="btn next-btn"
                      onClick={(e) => {
                        if (
                          !hypothesis ||
                          !goalSelected ||
                          goalSelected?.length === 0 ||
                          !triggerSelected ||
                          triggerSelected?.length === 0
                          // ||
                          // !controlImage ||
                          // !variantImage
                        ) {
                          toast.error("Please add all required fields");
                          return;
                        }
                        setSteps((steps) => steps + 1);
                      }}
                    >
                      NEXT
                    </div>
                  </div>
                )}
              </div>

              <div
                className={`step step-${steps}`}
                style={
                  steps === 3 || fullView
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="fields-wrapper">
                  <label> Select page object/element</label>
                  <Select
                    isMulti={true}
                    options={data?.formPrefillData?.pageelements?.map((pe) => {
                      return {
                        label: pe?.name,
                        value: pe?._id,
                      };
                    })}
                    className="acc-newselectwrapper"
                    classNamePrefix="acc-newselect"
                    value={pageSelected}
                    onChange={(pageelement) => {
                      setPageSelected(pageelement);
                    }}
                  />
                </div>
                <div className="fields-wrapper">
                  <label> Stage in Customer Journey</label>
                  <Select
                    // isDisabled={!client || module === "edit" ? true : false}
                    onChange={(stage) => {
                      setStage(stage);
                      setTags([]);
                    }}
                    value={stage}
                    className="acc-newselectwrapper"
                    classNamePrefix="acc-newselect"
                    placeholder="Stage in Customer Journey"
                    options={data?.formPrefillData?.stageInCustomerJourneys?.map(
                      (st, i) => {
                        return {
                          label: st?.name,
                          value: st?._id,
                        };
                      }
                    )}
                  />
                </div>
                <div className="fields-wrapper">
                  <label> Tags</label>
                  <Select
                    isMulti={true}
                    options={data?.formPrefillData?.tags?.map((tg) => {
                      return {
                        label: tg?.name,
                        value: tg?._id,
                      };
                    })}
                    className="acc-newselectwrapper"
                    classNamePrefix="acc-newselect"
                    value={tags}
                    onChange={(tag) => {
                      setTags(tag);
                    }}
                  />
                </div>

                <div className="fields-wrapper">
                  <label> Select Event</label>
                  <Select
                    // isDisabled={!client || module === "edit" ? true : false}
                    onChange={(event) => {
                      setSelectedEvent(event);
                    }}
                    value={selectedEvent}
                    className="acc-newselectwrapper"
                    classNamePrefix="acc-newselect"
                    placeholder="Select Event"
                    options={events.map((event, i) => {
                      return {
                        label: event?.label,
                        value: event?.value,
                      };
                    })}
                  />
                </div>

                {/* <div className="fields-wrapper">
                  <label> Add Page Audience</label>
                  <input
                    placeholder="Page audience"
                    className="field-control"
                    name="pageaudience"
                    defaultValue={testObj ? testObj?.pageaudience : ``}
                  />
                </div> */}
                {/* <div className="fields-wrapper">
                  <label> Target Audience</label>
                  <input
                    placeholder="Target audience"
                    className="field-control"
                    name="targetaudience"
                    defaultValue={testObj ? testObj?.targetaudience : ``}
                  />
                </div> */}
                <div className="fields-wrapper input-groups">
                  <div>
                    <label> Page</label>
                    <input
                      placeholder="Page"
                      className="field-control"
                      name="page"
                      defaultValue={testObj ? testObj?.page : ``}
                    />
                  </div>
                  <div>
                    <label> Page regex not contains</label>
                    <input
                      placeholder="Page regex not contains"
                      className="field-control"
                      name="pageNotContains"
                      defaultValue={testObj ? testObj?.pageNotContains : ``}
                    />
                  </div>
                </div>

                <div className="fields-wrapper">
                  <label> Event Parameter</label>
                  <input
                    placeholder="Event Parameter"
                    className="field-control"
                    name="eventparameter"
                    defaultValue={testObj ? testObj?.eventparameter : ``}
                  />
                </div>

                {/* <div className="fields-wrapper">
          <label> Trigger Events</label>
          <ReactTags
            classNames={{
              tagInputField: "field-control",
            }}
            tags={triggerEvents}
            delimiters={delimiters}
            handleDelete={(i) =>
              setTriggerEvents(
                triggerEvents.filter((target, index) => index !== i)
              )
            }
            handleAddition={(triggerEvent) =>
              setTriggerEvents([...triggerEvents, triggerEvent])
            }
            inputFieldPosition="top"
            placeholder="Add trigger events and press enter"
          />
        </div> */}

                <div className="fields-wrapper">
                  <label> HTML Code</label>
                  <textarea
                    className="field-control"
                    placeholder="Add HTML code"
                    defaultValue={testObj ? testObj?.htmlcode : ``}
                    name="htmlcode"
                  ></textarea>
                </div>

                <div className="fields-wrapper">
                  <label> CSS Code</label>
                  <textarea
                    className="field-control"
                    placeholder="Add CSS code"
                    defaultValue={testObj ? testObj?.csscode : ``}
                    name="csscode"
                  ></textarea>
                </div>
                <div className="fields-wrapper">
                  <label> JS Code</label>
                  <textarea
                    className="field-control"
                    placeholder="Add JS code"
                    name="jscode"
                    defaultValue={testObj ? testObj?.jscode : ``}
                  ></textarea>
                </div>
                <div className="fields-wrapper">
                  <label> Significance</label>
                  <input
                    placeholder="Significance"
                    className="field-control"
                    name="significance"
                    defaultValue={testObj ? testObj?.significance : ``}
                  />
                </div>
                <div className="fields-wrapper">
                  <label> Add Revenue Type</label>
                  <div className="test-types">
                    {revenuestypes?.map((rt, i) => (
                      <div
                        className="form-check"
                        key={i}
                        onClick={(_) => {
                          _.preventDefault();
                          setRevenueType(rt.toLowerCase());
                        }}
                      >
                        <input
                          type="radio"
                          name="test-type"
                          value={rt}
                          className="form-check-input"
                        />
                        <label className="radio-labels">{rt}</label>
                      </div>
                    ))}
                  </div>
                  {/* <div className="check-box-buttons-wrapper">
                        {revenuestypes.map((rt, i) => (
                          <button
                            type="button"
                            className={
                              rt.toLowerCase() === revenuetype ? "active" : ""
                            }
                            key={i}
                            onClick={(_) => setRevenueType(rt.toLowerCase())}
                          >
                            {rt}
                          </button>
                        ))}
                      </div> */}
                </div>
                <div className="fields-wrapper">
                  <label> Revenue Value</label>
                  <input
                    className="mb-0 field-control"
                    placeholder="Revenue Value"
                    name="revenue"
                    defaultValue={
                      testObj && revenuetype ? testObj?.revenue[0]?.value : ``
                    }
                  />
                </div>
                {!fullView && (
                  <div className="add__new__test__form-footer">
                    {steps > 0 && (
                      <div
                        className="btn-prev"
                        onClick={(e) => {
                          setSteps((steps) => steps - 1);
                        }}
                      >
                        <BackIcon />
                        Back
                      </div>
                    )}
                    <div
                      className="btn next-btn"
                      onClick={(e) => {
                        setSteps((steps) => steps + 1);
                      }}
                    >
                      NEXT
                    </div>
                  </div>
                )}
              </div>

              <div
                className={`step step-${steps}`}
                style={
                  steps === 4 || fullView
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="fields-wrapper">
                  <label> Test Tool *</label>
                  <div className="test-tool">
                    {toolTypes.map((btn, i) => (
                      <div
                        className="form-check"
                        key={i}
                        onClick={(e) => {
                          setTestTool(btn);
                          if (btn === "Codebase") {
                            setTestStatus("draft");
                          }
                        }}
                      >
                        <input
                          type="radio"
                          name="test-tool"
                          value={btn}
                          readOnly
                          checked={testTool === btn}
                          className="form-check-input"
                        />
                        <label className="radio-labels">{btn}</label>
                      </div>
                    ))}
                  </div>
                  {testTool === "Kameleoon" && (
                    <>
                      <h5 className="border-top-0"> Kameleoon Tests Details</h5>
                      <div className="fields-wrapper">
                        <label> Dimension Number</label>
                        <input
                          className="field-control"
                          type="number"
                          defaultValue={
                            testObj?.kameleoontestdetails?.dimension
                          }
                          name="kameleoondimension"
                          placeholder="example 2,3,4..."
                        />
                      </div>

                      {kameleoonTestDetails?.map((variants, i) => (
                        <div key={i} className="kameleoon-fields">
                          <div className="fields-wrapper">
                            <label>
                              {" "}
                              {variants?.index === 0
                                ? `Control Name`
                                : `Variation ${variants?.index} Name`}{" "}
                            </label>
                            <input
                              className="field-control"
                              defaultValue={variants?.name}
                              onBlur={(e) =>
                                setKameleoonDetails(
                                  kameleoonTestDetails,
                                  variants?.index,
                                  e.currentTarget.value
                                )
                              }
                              placeholder={`ACC_CLIENTNAME_TESTTYPE_TESTNUMBER | MAB/Control/Variant`}
                            />
                          </div>
                          {variants?.index !== 0 && (
                            <button
                              className="variants-info-button"
                              type="button"
                              onClick={(e) =>
                                removeKameleoonVariant(
                                  kameleoonTestDetails,
                                  variants?.index
                                )
                              }
                            >
                              <CrossIcon />
                            </button>
                          )}
                        </div>
                      ))}
                      <div className="addcontrolvariantkameleoon">
                        <span onClick={(e) => addVariant(kameleoonTestDetails)}>
                          Add Control/Variants
                        </span>
                      </div>
                    </>
                  )}

                  {testTool === "Vwo" && (
                    <>
                      <h5 className="border-top-0"> VWO Tests Details</h5>
                      <div className="fields-wrapper">
                        <label> Event Category</label>
                        <input
                          className="field-control"
                          type="text"
                          defaultValue={
                            testObj?.vwotestdetails?.vwoeventcategory
                          }
                          name="vwoeventcategory"
                          placeholder="VWO"
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="fields-wrapper">
                  <label>
                    {" "}
                    Default Control (if not "reference" or "control"){" "}
                    <span
                      data-tip={`https://res.cloudinary.com/acceleratedagency/video/upload/VIDEOS/howtoaddcustomcontrolname_gciv0b.webm`}
                      data-label={`watch Video for How to add custom control name?`}
                      className="toottip-link custom-hover"
                      onClick={(e) => {
                        window.open(
                          e.currentTarget.getAttribute("data-tip"),
                          "_blank"
                        );
                      }}
                    >
                      <VscInfo />
                    </span>
                  </label>
                  <input
                    placeholder="Variation name"
                    className="field-control"
                    name="defaultControl"
                    defaultValue={testObj?.defaultControl ?? ``}
                  />
                </div>
                <div className="fields-wrapper">
                  <label> Recommended Sample Size</label>
                  <input
                    className="field-control"
                    placeholder="Recommended sample size"
                    name="recommendedsamplesize"
                    defaultValue={testObj ? testObj?.recommendedsamplesize : ``}
                  />
                </div>
                <div style={{ color: "#fff" }}>SEQUENTIAL AB TESTS VALUES</div>
                <br />
                <div className="sequential-tests-values border p-2">
                  <div className="fields-wrapper input-groups">
                    <div>
                      <label> Confidence</label>
                      <input
                        placeholder="Confidence Value"
                        className="field-control"
                        name="alpha"
                        defaultValue={sequentialValues?.alpha ?? ``}
                      />
                    </div>

                    <div className="">
                      <label>Power</label>
                      <input
                        placeholder="Power Value"
                        className="field-control"
                        name="beta"
                        defaultValue={sequentialValues?.beta ?? ``}
                      />
                    </div>
                  </div>

                  <div className="fields-wrapper input-groups">
                    <div>
                      <label>Min. Detectable effect</label>
                      <input
                        placeholder="Min. Detectable effect"
                        className="field-control"
                        name="deltaMin"
                        defaultValue={sequentialValues?.deltaMin ?? ``}
                      />
                    </div>

                    <div className="">
                      <label>Max. Sample size</label>
                      <input
                        placeholder="Maximum sample size"
                        className="field-control"
                        name="nMax"
                        defaultValue={sequentialValues?.nMax ?? ``}
                      />
                    </div>
                  </div>
                </div>
                {(testStatus !== "ended" ||
                  (module === "edit" &&
                    testTool?.toLowerCase() === "codebase")) && (
                  <div className="fields-wrapper test-status">
                    <label> Test Status</label>
                    <div className="btn-groups">
                      {teststatuses.map((ts, i) => (
                        <button
                          key={i}
                          type="button"
                          disabled={checkButtonStatus(
                            module,
                            ts.toLowerCase(),
                            testTool?.toLowerCase()
                          )}
                          className={`btn ${
                            ts.toLowerCase() === testStatus
                              ? "test-status-active"
                              : ""
                          }
                                `}
                          onClick={(_) => setTestStatus(ts.toLowerCase())}
                        >
                          {ts}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
                {testStatus === "live" && (
                  <div className="fields-wrapper">
                    <label className="mb-2"> Start Date of the Test</label>
                    <div className="check-box-buttons-wrapper text-center pb-3 dark-calendar">
                      <Calendar
                        date={liveDate}
                        onChange={(_) => setLiveDate(_)}
                      />
                    </div>
                  </div>
                )}
              </div>

              {(steps === 4 || fullView) && (
                <div
                  className="add__new__test__form-footer"
                  style={fullView ? { justifyContent: "flex-end" } : {}}
                >
                  {steps > 0 && !fullView && (
                    <div
                      className="btn-prev"
                      onClick={(e) => {
                        setSteps((steps) => steps - 1);
                      }}
                    >
                      <BackIcon />
                      Back
                    </div>
                  )}
                  <button className="btn next-btn">
                    {module === "edit" ? `Update Test` : `Add Test`}
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      {createHypothesis && (
        <FormInstance
          title="Add Hypothesis"
          type="addhypothesis"
          gethyposrefetch={getClientHypothesisData}
          reset={resetHypothesisForm}
          closeForm={resetHypothesisForm}
          client={JSON.parse(client?.value)?._id}
          viewId={selectedProperty?.value}
          tagged="Client Specific"
        />
      )}
    </div>
  );
};
