import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useMemo } from "react";

import { Bar } from "react-chartjs-2";

export const RevenuePerMonthGraph = ({ labels, data }) => {
  // const randomColors = useMemo(() => {
  //   let colors = [];
  //   for (let i = 0; i < 24; i++) {
  //     colors.push(`#${Math.floor(Math.random() * 0xffffff).toString(16)}`);
  //   }
  //   return colors;
  // });
  const randomColors = [
    "#256561",
    "#d051e4",
    "#13f459",
    "#dd2853",
    "#ecbbe3",
    "#fb4f30",
    "#44c9ea",
    "#85c12b",
    "#d42401",
    "#703dab",
    "#3c3618",
    "#8f1a40",
    "#86fb3d",
    "#2b433e",
    "#3f2374",
    "#48df1e",
    "#b34152",
    "#dc9bf1",
    "#544f2e",
    "#f88215",
    "#210c28",
    "#e39411",
    "#cdd5d7",
    "#9771ba",
  ];
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  const options = {
    indexAxis: "x",
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Average Revenue Per Month",
      },
      datalabels: {
        color: "#fff",
        font: {
          weight: "600",
        },
        formatter: function (value, context) {
          return value.toLocaleString();
        },
      },
    },
  };
  const dataset = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: randomColors,
        barPercentage: 0.8,
        borderRadius: 5,
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Bar options={options} data={dataset} />
    </>
  );
};
