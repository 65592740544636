import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { GETCLIENTNOTES, GETCLIENTSNAMEANDIDS } from "../../../Graphql/Queries";
import { returnClientOptions } from "../../../Libs/Utilities";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ClientHypothesisSheet } from "../../Shared/ClientHypothesisSheet";
import { IdeasDataSheet } from "../../Shared/IdeasDataSheet";
import MyLoader from "../../MiroComponents/PreLoader";

export const Testideas = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const client = searchParams.get("client");
  const property = searchParams.get("property");
  const module = searchParams.get("module");
  const [myProfile, is_client, is_admin] = useOutletContext();
  const [clientSelectorOptions, setClientSelectorOptions] = useState(null);
  const [selectedClient, setClient] = useState(null);
  const [clientProperties, setClientProperties] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [getActiveClients] = useLazyQuery(GETCLIENTSNAMEANDIDS);

  const [getClientNotes] = useLazyQuery(GETCLIENTNOTES);

  const setAllClientsOptions = async () => {
    try {
      const { data, loading } = await getActiveClients({
        variables: {
          clientFilters: [{ active: true }],
        },
      });
      if (!loading && data) {
        setClientSelectorOptions(returnClientOptions(data?.clients?.clients));
      }
    } catch (error) {
      toast.error("Error fetching clients");
    }
  };

  const setLimitedClients = (clients) => {
    setClientSelectorOptions(returnClientOptions(clients));
  };
  useEffect(() => {
    if (is_client) {
      setLimitedClients(myProfile?.client);
    } else {
      setAllClientsOptions();
    }
  }, [is_client, myProfile]);

  useEffect(() => {
    if (clientSelectorOptions && client) {
      const _client = clientSelectorOptions.find(
        (c) => JSON.parse(c.value)?._id === client
      );
      setClient(_client);
      const properties = JSON.parse(_client?.value)?.gaproperties;
      setClientProperties(properties);
    }
  }, [clientSelectorOptions, client]);

  useEffect(() => {
    if (clientProperties && property) {
      const _property = clientProperties.find((p) => p.viewId === property);
      setSelectedProperty({
        label: _property?.propertyname,
        value: _property?.viewId,
      });
    }
  }, [clientProperties, property]);

  const ClientSelector = ({ options }) => {
    return (
      <div className="fields-wrapper">
        <Select
          onChange={(client) => {
            setSearchParams({ client: JSON.parse(client?.value)?._id });
            setSelectedProperty(null);
          }}
          value={selectedClient}
          // isMulti={true}
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder="Name"
          options={options}
        />
      </div>
    );
  };

  const PropertySelector = ({ clientProperties, client }) => {
    return (
      <div className="fields-wrapper">
        <Select
          onChange={(propertyvalue) => {
            const _params = Object.fromEntries([...searchParams]);
            _params.property = propertyvalue?.value;
            _params.module = "hypothesis";
            setSearchParams(_params);
          }}
          isDisabled={!client ? true : false}
          value={selectedProperty}
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder="Choose One"
          options={clientProperties?.map((c) => {
            return {
              label: c?.propertyname,
              value: c?.viewId,
            };
          })}
        />
      </div>
    );
  };

  const getClientDetail = useCallback(
    (key) => {
      const _client = clientSelectorOptions
        ?.reduce((acc, curr) => {
          return [...acc, { ...JSON.parse(curr.value), name: curr?.label }];
        }, [])
        .find((c) => c._id === client);
      return _client[key] ?? "";
    },
    [clientSelectorOptions, client]
  );

  if (!clientSelectorOptions) return <MyLoader />;
  return (
    <div>
      <section className="live-reports">
        <div className="live-reports-inner">
          <div className="report-head-main">
            <h1 className="section-heading-title">Test Ideas</h1>
          </div>
          <div className="client-data-wrapper">
            <div className="client-details">
              <div className="client-dropdown">
                <h1 className="section-heading">Store:</h1>
                <ClientSelector options={clientSelectorOptions} />
              </div>
              <div className="properties-dropdown">
                <h1 className="section-heading">Properties:</h1>
                <PropertySelector
                  clientProperties={clientProperties}
                  client={client}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {client && property && (
        <>
          <div className="audit-client-wrapper">
            <div className="audit-client-img">
              <img src={getClientDetail("logo")} alt="" />
            </div>
            <div className="audit-client-name">
              <h3 className="section-heading">{getClientDetail("name")}</h3>
            </div>
          </div>
        </>
      )}
      <IdeasDataSheet client={client} viewId={property} />
    </div>
  );
};
