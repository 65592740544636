import React from "react";
import { useQuery, useMutation } from "@apollo/client";
import { ListHeader } from "./ListHeader";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { GETAUDIENCES } from "../../../Graphql/Queries";
import { CREATEAUDIENCE, UPDATEAUDIENCE } from "../../../Graphql/Mutations";
import { LoaderLogo } from "../../Shared/LoaderLogo";

const AUDIENCE_HEADER = ["Name", "Rules js", "Edit/Delete"];
export const AudienceTargeting = ({
  type,
  module,
  formType,
  currentData,
  client,
  setModule,
  setFormType,
  setCurrentData,
}) => {
  const [createAudience] = useMutation(CREATEAUDIENCE);
  const [updateAudience] = useMutation(UPDATEAUDIENCE);

  const { data, loading, error, refetch } = useQuery(GETAUDIENCES, {
    variables: {
      filters: [
        {
          client: client,
        },
      ],
    },
  });

  if (loading) return <LoaderLogo />;
  if (error) return <div>Error: {error.message}</div>;
  return (
    <>
      {type === "form" ? (
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const { Name, rules_js } = e.target;
            const audiencePayload = {
              client: client,
              name: Name.value,
              rules_js: rules_js.value,
            };
            if (formType === "add") {
              await createAudience({
                variables: {
                  audiencePayload: audiencePayload,
                },
              });
              toast.success("Audience Added Successfully");
            } else {
              await updateAudience({
                variables: {
                  id: currentData?._id,
                  audiencePayload: audiencePayload,
                },
              });
              toast.success("Audience Edited Successfully");
            }
            refetch();
            setModule(null);
          }}
        >
          <div className="fields-wrapper fields-wrapper__updated">
            <div>
              <label>Name</label>
              <input
                required
                name="Name"
                className="field-control  field-control__updated"
                placeholder="Name"
                defaultValue={currentData ? currentData.name : ""}
              />
            </div>
          </div>
          <div className="fields-wrapper fields-wrapper__updated">
            <label>Rules js</label>
            <textarea
              name="rules_js"
              className="field-control  field-control__updated"
              placeholder="Rules js"
              defaultValue={currentData ? currentData.rules_js : "return true;"}
            />
          </div>
          <div className="add__new__test__form-footer">
            <button className="btn next-btn" type="submit">
              {formType === "add" ? `Add ${module}` : `Edit ${module}`}
            </button>
          </div>
        </form>
      ) : (
        <>
          <ListHeader header={AUDIENCE_HEADER} />
          <div>
            {data?.getAudiences?.audiences?.map((row, idx) => (
              <div
                key={`${row.name}-${idx}`}
                className="test-details-table-data"
              >
                <div className="test-name text-break">
                  <h3>{row?.name}</h3>
                </div>
                <div className="test-name text-break">
                  <h3>{row?.rules_js}</h3>
                </div>
                <div className="test-name text-break">
                  <FaEdit
                    onClick={() => {
                      setFormType("edit");
                      setModule("Audience Targeting");
                      setCurrentData(row);
                    }}
                    style={{ cursor: "pointer", color: "gray" }}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};
