import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { VscChromeClose } from "react-icons/vsc";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { LOGIN } from "../../Graphql/Mutations";
import { GETCLIENT, MYPROFILE } from "../../Graphql/Queries";
import { ValidateEmail } from "../../Libs/Utilities";
import { EyeInvisible, EyeVisible } from "../Shared/Icons";

import { LoaderLogo } from "../Shared/LoaderLogo";

function Login() {
  const [clientDetail, setClientDetail] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [fieldsValidated, setFieldsValidated] = useState({
    email: false,
    password: false,
  });
  const [login, { reset }] = useMutation(LOGIN);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get("clientId");
  const [client] = useLazyQuery(GETCLIENT, {
    variables: {
      id: clientId,
    },
  });
  useEffect(() => {
    (async () => {
      if (clientId) {
        const { data } = await client();
        setClientDetail(data?.client);
      }
    })();
  }, [clientId]);

  const fieldsValidate = (field, value) => {
    setFieldsValidated({
      ...fieldsValidated,
      [field]: value,
    });
  };

  const { data, loading, error } = useQuery(MYPROFILE);

  const validateFields = useCallback(
    (value) => {
      const name = value.name;
      const fieldvalue = value.value;
      if (name === "email") {
        if (ValidateEmail(String(fieldvalue).toLowerCase().trim())) {
          value.classList.add("valid");
          fieldsValidate("email", true);
        } else {
          value.classList.remove("valid");
          fieldsValidate("email", false);
        }
      }
      if (name === "password") {
        if (String(fieldvalue).length > 5) {
          value.classList.add("valid");
          fieldsValidate("password", true);
        } else {
          value.classList.remove("valid");
          fieldsValidate("password", false);
        }
      }
      return true;
    },
    [fieldsValidated]
  );

  const promtError = (message) => {
    var errorDiv = document.querySelector("#error-messages");
    errorDiv.innerHTML = message;
    errorDiv.style.opacity = 1;
    // setTimeout(function () {
    //   errorDiv.style.opacity = 0;
    // }, 3000);
  };

  const loginAttempt = async (_) => {
    const email = _?.target.email.value;
    const password = _?.target.password.value;
    if (!ValidateEmail(String(email).toLowerCase().trim())) {
      // toast.error("Email address is invalid.");
      promtError("Email address is invalid.");
      return;
    }
    if (password.trim() === "") {
      promtError("Password is required.");
      return;
    }
    try {
      const _login = await login({
        variables: {
          email,
          password,
        },
      });
      if (_login?.data) {
        const { role } = _login?.data?.loginUser;
        if (!role || role.length === 0) {
          promtError(
            "You are no longer allowed to login. Please contact Admin!"
          );
          return;
        } else {
          const roles = _login?.data?.loginUser?.role;
          if (
            process.env.REACT_APP_CLIENT_APP_URL &&
            roles.length === 1 &&
            roles[0]?.name === "CLIENT"
          ) {
            // window
            //   .open(
            //     `${process.env.REACT_APP_CLIENT_APP_URL}/login/authenticate/${_login?.data?.loginUser?.token}`,
            //     "_blank"
            //   )
            //   .focus();
            localStorage.setItem(
              "codebase_token",
              _login?.data?.loginUser?.token
            );
            navigate("/authorized");
          } else {
            localStorage.setItem(
              "codebase_token",
              _login?.data?.loginUser?.token
            );
            navigate("/authorized");
          }
        }
      }
    } catch (error) {
      promtError(error?.message);
      reset();
    }
  };

  useEffect(() => {
    if (data?.myProfile) {
      navigate("/authorized");
    }
  }, [data]);

  if (loading) return <LoaderLogo />;
  return (
    <div className="login-wrapper">
      <div className="login-inner">
        <div className="login-card">
          <div className="login-header">
            <div className="logo-wrapper">
              <img
                src={`${window.location.origin}/Images/AcceleratedLOGOICON.png`}
                alt=""
              />
              {clientDetail && clientDetail.logo !== "" && (
                <>
                  <span style={{ color: "#000" }}>
                    <VscChromeClose />
                  </span>
                  <img src={clientDetail?.logo} alt="" />
                </>
              )}
            </div>
            {clientDetail && (
              <h4 className="text-center mb-3"> LOGIN TO YOUR ACCOUNT</h4>
            )}
          </div>
          <form
            className=""
            onSubmit={(_) => {
              _.preventDefault();
              loginAttempt(_);
            }}
          >
            <div className="fields-wrapper">
              <input
                type="text"
                autoComplete="off"
                className="field-control"
                onChange={(e) => validateFields(e.currentTarget)}
                placeholder="Email"
                name="email"
              />
            </div>
            <div className="fields-wrapper-group">
              <div className="border-bottom-custom">
                <input
                  type={!passwordVisible ? `password` : `text`}
                  autoComplete="off"
                  className="field-control"
                  onChange={(e) => validateFields(e.currentTarget)}
                  placeholder="Password"
                  name="password"
                  onFocus={(e) => {
                    e.currentTarget.parentNode.classList.add("focus");
                  }}
                  onBlur={(e) => {
                    e.currentTarget.parentNode.classList.remove("focus");
                  }}
                />
                <button
                  type="button"
                  onClick={(_) => setPasswordVisible(!passwordVisible)}
                >
                  {!passwordVisible ? <EyeInvisible /> : <EyeVisible />}
                </button>
              </div>
              <div className="forgot-password">
                <Link to="/reset-password"> Forgot Password ?</Link>
              </div>
            </div>
            <div className="fields-wrapper submit-cta">
              <button
                className=""
                type="submit"
                disabled={
                  fieldsValidated?.email && fieldsValidated?.password
                    ? false
                    : true
                }
              >
                Log In
              </button>
            </div>
          </form>
          <button id="error-messages"> Error message space</button>
        </div>
      </div>
    </div>
  );
}

export default Login;
