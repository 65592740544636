import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import Select from "react-select";
import {
  CLIENTTESTSREPORTS,
  GETCLIENTSNAMEANDIDS,
} from "../../../Graphql/Queries";
import MyLoader from "../../MiroComponents/PreLoader";
import { ClientStats } from "../../NewComponents/ClientStats";
import { ClientStrategyandRoadmap } from "../../NewComponents/ClientStrategyandRoadmap";
import { ClientTestCardSection } from "../../NewComponents/ClientTestCardsSection";
import { ClientTestsOverview } from "../../NewComponents/ClientTestsOverview";
import { ClientTopLearningsandNextstep } from "../../NewComponents/ClientTopLearningsandNextstep";
import { LiveReportsIcon } from "../../Shared/Icons";

export const ClientReportsAdmin = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const client = searchParams.get("client");
  const viewId = searchParams.get("viewId");
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const [clientSelectorOptions, setClientSelectorOptions] = useState(null);
  const [clientStatsReports, setClientStatsReports] = useState({
    show: false,
  });
  const [statusFilter, setStatusFilter] = useState("ended");
  const [getActiveClients] = useLazyQuery(GETCLIENTSNAMEANDIDS);
  const [clientTestReports] = useLazyQuery(CLIENTTESTSREPORTS);

  const returnClientOptions = (clients) => {
    return clients?.map((c) => {
      return {
        label: c?.name,
        value: JSON.stringify({
          _id: c?._id,
          gaproperties: c?.gaproperties,
          currency: c?.currency,
          logo: c?.logo,
        }),
      };
    });
  };

  const setLimitedClients = (clients) => {
    setClientSelectorOptions(returnClientOptions(clients));
    if (returnClientOptions(clients)?.length === 1) {
      const firstAvailableClient = JSON.parse(
        returnClientOptions(clients)[0]?.value
      );
      const _params = Object.fromEntries([...searchParams]);
      _params.client = firstAvailableClient?._id;
      if (firstAvailableClient?.gaproperties?.length === 1) {
        _params.viewId = firstAvailableClient?.gaproperties[0]?.viewId;
      }
      setSearchParams(_params);
      setClientStatsReports({
        ...clientStatsReports,
        show: true,
      });
    }
  };

  const setAllClientsOptions = async () => {
    const { data, loading } = await getActiveClients({
      variables: {
        clientFilters: [{ active: true }],
      },
    });
    if (!loading && data) {
      setClientSelectorOptions(returnClientOptions(data?.clients?.clients));
    }
  };

  useEffect(() => {
    if (is_client) {
      setLimitedClients(myProfile?.client);
    } else {
      setAllClientsOptions();
    }
  }, [is_client, myProfile]);

  useEffect(() => {
    if (client && viewId && statusFilter === "ended") {
      setClientStatsReports({
        ...clientStatsReports,
        loading: true,
        show: true,
      });
      (async () => {
        const { data, loading, error } = await clientTestReports({
          variables: {
            viewId,
          },
        });
        setClientStatsReports({
          clientTestReports: data?.clientTestReports,
          loading,
          error,
          show: !loading,
        });
      })();
    }
  }, [client, viewId, statusFilter, clientTestReports, setClientStatsReports]);

  const ClientSelector = ({ options }) => {
    return (
      <div className="fields-wrapper">
        <Select
          onChange={(client) => {
            const _params = Object.fromEntries([...searchParams]);
            _params.client = JSON.parse(client?.value)?._id;
            delete _params.viewId;
            setSearchParams(_params);
            setClientStatsReports({
              ...clientStatsReports,
              show: false,
            });
            setStatusFilter("ended");
          }}
          value={
            client
              ? options?.find((opt) => JSON.parse(opt?.value)?._id === client)
              : null
          }
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder="Select Store"
          options={options}
          // menuIsOpen={true}
        />
      </div>
    );
  };

  const PropertySelector = ({ client, options }) => {
    const propertyOptions = client
      ? JSON.parse(
          options?.find((opt) => {
            return JSON.parse(opt?.value)?._id === client;
          })?.value
        )?.gaproperties?.map((c) => {
          return {
            label: c?.propertyname,
            value: c?.viewId,
          };
        })
      : [];
    return (
      <div className="fields-wrapper">
        <Select
          onChange={(propertyvalue) => {
            const _params = Object.fromEntries([...searchParams]);
            _params.viewId = propertyvalue?.value;
            setSearchParams(_params);
            setClientStatsReports({
              ...clientStatsReports,
              show: true,
            });
          }}
          isDisabled={!client ? true : false}
          value={
            viewId ? propertyOptions.find((opt) => opt?.value === viewId) : null
          }
          className="acc-newselectwrapper"
          classNamePrefix="acc-newselect"
          placeholder="Choose One"
          options={client && propertyOptions?.length > 0 ? propertyOptions : []}
        />
      </div>
    );
  };

  if (!clientSelectorOptions) return <MyLoader />;
  return (
    <>
      <section className="live-reports">
        <div className="live-reports-inner">
          <div className="report-head-main">
            <span className="live-icon">
              <LiveReportsIcon />
            </span>
            <h1 className="section-heading-title">Client Reports</h1>
          </div>
          <div className="client-data-wrapper">
            <div className="client-details">
              <div className="client-dropdown">
                <h1 className="section-heading">Store:</h1>
                <ClientSelector options={clientSelectorOptions} />
              </div>
              <div className="properties-dropdown">
                <h1 className="section-heading">Properties:</h1>
                <PropertySelector
                  options={clientSelectorOptions}
                  client={client}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <>
        {clientStatsReports?.show && (
          <ClientStats client={client} viewId={viewId} />
        )}
        {clientStatsReports?.show && (
          <ClientStrategyandRoadmap
            clientId={client}
            selectedProperty={viewId}
            showSection={clientStatsReports?.show}
          />
        )}
        {clientStatsReports?.show && (
          <ClientTopLearningsandNextstep
            clientId={client}
            selectedProperty={viewId}
            showSection={clientStatsReports?.show}
          />
        )}

        {clientStatsReports?.show && (
          <ClientTestsOverview
            statistics={clientStatsReports?.clientTestReports?.statistics}
            loading={clientStatsReports?.loading}
            show={clientStatsReports?.show}
          />
        )}

        <ClientTestCardSection client={client} viewId={viewId} />
      </>
      {/* {loader ? (
        <MyLoader />
      ) : (
        <>
          {Object.keys(testData).length > 0 && (
            <>
              <ClientOverallResults
                clientLogo={JSON.parse(client?.value)?.logo}
                clientTestReports={testData}
                setTestsBroughMonthlyRevenue={setTestsBroughMonthlyRevenue}
                currency={JSON.parse(client?.value)?.currency}
              />

              <ul
                className="nav nav-tabs client-dashboard-tabs"
                id="myTab"
                role="tablist"
              >
                {filters?.map((btn, i) => (
                  <li className="nav-item" role="presentation" key={i}>
                    <button
                      className={`nav-link ${
                        btn.value === statusFilter ? `active` : ``
                      }`}
                      id={`${btn?.value.toLowerCase()}-tab`}
                      type="button"
                      onClick={(_) => {
                        setLoader(true);
                        setTimeout(function () {
                          setStatusFilter(btn.value);
                        }, 200);
                      }}
                    >
                      {btn?.label}
                    </button>
                  </li>
                ))}
              </ul>
              <div className="tab-content pt-2" id="myTabContent">
                <ClientEndedAndDraftTests
                  reports={testData?.report}
                  upcomingTest={myTests}
                  statusFilter={statusFilter}
                  client={client}
                />
              </div>
              {testsBroughMonthlyRevenue && (
                <ModalWithTableData
                  closeModal={setTestsBroughMonthlyRevenue}
                  data={testsBroughMonthlyRevenue}
                  columns={columnstests}
                  maxWidth={80}
                />
              )}
            </>
          )}
        </>
      )} */}
    </>
  );
};
