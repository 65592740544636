import { useQuery } from "@apollo/client";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate, useOutletContext } from "react-router-dom";
import { GETCLIENTSWITHTESTS } from "../../../Graphql/Queries";
import { VscGraph, VscHome } from "react-icons/vsc";
import useStore from "../../../Store";
// import { AdminEditActions } from "./AdminEditActions";
import MyLoader from "../../MiroComponents/PreLoader";
import { Pagination } from "../../Shared/Paginations";
import { differenceInCalendarDays } from "date-fns";
import { customStyles } from "../../../Libs/TableCustomStyles";
import MyRoutes from "../../../Routes";

function ClientsRunningTests() {
  const navigate = useNavigate();
  const [myProfile, is_admin, is_user] = useOutletContext();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const { data, loading, error, refetch } = useQuery(GETCLIENTSWITHTESTS, {
    variables: {
      clientFilters: [],
      page: page,
      size: size,
      testStatus: "live",
    },
  });

  const testLiveDays = (liveDate) => {
    const days = Number(
      differenceInCalendarDays(new Date(), new Date(liveDate))
    );
    return days;
  };

  const testCanBeEvaluated = (
    liveDate,
    testId,
    clientId,
    propertyId,
    bgclientID,
    defaultDataSet,
    platform
  ) => {
    const days = Number(
      differenceInCalendarDays(new Date(), new Date(liveDate))
    );
    if (days < 30) {
      return (
        <span>
          - Results can probably evaluated in
          <strong className="d-inline-flex ms-1 me-1">
            {30 - Number(days)}{" "}
          </strong>
          days
        </span>
      );
    } else {
      return (
        <span>
          <span className="d-inline-flex align-items-center">
            - Results can be evaluated now{" "}
            <button
              className="btn btn-sm btn-primary ms-1 fw-normal"
              onClick={(_) => {
                if (platform === "GA4" && bgclientID) {
                  navigate(
                    `${MyRoutes.gaanalytics}?platform=BQ&client=${clientId}`,
                    {
                      state: {
                        testId: testId,
                      },
                    }
                  );
                } else {
                  navigate(
                    `${MyRoutes.gaanalytics}?platform=${platform}&client=${clientId}&viewId=${propertyId}`,
                    {
                      state: {
                        testId: testId,
                      },
                    }
                  );
                }
              }}
            >
              View live Report <VscGraph />
            </button>
          </span>
        </span>
      );
    }
  };

  const columnsclients = [
    {
      name: "Client name",
      selector: (row) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={(_) => {
            navigate(`/client-reports`, {
              state: {
                clientId: row?._id,
                propertyId: row?.gaproperties[0]?.viewId,
              },
            });
          }}
        >
          {row.name} {row?.tier && <> ({row?.tier?.name})</>}
        </div>
      ),
      wrap: true,
      width: "20%",
    },
    {
      name: "Properties",
      cell: (row) => {
        const { gaproperties } = row;
        return (
          <div className="py-2 w-100">
            <div className="properties ">
              {gaproperties.map((property, i) => {
                return (
                  <div className="border-0" key={i}>
                    <div className="card-body ps-0">
                      <div className="" key={i} titlel={property?.viewId}>
                        <VscHome /> {property?.propertyname}
                      </div>
                      <ul className="mb-0 mt-1 test-overview-list">
                        {row?.tests?.map((tst, index) => (
                          <li key={index}>
                            <span>{tst?.name}</span>{" "}
                            <span>
                              {testLiveDays(
                                tst?.livedate,
                                tst?._id,
                                row?._id,
                                property.viewId
                              )}{" "}
                              (Days){" "}
                              {testCanBeEvaluated(
                                tst?.livedate,
                                tst?._id,
                                row?._id,
                                property.viewId,
                                row?.bqClientId,
                                row?.defaultDataSet,
                                tst?.platform
                              )}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      },
      wrap: true,
    },
  ];
  if (loading) return <MyLoader />;
  if (error) return <div> {error?.message}</div>;
  const { clientsWithTests } = data;
  return (
    <div className="clients-inner">
      <div className="mt-3">
        <DataTable
          columns={columnsclients}
          data={clientsWithTests?.clients}
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={<MyLoader />}
        />
      </div>
      <Pagination
        page={page}
        size={size}
        setPage={setPage}
        totalcount={clientsWithTests?.total}
      />
    </div>
  );
}
export default ClientsRunningTests;
