import React from "react";

import "./SmartInput.scss";
import { TipIcon } from "../../Shared/Icons";
export const SmartInput = ({ label, icon, placeholder, tip, ...props }) => {
  return (
    <div className="smart-input">
      <label> {label}</label>
      {icon}
      <div className="smart-input-cover">
        <input placeholder={placeholder} {...props} />
        {tip && (
          <div data-for="soclose" data-tip={tip} className="tip toottip-span">
            <TipIcon />
          </div>
        )}
      </div>
    </div>
  );
};
// Path: src/Components/MiroComponents/SmartInput/SmartInput.jsx
