import React from "react";
import "./SmartProjectionDetails.scss";

export const SmartProjectionDetails = ({
  smartProjectionData,
  months = [],
  selectedProjectionType,
  businessCase,
}) => {
  const MonthsList = ({ value, noValue }) => {
    if (noValue) return;
    return <div className="list-data"> {value}</div>;
  };

  const headingName = {
    orders: "Orders",
    aov: "AOV",
    revenue: "Revenue",
    effectWhileTesting: "Effect While Testing",
    effectFor3Months: "Effect For 3 Months",
    additionalTransaction: "Additional Transactions",
    additionalTransactionNextMonth: "Additional Transactions",
    effectForNextMonth: "Effect For Next Month",
    realizedPercentage: "Realized Percentage",
    realizedClv: "Realized CLV",
    realizedClp: "Realized CLP",
    allRealizedProfit: "All Realized Profit",
    additionalclv365d: "Additional CLV 365d",
    additionalclp365d: "Additional CLP 365d",
    businessData: "Business Data",
    instantEffect: "Instant Effect",
    secondaryEffect: "Secondary Effect",
    businessCaseWithClv6M: "6M Business Case with CLV",
    businessCaseWithClv12M: "12M Business Case with CLV",
    projection365d: "",
    revenueThreeMonthCohort: "Revenue 3m Cohort",
    serviceCost: "Service Costs",
    profitThreeMonthCohort: "Profit 3m Cohort",
    futureCLP: "Future CLP",
  };
  const DataValue = ({ value, prefix = "", postfix = "", noValue }) => {
    if (noValue) return "";
    if (value === null || isNaN(value))
      return <div className="list-data">-</div>;
    // value =
    //   value % 1 != 0
    //     ? Number(value)?.toFixed(2)?.toLocaleString()
    //     : value.toLocaleString();
    value = value.toLocaleString();
    return (
      <div className="list-data">
        {prefix}
        {value}
        {postfix}{" "}
      </div>
    );
  };
  function camelToSnake(str) {
    return str.replace(/[A-Z0-9]/g, (letter) => ` ${letter.toLowerCase()}`);
  }

  const filteredHeadingCondition = (d) =>
    businessCase === "client-projection"
      ? d !== "month" && d !== "secondaryEffect"
      : d !== "month" && d !== "secondaryEffect";

  const BusinessCaseWith3MCohort = ({
    heading = "Business Case with 3M Cohort",
    data = [],
    total,
  }) => {
    return (
      <div className="list-wrapper-outer">
        <div className="list-main-heading">
          <h3> {heading}</h3>
        </div>
        <div className="list-wrapper">
          <div className="inside-values list-heading">
            {data &&
              Object.keys(data[0])
                ?.filter((d) => filteredHeadingCondition(d))
                .map((key, index) => {
                  return <h3 key={index}>{headingName[key]}</h3>;
                })}
          </div>
          {data?.map((data, index) => {
            return (
              <div className="inside-values" key={index + "mval"}>
                <DataValue value={data.revenueThreeMonthCohort} prefix="€" />
                <DataValue value={data.serviceCost} prefix="€" />

                <DataValue value={data.profitThreeMonthCohort} />
              </div>
            );
          })}
          <div className="inside-values total">
            <DataValue value={total.revenueThreeMonthCohort} prefix="€" />
            <DataValue value={total.serviceCost} prefix="€" />
            <DataValue value={total.profitThreeMonthCohort} prefix="€" />
          </div>
        </div>
      </div>
    );
  };

  const SecondaryEffect = ({
    heading = "Secondary Effect",
    data = [],
    total,
  }) => {
    return (
      <div className="list-wrapper-outer">
        <div className="list-main-heading">
          <h3> {heading}</h3>
        </div>
        <div className="list-wrapper">
          <div className="inside-values list-heading">
            {data &&
              Object.keys(data[0])
                ?.filter((d) => filteredHeadingCondition(d))
                .map((key, index) => {
                  return <h3 key={index}>{headingName[key]}</h3>;
                })}
          </div>
          {data?.map((data, index) => {
            return (
              <div className="inside-values" key={index + "mval"}>
                {data?.effectForNextMonth !== undefined ? (
                  <DataValue value={data.effectForNextMonth} prefix="€" />
                ) : (
                  ``
                )}

                <DataValue value={data.effectFor3Months} prefix="€" />
                <DataValue value={data.secondaryEffect} postfix="%" />

                <DataValue
                  value={
                    businessCase === "client-projection"
                      ? data.additionalTransactionNextMonth
                      : data.additionalTransaction
                  }
                />
              </div>
            );
          })}
          <div className="inside-values total">
            {total?.effectForNextMonth !== undefined && (
              <DataValue value={total.effectForNextMonth} prefix="€" />
            )}
            <DataValue value={total.effectFor3Months} prefix="€" />
            <DataValue value={total.secondaryEffect} prefix="€" />
            <DataValue
              value={
                businessCase === "client-projection"
                  ? total.additionalTransactionNextMonth
                  : total.additionalTransaction
              }
              prefix="€"
            />
          </div>
        </div>
      </div>
    );
  };

  const FutureClp = ({
    heading = "Future projection of incoming CLP + CRO Work",
    data = [],
    total,
  }) => {
    return (
      <div className="list-wrapper-outer">
        <div className="list-main-heading">
          <h3> {heading}</h3>
        </div>
        <div className="list-wrapper">
          <div
            className="inside-values list-heading"
            style={{
              textAlign: "right",
            }}
          >
            {data &&
              Object.keys(data[0])
                ?.filter((d) => filteredHeadingCondition(d))
                .map((key, index) => {
                  return <h3 key={index}>{headingName[key]}</h3>;
                })}
          </div>
          {data?.map((data, index) => {
            return (
              <div
                className="inside-values"
                style={{
                  textAlign: "right",
                }}
                key={index + "mval"}
              >
                <DataValue value={data.futureCLP} prefix="€" />
              </div>
            );
          })}
          <div
            className="inside-values total"
            style={{
              textAlign: "right",
            }}
          >
            <DataValue value={total.futureCLP} prefix="€" />
          </div>
        </div>
      </div>
    );
  };

  const InstantEffect = ({ heading = "Instant Effect", data = [], total }) => (
    <div className="list-wrapper-outer dark-bg">
      <div className="list-main-heading">
        <h3> {heading}</h3>
      </div>
      <div className="list-wrapper">
        <div className="inside-values list-heading">
          {data &&
            Object.keys(data[0])
              ?.filter((d) => d !== "month" && d !== "instantEffect")
              .map((key, index) => {
                return <h3 key={index + "inteff"}> {headingName[key]}</h3>;
              })}
        </div>
        {data?.map((data, index) => {
          return (
            <div className="inside-values" key={index + "insteffval"}>
              <DataValue value={data.effectWhileTesting} prefix="€" />
              <DataValue value={data.instantEffect} postfix="%" />
            </div>
          );
        })}
        <div className="inside-values total">
          <DataValue value={total.effectWhileTesting} prefix="€" />
          <DataValue value={total.instantEffect} prefix="" postfix="%" />
        </div>
      </div>
    </div>
  );
  const BusinessData = ({ heading = "Business Data", data = [], total }) => (
    <div className="list-wrapper-outer">
      <div className="list-main-heading">
        <h3>{heading}</h3>
      </div>
      <div className="list-wrapper">
        <div className="inside-values list-heading">
          {data &&
            Object.keys(data[0])
              ?.filter((d) => d !== "month")
              .map((key, index) => {
                return <h3 key={index + "mnthkey2"}> {headingName[key]}</h3>;
              })}
        </div>
        {data?.map((data, index) => {
          return (
            <div className="inside-values" key={index + "smrtprojdet"}>
              <DataValue value={data.orders} />
              <DataValue value={data.aov} prefix="€" />
              <DataValue value={data.revenue} prefix="€" />
            </div>
          );
        })}
        <div className="inside-values total">
          <DataValue value={total.orders} />
          <DataValue value={total.aov} prefix="€" />
          <DataValue value={total.revenue} prefix="€" />
        </div>
      </div>
    </div>
  );
  const BusinessCaseClv = ({
    heading = "6M Business Case with CLV",
    data = [],
    total,
  }) => (
    <div className="list-wrapper-outer">
      <div className="list-main-heading">
        <h3>{heading}</h3>
      </div>
      <div className="list-wrapper">
        <div className="inside-values list-heading">
          {data &&
            Object.keys(data[0])
              ?.filter(
                (d) =>
                  d !== "month" &&
                  d !== "additionalclp365d" &&
                  d !== "additionalclv365d"
              )
              .map((key, index) => {
                return <h3 key={index + "mnthkey2"}> {headingName[key]}</h3>;
              })}
        </div>
        {data?.map((data, index) => {
          return (
            <div className="inside-values" key={index}>
              <div className="">
                <DataValue value={data.realizedPercentage} postfix="%" />
              </div>
              <div className="">
                <DataValue value={data.realizedClv} prefix="€" />
              </div>
              <div className="">
                <DataValue value={data.realizedClp} prefix="€" />
              </div>
              <div>
                <DataValue value={data.allRealizedProfit} prefix="€" />
              </div>
            </div>
          );
        })}
        <div className="inside-values total">
          <DataValue
            value={total.realizedPercentage}
            prefix="%"
            // noValue={true}
          />
          <DataValue value={total.realizedClv} prefix="€" />
          <DataValue value={total.realizedClp} prefix="€" />
          <DataValue value={total.allRealizedProfit} prefix="€" />
        </div>
      </div>
    </div>
  );

  const BusinessCaseIncludingRealizedCLP = ({
    heading = "Business case including realized CLP",
    data = [],
    total,
  }) => (
    <div className="list-wrapper-outer">
      <div className="list-main-heading">
        <h3>{heading}</h3>
      </div>
      <div className="list-wrapper">
        <div className="inside-values list-heading">
          {data &&
            Object.keys(data[0])
              ?.filter((d) => d !== "month" && d !== "realizedClv")
              .map((key, index) => {
                return <h3 key={index + "mnthkey2"}> {headingName[key]}</h3>;
              })}
        </div>
        {data?.map((data, index) => {
          return (
            <div className="inside-values" key={index}>
              <div className="">
                <DataValue value={data.additionalclv365d} prefix="€" />
              </div>
              <div className="">
                <DataValue value={data.additionalclp365d} prefix="€" />
              </div>
              <div className="">
                <DataValue value={data.realizedPercentage} postfix="%" />
              </div>

              <div className="">
                <DataValue value={data.realizedClp} prefix="€" />
              </div>
              <div>
                <DataValue value={data.allRealizedProfit} prefix="€" />
              </div>
            </div>
          );
        })}
        <div className="inside-values total">
          <DataValue value={total.additionalclv365d} prefix="€" />
          <DataValue value={total.additionalclp365d} prefix="€" />
          <DataValue
            value={total.realizedPercentage}
            prefix="%"
            // noValue={true}
          />
          <DataValue value={total.realizedClp} prefix="€" />
          <DataValue value={total.allRealizedProfit} prefix="€" />
        </div>
      </div>
    </div>
  );
  const Projection365d = ({ heading = "", data = [], total }) => (
    <div className="list-wrapper-outer">
      <div className="list-main-heading">
        <h3>{heading}</h3>
      </div>
      <div className="list-wrapper">
        <div className="inside-values list-heading">
          {data &&
            Object.keys(data[0])
              ?.filter((d) => d !== "month")
              .map((key, index) => {
                return <h3 key={index + "mnthkey2"}> {headingName[key]}</h3>;
              })}
        </div>
        {data?.map((data, index) => {
          return (
            <div className="inside-values" key={index}>
              <div className="">
                <DataValue value={data.additionalclv365d} prefix="€" />
              </div>
              <div className="">
                <DataValue value={data.additionalclp365d} prefix="€" />
              </div>
            </div>
          );
        })}
        <div className="inside-values total">
          <DataValue value={total.additionalclv365d} prefix="€" />
          <DataValue value={total.additionalclp365d} prefix="€" />
        </div>
      </div>
    </div>
  );
  return (
    <div className="calculation-flex-wrapper m-36-top">
      <div className="list-wrapper-outer">
        <div className="list-main-heading">
          <h3
            style={{
              opacity: 0,
            }}
          >
            {" "}
            Month
          </h3>
        </div>
        <div className="list-heading">
          <h3> Month</h3>
        </div>
        <div className="list-wrapper">
          {months.map((month, index) => {
            return <MonthsList key={index + "mnth2"} value={month} />;
          })}
          <div className="inside-values total">
            <div className="list-data"> Total</div>
          </div>
        </div>
      </div>
      {selectedProjectionType === "6M Business Case with CLV" ? (
        <>
          <Projection365d
            heading=""
            data={smartProjectionData?.projection182d?.data}
            total={smartProjectionData?.projection182d?.total}
          />
          <BusinessCaseClv
            heading={selectedProjectionType}
            data={smartProjectionData?.businessCaseWithClv6M?.data}
            total={smartProjectionData?.businessCaseWithClv6M?.total}
          />
        </>
      ) : (
        ""
      )}
      {selectedProjectionType === "Business Data" ? (
        <>
          <BusinessData
            heading="Business Data"
            data={smartProjectionData?.businessData?.data}
            total={smartProjectionData?.businessData?.total}
          />
          <InstantEffect
            heading="Instant Effect"
            data={smartProjectionData?.instantEffect?.data}
            total={smartProjectionData?.instantEffect?.total}
          />
          <SecondaryEffect
            heading="Secondary Effect"
            data={smartProjectionData?.secondaryEffect?.data}
            total={smartProjectionData?.secondaryEffect?.total}
          />
        </>
      ) : (
        ""
      )}
      {selectedProjectionType === "12M Business Case with CLV" ? (
        <>
          <Projection365d
            heading=""
            data={smartProjectionData?.projection365d?.data}
            total={smartProjectionData?.projection365d?.total}
          />
          <BusinessCaseClv
            heading={selectedProjectionType}
            data={smartProjectionData?.businessCaseWithClv12M?.data}
            total={smartProjectionData?.businessCaseWithClv12M?.total}
          />
        </>
      ) : (
        ""
      )}
      {selectedProjectionType === "Business case including realized CLP" ? (
        <>
          <BusinessCaseIncludingRealizedCLP
            heading={selectedProjectionType}
            data={smartProjectionData?.businessCaseWithClv12M?.data}
            total={smartProjectionData?.businessCaseWithClv12M?.total}
          />
        </>
      ) : (
        ""
      )}
      {selectedProjectionType ===
      "Future projection of incoming CLP + CRO Work" ? (
        <>
          <BusinessCaseIncludingRealizedCLP
            heading={selectedProjectionType}
            data={smartProjectionData?.businessCaseWithClv12M?.data}
            total={smartProjectionData?.businessCaseWithClv12M?.total}
          />
          <FutureClp
            heading=""
            data={smartProjectionData?.futureCLP?.data}
            total={smartProjectionData?.futureCLP?.total}
          />
        </>
      ) : (
        ""
      )}
      {selectedProjectionType === "Business Case with 3m Cohort" ? (
        <BusinessCaseWith3MCohort
          heading="Business Case with 3M Cohort"
          data={smartProjectionData?.threeMonthCohort?.data}
          total={smartProjectionData?.threeMonthCohort?.total}
        />
      ) : (
        ""
      )}
    </div>
  );
};
