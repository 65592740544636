import { GETALLREPORTS } from "../../../Graphql/Queries";
import { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import MyLoader from "../../MiroComponents/PreLoader";
import { Pagination } from "../../Shared/Paginations";
import { TickGreen } from "../../Shared/Icons";
import { VscClose } from "react-icons/vsc";
import { toast } from "react-toastify";
import { UPDATEREPORTLEARNINGS } from "../../../Graphql/Mutations";
import { FiltersComponent } from "../../Shared/FiltersComponent";

export default function ClientReportsLeanings() {
  const [learnings, setLearnings] = useState([]);
  const [serverFilters, setServerFilters] = useState([]);
  const [filter, showFilter] = useState(false);
  const [action, setAction] = useState({});
  const [allreports, setAllReports] = useState({
    loading: true,
  });
  const [page, setPage] = useState(1);
  const size = 10;
  const [getallreports] = useLazyQuery(GETALLREPORTS);
  const [updatereportlearnings] = useMutation(UPDATEREPORTLEARNINGS);
  const getallreportsquery = useCallback(async () => {
    try {
      const {
        data: { getAllReports: allreports },
        loading,
      } = await getallreports({
        fetchPolicy: "cache-and-network",
        variables: {
          page,
          size,
          filters: serverFilters?.length > 0 ? serverFilters : null,
        },
      });
      setAllReports({
        loading,
        total: allreports?.total,
        reports: allreports?.reports,
      });
    } catch (error) {
      setAllReports({
        loading: false,
        error: error?.message,
      });
    }
  }, [page, size, serverFilters, getallreports]);

  useEffect(() => {
    getallreportsquery();
  }, [getallreportsquery]);

  const TestRowComponent = ({
    conclusion,
    segmentName,
    test,
    learnings,
    _id,
  }) => {
    return (
      <div className="test-details-table-data">
        <div className="test-name">
          <h3>{test?.name}</h3>
        </div>
        <div className="hypothesis-data">
          <h3 style={segmentName === "no data" ? { color: "red" } : {}}>
            {conclusion}
          </h3>
        </div>
        <div className="test-compare-img">
          <ul>
            {learnings?.map((learning, index) => (
              <li key={index}>
                <span> {learning}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="test-action-wrapper">
          <div className="test-action">
            <button
              disabled={segmentName === "no data" ? true : false}
              type="button"
              onClick={(_) => {
                setAction({ module: "edit", reportId: _id });
                setLearnings(learnings);
              }}
              className={`btn btn-sm conclusion-factor-btn btn-primary`}
            >
              Update Learnings
            </button>
          </div>
        </div>
      </div>
    );
  };

  const AddLearnings = () => {
    return (
      <div className="fields-wrapper-group">
        <label>Add Learnings *</label>
        <div>
          <input id="learningText" placeholder="Add learnings from this test" />
          <button
            type="button"
            onClick={(_) => {
              const learningvalue =
                document.querySelector("#learningText").value;
              if (learningvalue.trim() !== "") {
                setLearnings((_learnings) => [..._learnings, learningvalue]);
                document.querySelector("#learningText").value = "";
              }
            }}
          >
            Add
          </button>
        </div>
      </div>
    );
  };

  if (allreports?.loading) return <MyLoader />;
  if (allreports?.error) return <div>{allreports?.error}</div>;
  return (
    <div>
      {filter && (
        <FiltersComponent
          serverFilters={serverFilters}
          setServerFilters={setServerFilters}
          setPage={setPage}
          filterTypes={["findtestbyclientid", "findtestbytestname"]}
        />
      )}

      {action?.module && (
        <div className="create-report-modal">
          <div className="create-report-modal-inner">
            <div className="card">
              <button
                className="close-modal"
                onClick={(_) => {
                  setAction({});
                  setLearnings([]);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#000"
                >
                  <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
                </svg>{" "}
              </button>
              <div className="card-body">
                <h3> Update Report Learnings</h3>
                <form>
                  <AddLearnings />
                  {learnings.length > 0 && (
                    <div className="leanings-preview">
                      <ul>
                        {learnings.map((l, i) => (
                          <li key={i}>
                            {" "}
                            <TickGreen />{" "}
                            <span>
                              {l}{" "}
                              <VscClose
                                onClick={(_) => {
                                  const arr = [...learnings];
                                  arr.splice(i, 1);
                                  setLearnings(arr);
                                }}
                              />
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <div className="fields-wrapper mt-2">
                    <button
                      type="button"
                      onClick={async (_) => {
                        if (learnings?.length === 0) {
                          toast.error("Please add Learnings");
                          return;
                        }
                        try {
                          const { data } = await updatereportlearnings({
                            variables: {
                              id: action?.reportId,
                              learnings,
                            },
                          });
                          if (data?.updateReportLearnings) {
                            toast.success("Learnings Updated");
                            setAction({});
                            setLearnings([]);
                            getallreportsquery();
                          }
                        } catch (error) {
                          toast.error(error?.message);
                        }
                      }}
                    >
                      Update Report Learnings
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="header-bar">
        <div className="header-bar-inner">
          <h2> Reports Learnings</h2>
          <button
            className="btn-as-link d-inline-flex align-items-center text-white"
            onClick={(_) => {
              if (filter) {
                setPage(1);
                setServerFilters([]);
              }
              showFilter(!filter);
            }}
          >
            {filter ? "Reset Filter" : "Filters"}
          </button>
        </div>
      </div>
      <>
        <div className="table-body">
          <div className="test-details-table-data heading-row">
            <div className="test-name">
              <h3>Test</h3>
            </div>
            <div className="hypothesis-data">
              <h3>Conclusion</h3>
            </div>
            <div className="test-compare-img">
              <span>Learnings</span>
            </div>

            <div className="test-action-wrapper">
              <div className="test-action">
                <span> Action</span>
              </div>
            </div>
          </div>
          {allreports?.reports?.map((report, i) => (
            <TestRowComponent {...report} key={i} />
          ))}
        </div>
        <Pagination
          page={page}
          size={size}
          setPage={setPage}
          totalcount={allreports?.total}
        />
      </>
    </div>
  );
}
