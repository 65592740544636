import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { convertDate } from "../../../../Libs/Utilities";

function ConversionRateTimeSeriesGraph({ graphExperimentData, segmentName }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: true,
          color: "rgb(255 255 255 / 5%)",
        },
      },
      y: {
        grid: {
          color: "rgb(255 255 255 / 5%)",
          drawTicks: true,
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        font: {
          size: 14,
        },
      },
      title: {
        display: false,
        text: `Conversions Rate Time Series ${segmentName}`,
      },
      datalabels: {
        display: false,
        color: "#fff",
      },
    },
  };
  const colors = ["255 51 60", "86 122 250", "255 124 51", "0 128 0"];
  const labels = [
    ...new Map(
      graphExperimentData[0]?.experiments.map((gd) => [gd["date"], gd])
    ).values(),
  ].map((_) => convertDate(_?.date));

  const graphdataset = graphExperimentData.map((gde, i) => {
    return {
      label: gde?.controlvariant,
      lineTension: 0.4,
      borderWidth: 1,
      pointRadius: 3,
      pointHoverRadius: 4,
      pointBorderColor: `rgb(${colors[i]} / 100%)`,
      pointBackgroundColor: "#fff",
      borderColor: `rgb(${colors[i]} / 100%)`,
      data: gde?.experiments.map((gdm) =>
        Number(gdm?.transactionsPerSession).toFixed(2)
      ),
      backgroundColor: (context) => {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(0, 0, 0, 200);
        gradient.addColorStop(0, `rgb(${colors[i]} / 100%)`);
        gradient.addColorStop(0.5, `rgb(${colors[i]} / 50%)`);
        gradient.addColorStop(1, `rgb(${colors[i]} / 10%)`);
        return gradient;
      },
      fill: true,
    };
  });
  const dataset = {
    labels,
    datasets: graphdataset,
  };
  return (
    <div className="report-charts-height">
      <Line options={options} data={dataset} />
    </div>
  );
}

export default ConversionRateTimeSeriesGraph;
