import Select from "react-select";

export const SmartSelector = ({
  onChange,
  value,
  placeholder,
  isDisabled,
  options,
}) => {
  return (
    <div className="fields-wrapper">
      <Select
        className="acc-newselectwrapper"
        classNamePrefix="acc-newselect"
        placeholder={placeholder}
        onChange={(client) => onChange(client)}
        value={value}
        isDisabled={isDisabled}
        options={options}
      />
    </div>
  );
};
