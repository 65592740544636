import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { CREATETEST } from "../../Graphql/Mutations";
import useStore from "../../Store";
import { MultiSelect } from "react-multi-select-component";
import { useEffect, useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import { Calendar } from "react-date-range";
import { VscChromeClose, VscCloudUpload } from "react-icons/vsc";
import { FORMPREFILLDATA, GETREPORTANDTEST } from "../../Graphql/Queries";
import { toast } from "react-toastify";
import { LoaderLogo } from "../Shared/LoaderLogo";
import MyLoader from "./PreLoader";
export function EditTestForm() {
  const { _id } = useParams();
  const [controlImage, setControlImage] = useState(null);
  const [variantImage, setVariantImage] = useState(null);
  const [pageSelected, setPageSelected] = useState([]);
  const [goalSelected, setGoalSelected] = useState([]);
  const [triggerSelected, setTriggerSelected] = useState([]);
  const [tags, setTags] = useState([]);
  const [testStatus, setTestStatus] = useState("");
  const [liveDate, setLiveDate] = useState(new Date());
  const [urlTargets, setUrlTargets] = useState([]);
  const [triggerEvents, setTriggerEvents] = useState([]);
  const [test, setTest] = useState(null);
  // STATES
  const { data, loading } = useQuery(FORMPREFILLDATA, {
    variables: {
      fullSet: false,
    },
  });
  const formData = useQuery(GETREPORTANDTEST, {
    variables: {
      id: _id,
    },
  });
  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const pageobjectValueRenderer = (pageSelected) => {
    if (!pageSelected?.length) {
      return "Select Page / Object type";
    }
    return pageSelected?.length === 1
      ? `${pageSelected[0].label}`
      : pageSelected.map(({ label }) => (
          <span key={label} className="mr-1">
            {label}
          </span>
        ));
  };
  const goalValueRenderer = (goalSelected) => {
    if (!goalSelected?.length) {
      return "Select Goals";
    }
    return goalSelected.length === 1
      ? `${goalSelected[0].label}`
      : goalSelected.map(({ label }) => (
          <span key={label} className="mr-1">
            {label}
          </span>
        ));
  };
  const triggerValueRenderer = (triggerSelected) => {
    if (!triggerSelected?.length) {
      return "Select Triggers";
    }
    return triggerSelected?.length === 1
      ? `${triggerSelected[0].label}`
      : triggerSelected.map(({ label }) => (
          <span key={label} className="mr-1">
            {label}
          </span>
        ));
  };
  const tagValueRenderer = (tags) => {
    if (!tags?.length) {
      return "Select Tags";
    }
    return tags?.length === 1
      ? `${tags[0].label}`
      : tags.map(({ label }) => (
          <span key={label} className="mr-1">
            {label}
          </span>
        ));
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const requiredformfields = [
    "name",
    "client",
    // "pagetesttype",
    // "hypothesis",
    // "pageaudience",
    // "targetaudience",
    // "htmlcode",
    // "csscode",
    // "jscode",
    "testid",
    // "testtool",
    // "stageincustomerjourney",
    // "significance",
    // "recommendedsamplesize",
  ];

  useEffect(() => {
    const testdetails = formData?.data?.getReportDetails?.test;
    setTest(testdetails);
    setPageSelected(
      formData?.data?.getReportDetails?.test?.pageelement.map((el) => {
        return {
          label: el?.name,
          value: el?._id,
        };
      })
    );
    setTags(
      formData?.data?.getReportDetails?.test?.tags.map((el) => {
        return {
          label: el?.name,
          value: el?._id,
        };
      })
    );
    setGoalSelected(
      formData?.data?.getReportDetails?.test?.goal.map((el) => {
        return {
          label: el?.name,
          value: el?._id,
        };
      })
    );
    setTriggerSelected(
      formData?.data?.getReportDetails?.test?.trigger.map((el) => {
        return {
          label: el?.name,
          value: el?._id,
        };
      })
    );
    setTestStatus(formData?.data?.getReportDetails?.test?.status);
    setLiveDate(new Date(formData?.data?.getReportDetails?.test?.livedate));
  }, [formData?.loading, formData?.data]);

  let testPayload = {};
  if (loading || (formData?.loading && !test)) return <MyLoader />;
  return (
    <div>
      <div className="form-wrapper">
        <div className="form-wrapper-inner addtestform">
          <div className="card">
            <div className="card-body p-0">
              <h3> Update Test</h3>
              <form
              //   onSubmit={
              //       (e) => uploadTest(e)
              //       }
              >
                <div className="fields-wrapper">
                  <label> Test Name</label>
                  <input
                    placeholder="Test name"
                    name="name"
                    defaultValue={test?.name}
                  />
                </div>
                <MultiSelect
                  options={data?.pageelements?.map((pe) => {
                    return {
                      label: pe?.name,
                      value: pe?._id,
                    };
                  })}
                  value={pageSelected}
                  onChange={setPageSelected}
                  valueRenderer={pageobjectValueRenderer}
                  labelledBy="Select"
                />

                <div className="fields-wrapper">
                  <label>Select Client</label>
                  <select name="client" defaultValue={test?.client?._id}>
                    <option value=""> Select client</option>
                    {data?.clients?.map((c, i) => (
                      <option key={i} value={c?._id}>
                        {" "}
                        {c?.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="fields-wrapper">
                  <label> Select Test Type</label>
                  <select
                    name="pagetesttype"
                    defaultValue={
                      test?.pagetesttype?.length > 0 &&
                      test?.pagetesttype[0]?._id
                    }
                  >
                    <option value=""> Select Test Type</option>
                    {data?.pagetesttypes?.map((c, i) => (
                      <option key={i} value={c?._id}>
                        {" "}
                        {c?.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="control-variant-image-container">
                  <div
                    className="control-image"
                    // style={{ backgroundImage: `url(${controlImage})` }}
                  >
                    <div className="upload-icon">
                      <VscCloudUpload
                        onClick={(_) => {
                          _.currentTarget.nextSibling.click();
                        }}
                      />
                      <input
                        onChange={async (_) => {
                          setControlImage(_.currentTarget.files[0]);
                          const preview = await toBase64(
                            _.currentTarget.files[0]
                          );
                          document.querySelector(
                            ".control-image"
                          ).style.backgroundImage = `url(${preview})`;
                        }}
                        type="file"
                        name="controlImage"
                        style={{ display: "none" }}
                      />
                      {controlImage && (
                        <VscChromeClose
                          onClick={(_) => {
                            setControlImage(null);
                            document.querySelector(
                              ".control-image"
                            ).style.backgroundImage = `url()`;
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className="variant-image"
                    // style={{ backgroundImage: `url(${variantImage})` }}
                  >
                    <div className="upload-icon">
                      <VscCloudUpload
                        onClick={(_) => {
                          _.currentTarget.nextSibling.click();
                        }}
                      />
                      <input
                        type="file"
                        onChange={async (_) => {
                          setVariantImage(_.currentTarget.files[0]);
                          const preview = await toBase64(
                            _.currentTarget.files[0]
                          );
                          document.querySelector(
                            ".variant-image"
                          ).style.backgroundImage = `url(${preview})`;
                        }}
                        name="variantImage"
                        style={{ display: "none" }}
                      />
                      {variantImage && (
                        <VscChromeClose
                          onClick={(_) => {
                            document.querySelector(
                              ".variant-image"
                            ).style.backgroundImage = `url()`;
                            setVariantImage(null);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <MultiSelect
                  options={data?.tags?.map((tg) => {
                    return {
                      label: tg?.name,
                      value: tg?._id,
                    };
                  })}
                  value={tags}
                  onChange={setTags}
                  valueRenderer={tagValueRenderer}
                  labelledBy="Select"
                />

                <div className="fields-wrapper">
                  <label> Select Hypothesis</label>
                  <select
                    name="hypothesis"
                    defaultValue={test?.hypothesis?._id}
                  >
                    <option value=""> All Hypothesis </option>
                    {data?.allHypothesis?.map((c, i) => (
                      <option key={i} value={c?._id}>
                        {c?.name} | {c?.action}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="fields-wrapper">
                  <label> Target URLs</label>
                  <ReactTags
                    tags={urlTargets}
                    delimiters={delimiters}
                    handleDelete={(i) =>
                      setUrlTargets(
                        urlTargets.filter((target, index) => index !== i)
                      )
                    }
                    handleAddition={(urltarget) =>
                      setUrlTargets([...urlTargets, urltarget])
                    }
                    inputFieldPosition="top"
                    placeholder="Add new url targets"
                  />
                </div>

                <div className="fields-wrapper">
                  <label> Add Page Audience</label>
                  <input
                    placeholder="Page audience"
                    name="pageaudience"
                    defaultValue={test?.pageaudience}
                  />
                </div>
                <div className="fields-wrapper">
                  <label> Target Audience</label>
                  <input
                    placeholder="Target audience"
                    name="targetaudience"
                    defaultValue={test?.targetaudience}
                  />
                </div>

                <div className="fields-wrapper">
                  <label> Page</label>
                  <input
                    placeholder="Page"
                    name="page"
                    defaultValue={test?.page}
                  />
                </div>
                <div className="fields-wrapper">
                  <label> Event Parameter</label>
                  <input
                    placeholder="Event Parameter"
                    name="eventparameter"
                    defaultValue={test?.eventparameter}
                  />
                </div>

                <div className="fields-wrapper">
                  <label> Trigger Events</label>
                  <ReactTags
                    tags={triggerEvents}
                    delimiters={delimiters}
                    handleDelete={(i) =>
                      setTriggerEvents(
                        triggerEvents.filter((target, index) => index !== i)
                      )
                    }
                    handleAddition={(triggerEvent) =>
                      setTriggerEvents([...triggerEvents, triggerEvent])
                    }
                    inputFieldPosition="top"
                    placeholder="Add trigger events"
                  />
                </div>

                <MultiSelect
                  options={data?.goals?.map((pe) => {
                    return {
                      label: pe?.name,
                      value: pe?._id,
                    };
                  })}
                  value={goalSelected}
                  onChange={setGoalSelected}
                  valueRenderer={goalValueRenderer}
                  labelledBy="Select"
                />
                <div className="fields-wrapper">
                  <label> HTML Code</label>
                  <textarea
                    defaultValue={test?.htmlcode}
                    placeholder="Add HTML code"
                    name="htmlcode"
                  ></textarea>
                </div>

                <div className="fields-wrapper">
                  <label> CSS Code</label>
                  <textarea
                    defaultValue={test?.csscode}
                    placeholder="Add CSS code"
                    name="csscode"
                  ></textarea>
                </div>
                <div className="fields-wrapper">
                  <label> JS Code</label>
                  <textarea
                    defaultValue={test?.jscode}
                    placeholder="Add JS code"
                    name="jscode"
                  ></textarea>
                </div>
                <div className="fields-wrapper">
                  <label> Test ID</label>
                  <input
                    placeholder="Test ID"
                    name="testid"
                    defaultValue={test?.testid}
                  />
                </div>
                <div className="fields-wrapper">
                  <label> Test Tool</label>
                  <input
                    placeholder="Test Tool"
                    name="testtool"
                    defaultValue={test?.testtool}
                  />
                </div>

                <div className="">
                  <MultiSelect
                    options={data?.triggers?.map((pe) => {
                      return {
                        label: pe?.name,
                        value: pe?._id,
                      };
                    })}
                    value={triggerSelected}
                    onChange={setTriggerSelected}
                    valueRenderer={triggerValueRenderer}
                    labelledBy="Select"
                  />
                </div>

                <div className="fields-wrapper">
                  <label> Stage in Customer Journey</label>
                  <input
                    placeholder="Stage in customer journey"
                    defaultValue={test?.stageincustomerjourney}
                    name="stageincustomerjourney"
                  />
                </div>

                <div className="fields-wrapper">
                  <label> Add Reveue</label>
                  <select
                    name="revenuetype"
                    className="mb-2"
                    defaultValue={
                      test?.revenue?.length > 0 && test?.revenue[0].type
                    }
                  >
                    <option value="">Revenue Type</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>

                <div className="fields-wrapper">
                  <label> Revenue</label>
                  <input
                    defaultValue={
                      test?.revenue?.length > 0 && test?.revenue[0].value
                    }
                    className="mb-0"
                    placeholder="Revenue Value"
                    name="revenue"
                  />
                </div>

                <div className="fields-wrapper">
                  <label> Significance</label>
                  <input
                    placeholder="Significance"
                    name="significance"
                    defaultValue={test?.significance}
                  />
                </div>
                <div className="fields-wrapper">
                  <label> Recommended Sample Size</label>
                  <input
                    placeholder="Recommended sample size"
                    name="recommendedsamplesize"
                    defaultValue={test?.recommendedSampleSize}
                  />
                </div>

                <div className="fields-wrapper">
                  <label> Test Status</label>
                  <select
                    defaultValue={test?.status}
                    onChange={(_) => {
                      setTestStatus(_?.currentTarget.value);
                    }}
                  >
                    <option value="">Test Status</option>
                    <option value="live">Live</option>
                    <option value="draft">Draft</option>
                  </select>
                </div>

                {testStatus === "live" && (
                  <div className="field-wrapper">
                    <label className="mb-2"> Start Date of this Test</label>
                    <div className="text-center bg-light pb-3">
                      <Calendar
                        date={liveDate}
                        onChange={(_) => setLiveDate(_)}
                      />
                    </div>
                  </div>
                )}
                <div className="mt-4">
                  <button className="submit-class" type="submit">
                    Add Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
