import { useQuery } from "@apollo/client";
import React, { useMemo, useState } from "react";
import { ALLABTESTS } from "../../../Graphql/Queries";
import { LoaderLogo } from "../../Shared/LoaderLogo";
import { toast } from "react-toastify";
import { Pagination } from "../../Shared/Paginations";
import moment from "moment";
import "./ExperimentsList.scss";
import Select from "react-select";
import { useMatch, useNavigate } from "react-router-dom";
import MyRoutes from "../../../Routes";
import ClientSettings from "../ClientSettings/ClientSettings";

export default function ExperimentsList({ client, viewId }) {
  const [page, setPage] = useState(1);
  const experimentBoxRef = React.createRef();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("experiments");
  const size = 10;
  // const [createClientExperiment] = useMutation(CREATECLIENTEXPERIMENTSCRIPT);

  const { data, loading, error, refetch } = useQuery(ALLABTESTS, {
    variables: {
      page,
      size,
      testFilters: [
        {
          client: [client],
          testtool: "Codebase",
          "property.value": viewId,
        },
      ],
    },
  });

  const tabs = useMemo(() => {
    return [
      {
        name: "experiments",
        label: "Experiments",
      },
      {
        name: "settings",
        label: "Settings",
      },
    ];
  }, []);

  const ExperimentBox = React.forwardRef(({ experiment }, ref) => {
    const defaultVariant = experiment?.variations?.find(
      (c) => c?.name === "Reference" || c.traffic_allocation > 0
    );
    const [previewVariant, setPreviewVariant] = useState({
      label: defaultVariant?.name,
      value: defaultVariant?._id,
    });
    return (
      <section className="ab-test-overview" ref={ref}>
        <div className="experiment-list-inner">
          <div className="experiment-box">
            <div
              className="experiment-box-head"
              onClick={(e) => {
                e.target
                  .closest(".experiment-box")
                  .querySelector(".experiment-overview")
                  .classList.toggle("active");
              }}
            >
              <h3>
                {experiment?.name ?? `Experiment Name`}{" "}
                <span className={experiment?.status?.toLowerCase()}>
                  {" "}
                  {experiment?.status}{" "}
                </span>
              </h3>
              <p>{moment(experiment?.createdAt).format("LL")}</p>
            </div>
            <div className="experiment-overview" id="add-new-test-form">
              <h2> Experiment is in {experiment?.status} Mode</h2>
              <p>
                This experiment is in {experiment?.status} mode. It will only be
                visible to you.
              </p>
              <div className="fields-wrapper">
                <label className="nofloat">Experiment URL</label>
                <input
                  type="text"
                  value={experiment?.urltargeting?.[0]}
                  disabled
                  className="field-control  field-control__updated darkinput"
                  placeholder="Enter Variant Name"
                />
              </div>
              {experiment?.status === "preview" && (
                <>
                  <div className="fields-wrapper">
                    <label className="nofloat">Variation to Preview</label>
                    <Select
                      onChange={(vairant) => {
                        setPreviewVariant(vairant);
                      }}
                      value={previewVariant}
                      classNamePrefix="acc-newselect"
                      className="acc-newselectwrapper darkinput"
                      placeholder="Select..."
                      options={experiment?.variations?.map((c) => {
                        return {
                          label: c?.name,
                          value: c?._id,
                        };
                      })}
                    />
                  </div>
                  {previewVariant && (
                    <div className="fields-wrapper">
                      <label className="nofloat"> Preview URL</label>
                      <input
                        type="text"
                        onClick={(e) => {
                          navigator.clipboard.writeText(e.target.value);
                          toast.success("Copied to clipboard");
                        }}
                        value={`${
                          experiment?.urltargeting?.[0]
                        }?codebase_preview=${experiment?._id}_${
                          previewVariant ? previewVariant?.value : ""
                        }`}
                        readOnly
                        className="field-control  field-control__updated darkinput"
                      />
                    </div>
                  )}
                </>
              )}

              <div className="add__new__test__form-footer mb-3">
                <button
                  onClick={(e) => {
                    navigate(
                      `${MyRoutes.abtestingdashboard}/${experiment._id}`
                    );
                  }}
                  className="btn next-btn"
                  type="button"
                >
                  Edit Experiment{" "}
                </button>
              </div>
            </div>

            <div className="experiment-box-footer">
              <div>
                Experminet ID: <strong> {experiment._id}</strong>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  });

  if (loading) return <LoaderLogo />;
  if (error) return <div>{error?.message}</div>;
  const { total, tests } = data?.allAbTests;
  return (
    <div>
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2>
            {activeTab}
            {activeTab === "experiments" && <> ({total})</>}
          </h2>
          <div>
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`btn btn-lg ${
                  activeTab === tab.name ? "btn-primary" : "btn-secondary"
                }`}
                onClick={(_) => {
                  setActiveTab(tab.name);
                }}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>
      </div>

      {activeTab === "experiments" ? (
        <>
          {tests?.map((experiment, index) => (
            <ExperimentBox
              experiment={experiment}
              ref={experimentBoxRef}
              key={index}
            />
          ))}
          <Pagination
            page={page}
            size={size}
            setPage={setPage}
            totalcount={total}
          />
        </>
      ) : (
        <ClientSettings client={client} viewId={viewId} />
      )}
    </div>
  );
}
