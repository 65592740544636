const MyRoutes = {
  // Editor Routes
  editorDashboard: "/editor-dashboard",
  //
  dashboard: "/dashboard",
  clients: "/clients",
  testsoverview: "/client-tests-overview",
  tests: "/tests",
  snippets: "/functional-snippets",
  businesscaseprojections: "/conversionapp/businesscase",
  clientbusinesscaseprojections: "/conversionapp/clientbusinesscase",
  data: "/data",
  page: "/data/page",
  hypothesis: "/data/hypothesis",
  triggers: "/data/triggers",
  tags: "/data/tags",
  testtypes: "/data/testtypes",
  goals: "/data/goals",
  cvrReports: "/data/cvr-reports",
  refetchAddtionalRevenueData: "/refetch-additional-revenue-data",
  users: "/users",
  manageclient: "/manage-client-data",
  manageclientretentions: "/manage-client-retentions",
  importcsv: "/importcsv",
  createsegments: "/create-segments",
  clientreportslearnings: "/client-reports-learnings",
  clientreports: "/client-reports",
  gaanalytics: "/googleanalyticsdata",
  gaprecalculations: "/precalculations",
  clientfeedbacks: "/client-feedbacks",
  announcements: "/announcements",
  clientaudit: "/client-audit",
  clientlinks: "/client-links",
  faqs: "/managefaqs",
  portfoliocomparison: "/potfoliocomparison",
  clientsaccesses: "/client-accesses",
  resetpassword: "/reset-password",
  changepassword: "/change-password",
  clientsurveyreport: "/client-survey-reports",
  testideas: "/test-ideas",
  landingpages: "/landingpages",
  appevents: "/appevents",
  abtestingdashboard: "/ab-testing-dashboard",
};

export default MyRoutes;
