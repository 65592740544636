import { useLayoutEffect, useState, useEffect } from "react";
import { SmartButton } from "../../MiroComponents/SmartButton/SmartButton";
import { SmartCard } from "../../MiroComponents/SmartCard/SmartCard";
import { SmartHeading } from "../../MiroComponents/SmartHeading/SmartHeading";
import { SmartInput } from "../../MiroComponents/SmartInput/SmartInput";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { DateRangePicker } from "react-date-range";
import ReactTooltip from "react-tooltip";
import { Calendar } from "../../Shared/Icons";
import { SmartProjectedEffect } from "../../NewComponents/SmartProjectedEffect/SmartProjectedEffect";
import { SmartDetailProjections } from "../../NewComponents/SmartDetailProjections/SmartDetailProjections";
import { useMutation, useLazyQuery } from "@apollo/client";
import { CREATECLIENTBUSINESSCASEPROJECTION } from "../../../Graphql/Mutations";
import { GETCLIENTSNAMEANDIDS } from "../../../Graphql/Queries";
import SmartContainer from "../../MiroComponents/SmartContainer/SmartContainer";
import { useMyProfile } from "../../../Hooks/useMyProfile";
import { SmartSelector } from "../../MiroComponents/SmartSelector/SmartSelector";
import { BigQueryDataSetsComponent } from "../../Shared/BigQueryDataSetsComponent";
import MyLoader from "../../MiroComponents/PreLoader";

export const ClientBusinessProjection = () => {
  const [data, setData] = useState({});
  const { myProfile } = useMyProfile();
  const [clientSelectorOptions, setClientSelectorOptions] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams({});
  const [getActiveClients] = useLazyQuery(GETCLIENTSNAMEANDIDS);
  const [getclientbusinessprojection] = useMutation(
    CREATECLIENTBUSINESSCASEPROJECTION
  );
  const _params = Object.fromEntries([...searchParams]);
  const returnClientOptions = (clients) => {
    return clients?.map((c) => {
      return {
        label: c?.name,
        value: JSON.stringify({
          _id: c?._id,
          gaproperties: c?.gaproperties,
          bqClientId: c?.bqClientId,
          currency: c?.currency,
          logo: c?.logo,
        }),
      };
    });
  };
  const client = searchParams.get("client");
  const viewId = searchParams.get("viewId");

  const setAllClientsOptions = async () => {
    const { data, loading } = await getActiveClients({
      variables: {
        clientFilters: [{ active: true }],
      },
    });
    if (!loading && data) {
      setClientSelectorOptions(returnClientOptions(data?.clients?.clients));
    }
  };

  useEffect(() => {
    setAllClientsOptions();
  }, [myProfile]);

  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: moment().subtract(12, "months").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [oldSelection, setOldSelection] = useState([
    {
      startDate: moment().subtract(3, "months").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);
  const callBackWhenClose = () => {
    setShowCalendar(false);
  };

  useLayoutEffect(() => {
    ReactTooltip.rebuild();
    return () => {};
  }, []);

  if (data?.loading || !clientSelectorOptions) return <MyLoader />;
  return (
    <div className="dark-bg conversionapppage client-business-case">
      <ReactTooltip
        id="soclose"
        className="swtooltip"
        getContent={(dataTip) => <div>{dataTip}</div>}
        effect="solid"
      />
      <SmartContainer className="p-24-top p-24-bottom">
        <SmartHeading>Client Business Case Overview</SmartHeading>
        <SmartCard bodyClass="projected-values">
          <div className="business-case-form">
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                const { avgcm2, guaranteedoutcome } = e.target.elements;
                if (!avgcm2.value || !guaranteedoutcome.value || !viewId) {
                  toast.error("Please fill all the fields");
                  return;
                }

                try {
                  setData({
                    loading: true,
                  });
                  const {
                    data: { getBusinessCaseValidationData },
                    loading,
                  } = await getclientbusinessprojection({
                    variables: {
                      client,
                      dataSetId: viewId,
                      avgcm2: parseInt(avgcm2.value),
                      guaranteedoutcome: parseFloat(guaranteedoutcome.value),
                      dateRange: {
                        startDate: format(
                          new Date(selectionRange[0]?.startDate),
                          "yyyyMMdd"
                        ),
                        endDate: format(selectionRange[0]?.endDate, "yyyyMMdd"),
                      },
                      sheetData: _params.sheetData ?? "false",
                    },
                  });
                  setData({
                    loading,
                    ...getBusinessCaseValidationData,
                  });
                } catch (error) {
                  toast.error("Error while calculating the data");
                }
              }}
            >
              <div className="mb-2">
                <h5 style={{ color: "#F7FFF7" }}>Store:</h5>{" "}
                <SmartSelector
                  onChange={(client) => {
                    _params.client = JSON.parse(client?.value)?._id;
                    setSearchParams(_params);
                  }}
                  value={
                    searchParams?.get("client")
                      ? clientSelectorOptions?.find(
                          (opt) => JSON.parse(opt?.value)?._id === client
                        )
                      : null
                  }
                  className="acc-newselectwrapper"
                  classNamePrefix="acc-newselect"
                  placeholder="Select Store"
                  options={clientSelectorOptions}
                />
              </div>
              <>
                {client && (
                  <div className="smart-input">
                    <BigQueryDataSetsComponent
                      projectId={
                        client
                          ? JSON.parse(
                              clientSelectorOptions.find(
                                (c) => JSON.parse(c?.value)?._id === client
                              )?.value
                            )?.bqClientId
                          : null
                      }
                      client={
                        client
                          ? JSON.parse(
                              clientSelectorOptions?.find((opt) => {
                                return JSON.parse(opt?.value)?._id === client;
                              })?.value
                            )
                          : null
                      }
                      viewId={viewId}
                      _params={_params}
                      setSearchParams={setSearchParams}
                    />
                  </div>
                )}
              </>

              <SmartInput
                label="Avg. CM2"
                defaultValue="40"
                name="avgcm2"
                placeholder="Enter your Avg. CM2"
              />

              <SmartInput
                label="Guaranteed Outcome"
                defaultValue="50"
                name="guaranteedoutcome"
                placeholder="Enter guaranteed outcome"
              />

              <div className="smart-input">
                <label> Date Selection </label>
                <div className="smart-input-cover">
                  <span>
                    <button
                      type="button"
                      onClick={(_) => setShowCalendar(!showCalendar)}
                      className="btn btn-default d-inline-flex align-items-center"
                      style={{
                        columnGap: "6px",
                      }}
                    >
                      <Calendar color={"#fff"} />
                      <span
                        className="range-selected"
                        style={{
                          color: "#fff",
                          width: "100%",
                        }}
                      >
                        {format(selectionRange[0]?.startDate, "dd.MMMM.yyyy")} -{" "}
                        {format(selectionRange[0]?.endDate, "dd.MMMM.yyyy")}
                      </span>
                    </button>
                  </span>

                  {showCalendar && (
                    <div className={`datepicker-wrapper dark-calendar-wrapper`}>
                      <DateRangePicker
                        showSelectionPreview={true}
                        // maxDate={new Date()}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        direction={`horizontal`}
                        ranges={selectionRange}
                        className={`dark-calendar`}
                        retainEndDateOnFirstSelection={true}
                        onChange={(item) => {
                          const oneYearAhead = moment(
                            item?.selection?.startDate
                          )
                            .add(12, "months")
                            .toDate();
                          const newSelection = [
                            {
                              startDate: item?.selection?.startDate,
                              endDate: oneYearAhead,
                              key: "selection",
                            },
                          ];
                          setSelectionRange(newSelection);
                          callBackWhenClose(selectionRange);
                          setOldSelection(selectionRange);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <SmartButton
                style={{ width: "100%" }}
                type="submit"
                className="smart-button big active"
                text={`Calculate`}
              />
            </form>
          </div>

          {data?.data && <SmartProjectedEffect data={data.data} />}
        </SmartCard>

        {data?.data && (
          <SmartCard
            className="smart-card-outer m-48-top"
            id="projectionoverview"
          >
            <SmartDetailProjections
              data={data.data}
              businessCase="client-projection"
            />
          </SmartCard>
        )}
      </SmartContainer>
    </div>
  );
};
