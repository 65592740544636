import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useRef, useState } from "react";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { toast } from "react-toastify";
import { COMPAREPORTFOLIO } from "../../../Graphql/Mutations";
import {
  GETCLIENTSNAMEANDIDS,
  GETSAVEDPORTFOLIO,
} from "../../../Graphql/Queries";
import MyLoader from "../../MiroComponents/PreLoader";
import { CrossIcon, CrossIcon2 } from "../../Shared/Icons";
import moment from "moment";

export const AdminPortfoliComparison = () => {
  const [clients, setClients] = useState(null);
  const [getActiveClients] = useLazyQuery(GETCLIENTSNAMEANDIDS);
  const [portfolioVars, setPortfoliovars] = useState([]);
  const formRef = useRef();
  const [compareportfolio] = useMutation(COMPAREPORTFOLIO);
  const { data, loading, error, refetch } = useQuery(GETSAVEDPORTFOLIO);
  const setValues = (id, key, value) => {
    const formObj = { ...portfolioVars.find((obj) => obj.id === id) };
    formObj[key] = value;
    const _portfolios = portfolioVars.filter((obj) => obj.id !== id);
    const newArr = [..._portfolios, formObj].sort((a, b) =>
      a.id > b.id ? 1 : -1
    );
    setPortfoliovars(newArr);
  };

  const removeValues = (id) => {
    const _portfolios = portfolioVars.filter((obj) => obj.id !== id);
    setPortfoliovars(_portfolios);
  };

  useEffect(() => {
    (async () => {
      const {
        data: { clients },
        loading,
        error,
      } = await getActiveClients({
        variables: {
          clientFilters: [
            {
              active: true,
            },
          ],
        },
      });
      setClients({
        loading,
        error,
        clients: clients?.clients,
      });
    })();
  }, []);

  useEffect(() => {
    setPortfoliovars(data?.getPortfolio?.portfoliokey);
  }, [data, loading, setPortfoliovars]);

  const PortfolioForm = React.forwardRef(({ portfolioVars, clients }, ref) => {
    return (
      <div ref={ref} className="port-cards">
        {portfolioVars?.map((obj, i) => (
          <div key={i} className="portfolio-creater">
            <div
              className="remove-portfolio"
              onClick={(e) => {
                removeValues(obj?.id);
              }}
            >
              <CrossIcon2 />
            </div>
            <div className="fields-wrapper theme-black">
              {/* <label> Title</label> */}
              <input
                className="field-control"
                defaultValue={obj?.title ?? ""}
                onBlur={(_) =>
                  setValues(obj?.id, "title", _?.currentTarget?.value)
                }
              />
            </div>
            <div className="fields-wrapper mb-0">
              <Select
                isMulti={true}
                onChange={(clients) => setValues(obj?.id, "clients", clients)}
                className="acc-newselectwrapper"
                classNamePrefix="acc-newselect"
                options={clients?.map((_) => {
                  return {
                    label: _?.name,
                    value: _?._id,
                  };
                })}
                value={obj?.clients?.map((clt) => {
                  return {
                    label: clt?.label,
                    value: clt?.value,
                  };
                })}
              />
            </div>
          </div>
        ))}
      </div>
    );
  });
  if (loading || clients?.loading) return <MyLoader />;
  if (error || clients?.error) return <p>Error</p>;
  return (
    <div>
      <div className="header-bar">
        <div className="header-bar-inner">
          <h2> Portfolio </h2>
          <div>
            <button
              className="add"
              onClick={(e) =>
                setPortfoliovars((_) => [
                  ..._,
                  {
                    id: new Date()?.valueOf(),
                    title: "",
                    clients: null,
                  },
                ])
              }
            >
              Add Portfolio
            </button>
          </div>
        </div>
      </div>

      <PortfolioForm
        ref={formRef}
        portfolioVars={portfolioVars}
        clients={clients?.clients}
      />
      <div>
        {portfolioVars?.length > 0 && (
          <div className="create-request-wrapper">
            <div className="submit-request">
              <button
                type="button"
                onClick={async (e) => {
                  if (
                    portfolioVars?.some(
                      (arr) => arr?.title === "" || !arr?.clients
                    )
                  ) {
                    toast.error(
                      "Select clients and enter title for all fields."
                    );
                    return;
                  }
                  const { data, loading, error } = await compareportfolio({
                    variables: {
                      payload: portfolioVars,
                    },
                  });
                  refetch();
                }}
              >
                Compare{" "}
              </button>
            </div>
          </div>
        )}
      </div>
      {data?.getPortfolio?.portfolioData && (
        <React.Fragment>
          <p
            className="last-checked"
            style={{
              color: "#fff",
              textAlign: "right",
            }}
          >
            {" "}
            Last Checked on{" "}
            <strong
              style={{
                fontWeight: "900",
              }}
            >
              {moment(data?.getPortfolio?.updatedAt).format("LL")}
            </strong>{" "}
          </p>
          <div className="table-responsive">
            <table className="table text-center" style={{ color: "#fff" }}>
              <thead>
                <tr>
                  <th className="text-start">Name/Clients</th>
                  <th>
                    Avg. Uplift <small> (winning tests)</small>
                  </th>
                  <th>Ended Tests</th>
                  <th>Monthly Revenue</th>
                  <th>Yearly Revenue</th>
                  <th>Success rate</th>
                </tr>
              </thead>
              <tbody>
                {data?.getPortfolio?.portfolioData?.map((data, i) => (
                  <tr key={i}>
                    <th scope="row" className="text-start">
                      <h4>{data?.title}:</h4>
                      <small>
                        {" "}
                        (
                        {data?.clients
                          ?.map((client) => client?.label)
                          .join(" | ")}
                        ){" "}
                      </small>
                    </th>
                    <td>{data?.averageUpliftSuccessfullTests} %</td>
                    <td>{data?.endedTest}</td>
                    <td>{data?.monthlyRevenue}</td>
                    <td>{data?.yearlyRevenue}</td>
                    <td>{data?.successRate} %</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
