import { useLazyQuery, useQuery } from "@apollo/client";
import { subDays, subMonths } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import {
  VscAccount,
  VscArrowUp,
  VscChecklist,
  VscDiff,
  VscRocket,
  VscUngroupByRefType,
} from "react-icons/vsc";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  ADMINDASHBOARDSTATS,
  GETCLIENTS,
  GETCLIENTSPORTFOLIO,
} from "../../../Graphql/Queries";
import MyRoutes from "../../../Routes";
import MyLoader from "../../MiroComponents/PreLoader";
import { AdminStats } from "../../Shared/AdminStats";
import { DateRangeSelectorComponent } from "../../Shared/DateRangeSelectorComponent";
import { RevenuePerMonthGraph } from "./AdminComponents/RevenuePerMonthGraph";
import { customStyles } from "../../../Libs/TableCustomStyles";
import DataTable from "react-data-table-component";

function Dashboard() {
  const [myProfile, is_client, is_admin, is_user] = useOutletContext();
  const [clients, setClients] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: subMonths(new Date(), 6),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [data, setData] = useState({
    loading: true,
  });
  const [clientPortfolio, setClientPortfolio] = useState({
    loading: true,
  });
  const navigate = useNavigate();
  const [getClients] = useLazyQuery(GETCLIENTS);
  const [getStatsData] = useLazyQuery(ADMINDASHBOARDSTATS);
  const [getclientportfolio] = useLazyQuery(GETCLIENTSPORTFOLIO);

  const setAdminStats = useCallback(async () => {
    const { data, loading, error } = await getStatsData({
      variables: {
        from: selectionRange[0].startDate,
        to: selectionRange[0].endDate,
        client: null,
        active: true,
      },
    });
    setData({
      ...data,
      adminDashboardStatistics: data?.adminDashboardStatistics,
      getAdminStats: data?.getAdminStats,
      revenuePerMonth: data?.revenuePerMonth,
      loading,
      error,
    });
  }, [selectionRange]);

  const callBackWhenClose = () => {
    setShowCalendar(false);
  };

  useEffect(() => {
    setAdminStats();
  }, [setAdminStats]);

  useEffect(() => {
    (async () => {
      const { data, loading, error } = await getclientportfolio();
      setClientPortfolio({
        clientPortfolio: data?.clientPortfolio,
        loading,
        error,
      });
    })();
  }, [getclientportfolio]);

  const checkNaN = (wt, et) => {
    const _winrate = (Number(wt) / Number(et)) * 100;
    return (
      <div className="fw-light">
        {wt} / {et}
        <strong>
          {" "}
          ({!isNaN(_winrate) && isFinite(_winrate) ? _winrate.toFixed(2) : 0} %)
        </strong>
      </div>
    );
  };

  useEffect(() => {
    (async () => {
      const { data } = await getClients({
        variables: {
          clientFilters: [{ active: true }],
          page: 1,
          size: 6,
          testStatus: "live",
          testRequired: false,
        },
      });
      setClients(data?.clients?.clients);
    })();
  }, [getClients, setClients]);

  const columnsclients = [
    {
      name: "Client name",
      selector: ({ name, active, tier, _id, gaproperties }) => (
        <div
          style={{ cursor: "pointer" }}
          className="d-inline-flex align-items-center"
          onClick={(_) => {
            navigate(
              `${MyRoutes.clientreports}?client=${_id}&viewId=${gaproperties[0]?.viewId}`
            );
          }}
        >
          <span
            className="colorSwatch"
            style={{
              backgroundColor: active ? tier?.colorSwatch : "red",
            }}
          ></span>
          {name}
        </div>
      ),
      wrap: true,
    },
    {
      name: "Win Rate",
      selector: ({ winningTests, endedTests }) =>
        checkNaN(winningTests, endedTests),
      wrap: true,
    },
    {
      name: "Stats",
      selector: ({ _id }) => <AdminStats show={false} id={_id} />,
      wrap: true,
      right: true,
    },
  ];

  if (data.loading || clientPortfolio?.loading) return <MyLoader />;
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-3 bg-dark text-white shadow-1 border-0">
            <div className="card-header border-0 d-flex flex-between-center py-2">
              <h6 className="mb-0">Analytics</h6>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-3">
                  <div>
                    <h6 className="text-700">Active Clients</h6>
                    <h3 className="fw-normal text-700 mb-0">
                      {data?.getAdminStats?.activeClients}
                    </h3>
                  </div>
                </div>
                <div className="col-3">
                  <div>
                    <h6 className="text-700">Overall win-rate</h6>
                    <h3 className="fw-normal text-700 mb-0">
                      {data?.getAdminStats?.winningTests}/
                      {data?.getAdminStats?.endedTests}
                    </h3>
                  </div>
                </div>
                {(is_admin || is_user) && (
                  <>
                    <div className="col-3">
                      <div>
                        <h6 className="text-700">Total Yearly Revenue</h6>
                        <h3 className="fw-normal text-700 mb-0">
                          {data?.getAdminStats?.yearlyRevenue}
                        </h3>
                      </div>
                    </div>
                    <div className="col-3">
                      <div>
                        <h6 className="text-700">Total Monthly Revenue</h6>
                        <h3 className="fw-normal text-700 mb-0 ">
                          {data?.getAdminStats?.monthlyRevenue}
                        </h3>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {(is_admin || is_user) && (
            <>
              <div className="card mt-3 mb-3">
                <div className="card-body shadow">
                  <div className="segment-wrapper">
                    <div className="segment-inner">
                      <div className="segment-rules">
                        <DateRangeSelectorComponent
                          showCalendar={showCalendar}
                          setShowCalendar={setShowCalendar}
                          selectionRange={selectionRange}
                          setSelectionRange={setSelectionRange}
                          callBackWhenClose={callBackWhenClose}
                          className="dark-calendar"
                          classNameWrapper="dark-calendar-wrapper"
                          color={"#000"}
                        />
                      </div>
                    </div>
                  </div>
                  <RevenuePerMonthGraph
                    labels={data?.revenuePerMonth?.map(
                      (rpm) => `${rpm.month}, ${rpm.year}`
                    )}
                    data={data?.revenuePerMonth?.map((rpm) => rpm.revenue)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {is_admin && (
            <div className="card mb-3 bg-dark text-white shadow-1 border-0 mt-3">
              <div className="card-header border-0 d-flex flex-between-center py-2">
                <h6 className="mb-0">Overall Portfolio Performance</h6>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-2">
                    <div>
                      <h6 className="text-700">Success Rate</h6>
                      <h3 className="fw-normal text-700 mb-0">
                        {clientPortfolio?.clientPortfolio?.successRate} %
                      </h3>
                    </div>
                  </div>
                  <div className="col-2">
                    <div>
                      <h6 className="text-700">Ended Tests</h6>
                      <h3 className="fw-normal text-700 mb-0">
                        {clientPortfolio?.clientPortfolio?.endedTest}
                      </h3>
                    </div>
                  </div>
                  <div className="col-4">
                    <div>
                      <h6 className="text-700">
                        Avg. Uplift on CR of successful Tests
                      </h6>
                      <h3 className="fw-normal text-700 mb-0">
                        {
                          clientPortfolio?.clientPortfolio
                            ?.averageUpliftSuccessfullTests
                        }{" "}
                        %
                      </h3>
                    </div>
                  </div>
                  <div className="col-2">
                    <div>
                      <h6 className="text-700">Revenue Monthly</h6>
                      <h3
                        className="fw-normal text-700 mb-0"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {clientPortfolio?.clientPortfolio?.monthlyRevenue}
                      </h3>
                    </div>
                  </div>
                  <div className="col-2">
                    <div>
                      <h6 className="text-700">Revenue Yearly</h6>
                      <h3
                        className="fw-normal text-700 mb-0"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {clientPortfolio?.clientPortfolio?.yearlyRevenue}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="shadow">
            <DataTable
              columns={columnsclients}
              data={clients}
              customStyles={customStyles}
              progressComponent={<MyLoader />}
            />
          </div>
          {clients?.length > 0 && (
            <div className="mt-3 mb-3">
              <button
                className="btn btn-sm btn-outline-primary m-auto d-flex"
                onClick={(_) => navigate(MyRoutes.clients)}
              >
                View All
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card mb-3 bg-white shadow-1 border-0 h-100">
            <div className="card-header border-0 d-flex flex-between-center bg-white py-2">
              <h6 className="mb-0">Services</h6>
            </div>
            <div className="card-body bg-light">
              <div className="admin-dashboard-inner" style={{ width: "100%" }}>
                <div className="card shadow-1 border-0">
                  <div
                    className="card-body text-center"
                    onClick={(_) => navigate(MyRoutes.tests)}
                  >
                    <VscDiff />
                    <h5 className="card-title">
                      Tests{" "}
                      <span> {data?.adminDashboardStatistics?.tests} </span>
                    </h5>
                  </div>
                </div>
                <div className="card shadow-1 border-0">
                  <div
                    className="card-body text-center"
                    onClick={(_) => navigate(MyRoutes.clients)}
                  >
                    <VscUngroupByRefType />
                    <h5 className="card-title">
                      Clients{" "}
                      <span> {data?.adminDashboardStatistics?.clients} </span>
                    </h5>
                  </div>
                </div>
                <div className="card shadow-1 border-0">
                  <div
                    className="card-body text-center"
                    onClick={(_) => navigate(MyRoutes.goals)}
                  >
                    <VscRocket />
                    <h5 className="card-title">
                      Goals{" "}
                      <span> {data?.adminDashboardStatistics?.goals} </span>
                    </h5>
                  </div>
                </div>
                {is_admin && (
                  <div className="card shadow-1 border-0">
                    <div
                      className="card-body text-center"
                      onClick={(_) => navigate(MyRoutes.users)}
                    >
                      <VscAccount />
                      <h5 className="card-title">
                        Users{" "}
                        <span> {data?.adminDashboardStatistics?.users} </span>
                      </h5>
                    </div>
                  </div>
                )}

                <div className="card shadow-1 border-0">
                  <div
                    className="card-body text-center"
                    onClick={(_) => navigate(MyRoutes.hypothesis)}
                  >
                    <VscArrowUp />
                    <h5 className="card-title">
                      Hypothesis{" "}
                      <span>
                        {" "}
                        {data?.adminDashboardStatistics?.hypothesis}{" "}
                      </span>
                    </h5>
                  </div>
                </div>

                <div className="card shadow-1 border-0">
                  <div
                    className="card-body text-center"
                    onClick={(_) => navigate(MyRoutes.testtypes)}
                  >
                    <VscChecklist />
                    <h5 className="card-title">
                      Types of Tests{" "}
                      <span>
                        {" "}
                        {data?.adminDashboardStatistics?.typeOfTests}{" "}
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
