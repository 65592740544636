import { useLazyQuery } from "@apollo/client";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import moment from "moment";
import { useEffect, useState } from "react";
import { GETCLIENTSTRATEGIES } from "../../Graphql/Queries";
import { BigTickGreen, PendingCircle } from "../Shared/Icons";

export const ClientStrategyandRoadmap = ({
  showSection,
  clientId,
  selectedProperty,
}) => {
  const [strategyData, setStrategyData] = useState({
    show: showSection ?? false,
  });
  const [clientStrategies] = useLazyQuery(GETCLIENTSTRATEGIES);
  const getClientStrategies = async (viewId, client) => {
    var currentDate = moment(new Date());
    var nextMonth = moment(currentDate).add(1, "M");
    var nextMonthEnd = moment(futureMonth).endOf("month");
    var futureMonth = moment(currentDate).add(2, "M");
    var futureMonthEnd = moment(futureMonth).endOf("month");
    if (
      currentDate.date() != nextMonth.date() &&
      nextMonth.isSame(nextMonthEnd.format("YYYY-MM-DD"))
    ) {
      nextMonth = nextMonth.add(1, "d");
    }
    if (
      currentDate.date() != futureMonth.date() &&
      futureMonth.isSame(futureMonthEnd.format("YYYY-MM-DD"))
    ) {
      futureMonth = futureMonth.add(1, "d");
    }
    let dates = [
      currentDate.toDate(),
      nextMonth.toDate(),
      futureMonth.toDate(),
    ];
    setStrategyData({
      ...strategyData,
      loading: true,
    });
    const { data, loading, error } = await clientStrategies({
      variables: {
        viewId,
        client,
        dates,
      },
    });
    setStrategyData({
      show: !loading,
      loading,
      clientStrategies: data?.clientStrategies,
      error,
    });
  };

  useEffect(() => {
    if ((clientId, selectedProperty)) {
      setStrategyData({
        ...strategyData,
        show: true,
      });
      getClientStrategies(selectedProperty, clientId);
    }
  }, [clientId, selectedProperty]);
  if (strategyData?.show && strategyData?.loading) return "loading";
  if (
    !strategyData?.clientStrategies ||
    strategyData?.clientStrategies?.length === 0
  ) {
    return ``;
  }

  return (
    <section className="strategy">
      <div className="strategy-inner">
        <div className="strategy-title">Strategy &amp; Roadmap</div>
        <h2 className="strategy-sub-title">Current Status Update</h2>
        <div className="stratergy-months-card-list">
          <Splide
            aria-label=""
            options={{
              type: "slide",
              perPage: 3,
              pagination: false,
              arrows: false,
              gap: "10px",
              breakpoints: {
                991: {
                  perPage: 2,
                  pagination: true,
                },
                767: {
                  perPage: 1,
                },
              },
            }}
          >
            {strategyData?.clientStrategies?.map((item, i) => (
              <SplideSlide key={i}>
                <div className="month-card" key={i}>
                  <div className="month-card-content">
                    <h3 className="month-name"> {item?.month?.label} </h3>
                    <ul className="monthly-test-list">
                      {item?.strategies?.map((val, index) => (
                        <li className="test-detail" key={index}>
                          <span className="icon">
                            {val?.done ? <BigTickGreen /> : <PendingCircle />}
                          </span>
                          <p className="task-status">{val?.name}</p>
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* <a href="#" className="button month-detail-btn month-active">
                detail
              </a> */}
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </section>
  );
};
